import React, { useEffect, useContext } from 'react';
import { View } from 'dripsy';
import { useAtom } from 'jotai';
import ServiceList from '../../components/ServiceList';
import { HospedagemProps } from '../../navigation/types';
import { localAtom } from '../../atoms';
import { CIDADE } from '../../values/constants';
import { LanguageContext } from '../../context/LanguageContext';

const Hospedagem: React.FC<HospedagemProps> = ({ navigation }) => {
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: `${CIDADE[local]} / ${str.hospedagem}`
    });
  }, [navigation, local, str]);

  return (
    <View sx={{ flex: 1 }}>
      <ServiceList repo='Hospedagem' />
    </View>
  );
};

export default Hospedagem;
