import React, { useContext, useEffect } from 'react';
import { View } from 'dripsy';
import { useAtom } from 'jotai';
import { localAtom } from '../../atoms';
import { HistoriaProps } from '../../navigation/types';
import { LanguageContext } from '../../context/LanguageContext';
import { CIDADE } from '../../values/constants';
import ServiceAlone from '../../components/ServiceAlone';

const Historia: React.FC<HistoriaProps> = ({ navigation }) => {
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    navigation.setOptions({ headerTitle: `${CIDADE[local]} / ${str.historia}` });
  }, [navigation, local, str]);

  return (
    <View sx={{ flex: 1 }}>
      <ServiceAlone repo='Lugares' tipo='historia' />
    </View>
  );
};

export default Historia;
