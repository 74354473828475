import React from 'react';
// @ts-ignore
import RNLinearGradient from 'react-native-web-linear-gradient';

// @ts-ignore
const LinearGradient = ({ children, ...props }) => {
  // @ts-ignore
  return <RNLinearGradient {...props}>{children}</RNLinearGradient>;
};

export default LinearGradient;
