/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { Linking, Platform } from 'react-native';
import { Text } from 'dripsy';
import { structureText } from 'text-structurer';

const FormatedText: React.FC<{ text: string }> = ({ text }) => {
  const structuredText = structureText(text);

  // @ts-ignore
  const Title = ({ children }) => (
    <Text
      sx={{
        fontSize: 18,
        marginTop: 15,
        marginBottom: 10,
        marginLeft: 15,
        marginRight: 15,
        color: '$primary3',
        fontFamily: Platform.select({ android: 'Poppins-Regular' })
      }}>
      {children}
    </Text>
  );

  // @ts-ignore
  const SubTitle = ({ children }) => (
    <Text
      sx={{
        fontSize: 16,
        marginLeft: 15,
        marginRight: 15,
        color: '$primary3',
        fontFamily: Platform.select({ android: 'Poppins-Regular' })
      }}>
      {children}
    </Text>
  );

  // @ts-ignore
  const Paragraph = ({ children }) => (
    <Text
      sx={{
        fontSize: 16,
        marginLeft: 15,
        marginRight: 15,
        color: '$primary3',
        fontFamily: Platform.select({ android: 'Poppins-Regular' })
      }}>
      {children}
    </Text>
  );

  // @ts-ignore
  const Bold = ({ children }) => <Text sx={{ fontWeight: ['bold'] }}>{children}</Text>;

  // @ts-ignore
  const Italic = ({ children }) => <Text sx={{ fontStyle: ['italic'] }}>{children}</Text>;

  // @ts-ignore
  const Link = ({ children, meta }) => (
    <Text
      sx={{ textDecorationLine: ['underline'] }}
      onPress={async () => {
        await Linking.openURL(meta);
      }}>
      {children}
    </Text>
  );

  // @ts-ignore
  const renderPartText = ({ format, part, meta }, index) => {
    return format === 'B' ? (
      <Bold key={`bold-${index}`}>{part}</Bold>
    ) : format === 'I' ? (
      <Italic key={`italic-${index}`}>{part}</Italic>
    ) : format === 'A' ? (
      <Link meta={meta}>{part}</Link>
    ) : (
      part
    );
  };

  return (
    <>
      {structuredText.map((item, index) => {
        if (item.type === 'H1') {
          // @ts-ignore
          return <Title key={`ft-title-${index}`}>{item.value.map(renderPartText)}</Title>;
        }
        if (item.type === 'H2') {
          // @ts-ignore
          return <SubTitle key={`ft-subtitle-${index}`}>{item.value.map(renderPartText)}</SubTitle>;
        }
        if (item.type === 'LI') {
          return (
            <Paragraph key={`ft-listitem-${index}`}>
              {' • '}
              {
                // @ts-ignore
                item.value.map(renderPartText)
              }
            </Paragraph>
          );
        }
        return (
          <Paragraph key={`ft-paragraph-${index}`}>
            {
              // @ts-ignore
              item.value.map(renderPartText)
            }
          </Paragraph>
        );
      })}
    </>
  );
};

export default FormatedText;
