import React, { useEffect, useState } from 'react';
import { View } from 'dripsy';
import { useAtom } from 'jotai';
import { isLoadingAtom, localAtom } from '../../atoms';
import { Repo } from '../../model/Repo';
import { DetalhesServicoContent } from '../../pages/DetalhesServico';
// @ts-ignore
import { RTDatabase } from '../../shims/firebase';

type ServiceAloneProps = {
  repo: Repo;
  tipo?: 'historia' | 'cachoeiras' | 'monumentos' | 'trilhas' | 'religiao';
  customRender?: (item: any) => React.ReactNode;
};

const ServiceAlone: React.FC<ServiceAloneProps> = ({ repo, tipo, customRender }) => {
  const [data, setData] = useState<Array<any>>();
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [local] = useAtom(localAtom);

  useEffect(() => {
    setIsLoading(true);

    RTDatabase.get(repo)
      .then((result: any) => {
        if (result) {
          let items = Object.entries(result)
            .map(([key, value]) => ({
              ...(value as any),
              id: key,
              repo
            }))
            .filter(i => i.Ativo && (tipo ? i.Tipo === tipo : true));
          setData(items);
        }
      })
      .catch((err: any) => {
        console.log('err', err);
      })
      .finally(() => setIsLoading(false));

    () => setIsLoading(false);
  }, [repo, tipo, setIsLoading]);

  const item = data ? data.find(i => i.Local === local) : null;

  if (customRender) {
    return <>{customRender(data)}</>;
  }

  return <View sx={{ flex: 1 }}>{item ? <DetalhesServicoContent item={item} /> : null}</View>;
};

export default ServiceAlone;
