import React, { useEffect, useState, useCallback } from 'react';
import {
  Platform,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  Image,
  ImageBackground,
  Linking
} from 'react-native';
import { useAtom } from 'jotai';
import { View, Text, useSx, TextInput } from 'dripsy';
import IconMaterial from 'react-native-vector-icons/MaterialCommunityIcons';
import IconIonicon from 'react-native-vector-icons/Ionicons';
import { maskText } from 'js-util/functions/mask-text';
import axios from 'axios';
import { Divider, Portal, Modal, List, Snackbar, Checkbox, IconButton } from 'react-native-paper';
import { comercianteAtom, isLoadingAtom } from '../../../atoms';
import StorageService from '../../../services/StorageService';
import { CLASSIFICACOES, STORAGEKEY_COMERCIANTE, VALOR_PLANO } from '../../../values/constants';
import { ACHomeProps } from '../../../navigation/types';
// @ts-ignore
import { RTDatabase } from '../../../shims/firebase';
import TextButton from '../../../components/TextButton';
import { Repo } from '../../../model/Repo';
import { Colors } from '../../../theme';
import ImagePlaceholder from '../../../assets/images/img-default.png';
import ContainedButton from '../../../components/ContainedButton';
import { ACRepository } from '../../../repositories/ACRepository';
import Deck from '../../../assets/images/deck.jpg';
import { BASE_URL } from '../../../services/ApiService';
import { formatDateDispon, getValidator } from '../../../functions';
import { sortByKey } from 'js-util/functions/array-util';
import { Auth } from '../../../shims/firebase/index.web';

const ACHome: React.FC<ACHomeProps> = ({ navigation }) => {
  const sx = useSx();
  const {} = useWindowDimensions();

  const [comerciante, setComerciante] = useAtom(comercianteAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState<any>();
  const [statusBoleto, setStatusBoleto] = useState<any>();

  const planos = {
    gratis: 'Teste Grátis por 7 dias',
    completo: 'Completo ' + VALOR_PLANO + '/mês'
  };

  const migrarPlano = useCallback(async () => {
    if (!data) return;
    try {
      setIsLoading(true);
      await ACRepository.migrarPlano(comerciante.repo, data.key);
      setStatusBoleto('aguardando');
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, data, comerciante]);

  useEffect(() => {
    if (!comerciante) {
      navigation.replace('ACSignin');
    }

    if (comerciante) {
      RTDatabase.get(`/${comerciante?.repo}`).then((value: any) => {
        if (value) {
          const item: any = Object.entries(value).find(
            //([_, v]) => (v as any).CNPJ === comerciante?.cnpj
            ([_, v]) => (v as any).Email === comerciante?.email
          );
          if (item) {
            const d = { key: item[0], ...item[1] };
            setData(d);
            d.StatusBoleto && setStatusBoleto(d.StatusBoleto);
          }
        }
      });
    }
  }, [comerciante, navigation, tabIndex]);

  if (!data) {
    return null;
  }

  return (
    <View sx={{ flex: 1 }}>
      <View>
        <ScrollView
          horizontal
          style={sx({ width: '100%', borderBottomWidth: 0.5, borderBottomColor: '$primary1' })}>
          <ACTab title='Início' isSelected={tabIndex === 0} onPress={() => setTabIndex(0)} />
          <ACTab title='Cadastro' isSelected={tabIndex === 1} onPress={() => setTabIndex(1)} />
          <ACTab title='Imagens' isSelected={tabIndex === 2} onPress={() => setTabIndex(2)} />
          {comerciante?.repo === ('Restaurante' as Repo) ? (
            <>
              <ACTab title='Cardápio' isSelected={tabIndex === 3} onPress={() => setTabIndex(3)} />
              <ACTab
                title='Disponibilidade'
                isSelected={tabIndex === 4}
                onPress={() => setTabIndex(4)}
              />
            </>
          ) : null}
          <ACTab title='Desconto' isSelected={tabIndex === 5} onPress={() => setTabIndex(5)} />
          <ACTab title='Segurança' isSelected={tabIndex === 6} onPress={() => setTabIndex(6)} />
        </ScrollView>
      </View>

      <ScrollView style={{ flex: 1 }}>
        <View sx={{ width: ['100%', '100%', '75%'], marginBottom: [15], alignSelf: ['center'] }}>
          {tabIndex === 0 && (
            <>
              <ImageBackground
                source={ImagePlaceholder as any}
                style={sx({
                  width: [100],
                  height: [100],
                  alignSelf: ['center'],
                  marginTop: [25]
                })}
                imageStyle={sx({ borderRadius: [50] })}>
                {data.Images?.[0] || data.UrlImage ? (
                  <Image
                    source={{ uri: data.Images?.[0] || data.UrlImage }}
                    style={sx({ borderRadius: [50], width: [100], height: [100] })}
                  />
                ) : null}
              </ImageBackground>

              <Text
                sx={{
                  textAlign: 'center',
                  color: ['$text'],
                  fontSize: 16,
                  marginTop: [20],
                  marginBottom: [20]
                }}>
                Bem-vindo <Text sx={{ fontWeight: 'bold' }}>{data.Nome}!</Text>
              </Text>

              <View
                sx={{
                  backgroundColor: '$white',
                  padding: [10],
                  borderRadius: [10],
                  flexDirection: ['row'],
                  alignItems: ['center'],
                  marginRight: [25],
                  marginLeft: [25]
                }}>
                <IconMaterial name='information-outline' size={22} color={Colors.info} />
                <Text sx={{ marginLeft: [10], fontSize: [14], color: ['$text'] }}>
                  {data.Aguardando
                    ? 'Seu cadastro está em análise, complete suas informações e em breve seu anúncio aparecerá no Opção Costa Verde!'
                    : data.Ativo
                    ? 'Seu cadastro está ativo no Opção Costa Verde!'
                    : 'Seu cadastro está inativo no Opção Costa Verde! Entre em contato no informaçao.opcaocostaverde@gmail.com para mais informações.'}
                </Text>
              </View>

              <View
                sx={{
                  backgroundColor: '$success',
                  padding: [10],
                  borderRadius: [10],
                  flexDirection: ['row'],
                  alignItems: ['center'],
                  marginTop: [25],
                  marginBottom: [25],
                  marginRight: [25],
                  marginLeft: [25]
                }}>
                <IconMaterial name='flag-outline' size={22} color={Colors.white} />
                <Text sx={{ marginLeft: [10], fontSize: [14], color: ['$white'] }}>
                  Seu Plano é o{' '}
                  <Text sx={{ fontWeight: ['bold'] }}>
                    {
                      // @ts-ignore
                      planos[data?.Plano]
                    }
                  </Text>
                </Text>

                {statusBoleto === 'aguardando' ? (
                  <Text sx={{ marginLeft: [10], color: ['$primary3'] }}>
                    Aguarde as informações de pagamento!
                  </Text>
                ) : statusBoleto?.startsWith('disponivel') ? (
                  <Text
                    onPress={async () => await Linking.openURL(statusBoleto.split('#').pop())}
                    sx={{
                      marginLeft: [10],
                      color: ['$primary3'],
                      textDecorationLine: ['underline']
                    }}>
                    Boleto disponível para pagamento!
                  </Text>
                ) : data?.Plano === 'gratis' ? (
                  <Text
                    onPress={() => migrarPlano()}
                    sx={{
                      marginLeft: [10],
                      color: ['$primary3'],
                      textDecorationLine: ['underline']
                    }}>
                    Mude para o plano Completo!
                  </Text>
                ) : null}
              </View>

              <TextButton
                text='Sair'
                color='red'
                onPress={() =>
                  StorageService.remove(STORAGEKEY_COMERCIANTE).then(() => {
                    setComerciante(undefined);
                    Auth.signOut();
                    ACRepository.logout(comerciante?.email, comerciante?.token).catch(err =>
                      console.log('err', err)
                    );
                  })
                }
              />
            </>
          )}

          {tabIndex === 1 && <ACCadastro data={data} />}
          {tabIndex === 2 && <ACImagens data={data} />}
          {tabIndex === 3 && <ACCardapio data={data} />}
          {tabIndex === 4 && <ACDisponibilidade data={data} />}
          {tabIndex === 5 && <ACDesconto data={data} />}
          {tabIndex === 6 && <ACSeguranca data={data} />}
        </View>
      </ScrollView>
    </View>
  );
};

export default ACHome;

const ACTab: React.FC<{ title: string; isSelected: boolean; onPress: () => void }> = ({
  title,
  isSelected,
  onPress
}) => {
  const sx = useSx();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={sx({
        padding: [10],
        borderBottomWidth: [2],
        borderBottomColor: isSelected ? ['$primary1'] : ['transparent']
      })}>
      <Text
        sx={{
          fontWeight: Platform.OS === 'web' && isSelected ? ['bold'] : undefined,
          color: isSelected ? ['$primary1'] : ['$text']
        }}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const ACCadastro: React.FC<{ data: any }> = ({ data }) => {
  const sx = useSx();

  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [comerciante] = useAtom(comercianteAtom);

  const [nome, setNome] = useState(data?.Nome || '');
  const [cadastur, setCadastur] = useState(data?.Cadastur || '');
  const [tipo, setTipo] = useState(data?.Tipo || '');
  const [classificacao, setClassificacao] = useState(data?.Preco || '');
  const [telefoneFixo, setTelefoneFixo] = useState(
    data?.Telefones?.find((t: any) => t.Tipo === 'fixo')?.Numero || ''
  );
  const [telefoneCel, setTelefoneCel] = useState(
    data?.Telefones?.find((t: any) => t.Tipo === 'celular')?.Numero || ''
  );
  const [isWhatsApp, setIsWhatsApp] = useState(
    data?.Telefones?.find((t: any) => t.Tipo === 'celular')?.WhatsApp || false
  );
  const [email, setEmail] = useState(data?.Email || '');
  const [site, setSite] = useState(data?.Site || '');
  const [facebook, setFacebook] = useState(data?.FaceBook || '');
  const [instagram, setInstagram] = useState(data?.Instagram || '');
  const [video, setVideo] = useState(data?.Video || '');
  const [endereco, setEndereco] = useState(data?.Endereco || '');
  const [urlMaps, setUrlMaps] = useState(data?.UrlMaps || '');
  const [descricaoPt, setDescricaoPt] = useState(data?.Descricao?.pt || '');
  const [descricaoEn, setDescricaoEn] = useState(data?.Descricao?.en || '');
  const [descricaoEs, setDescricaoEs] = useState(data?.Descricao?.es || '');

  const [modalVisible, setModalVisible] = useState<Array<string>>([]);
  const [error, setError] = useState('');

  const onSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      await ACRepository.updateRegister(comerciante?.cnpj, comerciante?.repo, data.key, {
        nome: nome.trim(),
        cadastur: cadastur.replace(/\D/g, ''),
        tipo: tipo.trim(),
        classificacao,
        telefones: [
          { numero: telefoneFixo.replace(/\D/g, ''), tipo: 'fixo', whatsapp: false },
          { numero: telefoneCel.replace(/\D/g, ''), tipo: 'celular', whatsapp: isWhatsApp }
        ],
        email: email.trim(),
        site: site.trim(),
        facebook: facebook.trim(),
        instagram: instagram.trim(),
        video: video.trim(),
        endereco: endereco.trim(),
        urlMaps: urlMaps.trim(),
        descricao: { pt: descricaoPt.trim(), en: descricaoEn.trim(), es: descricaoEs.trim() }
      });

      setIsLoading(false);
    } catch (err) {
      __DEV__ && console.log('[ERROR:ACCadastro.onSubmit]', err);
      setIsLoading(false);
      setError('Falha ao salvar o cadastro!');
    }
  }, [
    data,
    comerciante,
    setIsLoading,
    nome,
    cadastur,
    tipo,
    classificacao,
    telefoneFixo,
    telefoneCel,
    isWhatsApp,
    email,
    site,
    facebook,
    instagram,
    video,
    urlMaps,
    endereco,
    descricaoPt,
    descricaoEn,
    descricaoEs
  ]);

  if (!data) return null;

  return (
    <>
      <View sx={{ flex: 1 }}>
        <Text
          sx={{
            marginLeft: [10],
            marginRight: [10],
            marginTop: [25],
            //marginBottom: [25],
            fontSize: [14],
            color: ['$text']
          }}>
          Mantenha seu cadastro atualizado!
        </Text>
        <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Nome da empresa' />
            <TextInput
              value={nome}
              placeholderTextColor={Colors.primary3}
              onChangeText={setNome}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Cadastur' />
            <TextInput
              value={cadastur}
              placeholderTextColor={Colors.primary3}
              onChangeText={setCadastur}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
        </View>

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          {['Restaurante'].includes(comerciante.repo) && (
            <View sx={{ flex: 1 }}>
              <FormLabel text='Especialidade' />
              <TextInput
                value={tipo}
                placeholderTextColor={Colors.primary3}
                onChangeText={setTipo}
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: '$primary3',
                  marginRight: 25,
                  marginLeft: 25,
                  marginBottom: 10,
                  color: '$primary3',
                  height: Platform.select({ web: [30], android: [45] }),
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}
              />
            </View>
          )}
          <View sx={{ flex: 1 }}>
            <FormLabel text='Classificação Preço' />
            <TouchableOpacity
              onPress={() => setModalVisible(CLASSIFICACOES)}
              style={sx({
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                height: Platform.select({ web: [30], android: [45] }),
                flexDirection: ['row'],
                alignItems: ['center']
              })}>
              <Text
                sx={{
                  flex: 1,
                  color: '$primary3',
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}>
                {classificacao}
              </Text>
              <IconIonicon name='caret-down' color={Colors.primary3} size={25} />
            </TouchableOpacity>
          </View>
        </View>

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Telefone Fixo' />
            <TextInput
              value={telefoneFixo}
              placeholderTextColor={Colors.primary3}
              onChangeText={maskText('phone', text => setTelefoneFixo(text as string))}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Telefone Celular' />
            <TextInput
              value={telefoneCel}
              placeholderTextColor={Colors.primary3}
              onChangeText={maskText('phone', text => setTelefoneCel(text as string))}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
            <View
              sx={{
                flexDirection: ['row'],
                alignItems: ['center'],
                alignSelf: ['flex-end'],
                marginBottom: [10],
                marginRight: 25
              }}>
              <Checkbox
                color={Colors.primary3}
                status={isWhatsApp ? 'checked' : 'unchecked'}
                onPress={() => setIsWhatsApp(!isWhatsApp)}
              />
              <Text
                sx={{
                  color: '$text',
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}>
                WhatsApp
              </Text>
            </View>
          </View>
        </View>

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Email' />
            <TextInput
              value={email}
              placeholderTextColor={Colors.primary3}
              onChangeText={setEmail}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
              keyboardType='email-address'
              autoCapitalize='none'
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Site' />
            <TextInput
              value={site}
              placeholderTextColor={Colors.primary3}
              onChangeText={setSite}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
              autoCapitalize='none'
            />
          </View>
        </View>

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Facebook' />
            <TextInput
              value={facebook}
              placeholderTextColor={Colors.primary3}
              onChangeText={setFacebook}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
              autoCapitalize='none'
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Instagram' />
            <TextInput
              value={instagram}
              placeholderTextColor={Colors.primary3}
              onChangeText={setInstagram}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
              autoCapitalize='none'
            />
          </View>
        </View>

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Vídeo/Youtube' />
            <TextInput
              value={video}
              placeholderTextColor={Colors.primary3}
              onChangeText={setVideo}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
              autoCapitalize='none'
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Endereço' />
            <TextInput
              value={endereco}
              placeholderTextColor={Colors.primary3}
              onChangeText={setEndereco}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
        </View>

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Link do local no Google Maps' />
            <TextInput
              value={urlMaps}
              placeholderTextColor={Colors.primary3}
              onChangeText={setUrlMaps}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
        </View>

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Descrição (Português)' />
            <TextInput
              value={descricaoPt}
              placeholderTextColor={Colors.primary3}
              onChangeText={setDescricaoPt}
              multiline
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [100], android: [100] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Descrição (Inglês)' />
            <TextInput
              value={descricaoEn}
              placeholderTextColor={Colors.primary3}
              onChangeText={setDescricaoEn}
              multiline
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [100], android: [100] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Descrição (Espanhol)' />
            <TextInput
              value={descricaoEs}
              placeholderTextColor={Colors.primary3}
              onChangeText={setDescricaoEs}
              multiline
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [100], android: [100] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
        </View>

        <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
          <ContainedButton text='Salvar' color={Colors.primary3} onPress={onSubmit} />
        </View>
      </View>

      <Portal>
        <Modal
          visible={modalVisible?.length > 0}
          onDismiss={() => setModalVisible([])}
          contentContainerStyle={sx({
            width: ['95%', '95%', '50%'],
            alignSelf: ['center'],
            backgroundColor: '$white',
            margin: ['$4'],
            borderRadius: [10]
            //marginLeft: [10],
            //marginRight: [10]
          })}>
          {modalVisible.map((item, index) => (
            <React.Fragment key={`segmento-${index}`}>
              <List.Item
                title={item}
                onPress={() => {
                  if (CLASSIFICACOES.includes(item)) setClassificacao(item);
                  setModalVisible([]);
                }}
                titleStyle={sx({
                  fontSize: [16, 16, 18],
                  color: '$primary3'
                })}
              />
              <Divider />
            </React.Fragment>
          ))}
        </Modal>
      </Portal>

      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ bottom: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

const ACImagens: React.FC<{ data: any }> = ({ data }) => {
  const sx = useSx();
  const { width, height } = useWindowDimensions();

  const [images, setImages] = useState<Array<string>>(data?.Images || []);
  const [error, setError] = useState('');

  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [comerciante] = useAtom(comercianteAtom);

  const onDeleteImage = useCallback(
    async (index: number) => {
      try {
        setIsLoading(true);

        const newImages = images.filter((_, i) => i !== index);

        const jsonData = {
          repo: comerciante?.repo,
          key: data.key,
          image: images[index].split('/').pop(),
          images: newImages
        };

        const response = await axios.post(`${BASE_URL}/comercio-image/delete.php`, jsonData, {
          headers: { 'Content-Type': 'application/json' }
        });
        __DEV__ && console.log('response.data', response.data);

        if (response.data.success) {
          setImages(newImages);
        } else {
          setError(response.data.message || 'Falha ao excluir imagem!');
        }
      } catch (err) {
        __DEV__ && console.log('err', err);
        setError('Falha o excluir imagem!');
      } finally {
        setIsLoading(false);
      }
    },
    [comerciante, data, images, setIsLoading]
  );

  if (!data) return null;

  return (
    <>
      <View sx={{ flex: 1 }}>
        <Text
          sx={{
            marginLeft: [10],
            marginRight: [10],
            marginTop: [25],
            marginBottom: [25],
            fontSize: [14],
            color: ['$text']
          }}>
          Cadastre até 10 imagens do estabelecimento!
        </Text>

        <View sx={{ marginLeft: [10], marginRight: [10] }}>
          <input
            type='file'
            id='file-image'
            name='file-image'
            accept='image/jpg, image/jpeg, image/png'
            disabled={images.length === 10}
            onChange={async e => {
              e.preventDefault();

              try {
                setIsLoading(true);

                // @ts-ignore
                const file = e?.target?.files[0];

                const formData = new FormData();

                formData.append(
                  'imageRef',
                  `${comerciante?.repo}/${data.key}/Images/${images.length}`
                );
                formData.append('image', file);

                const response = await axios.post(
                  `${BASE_URL}/comercio-image/create.php`,
                  formData,
                  {
                    headers: { 'Content-Type': 'multipart/form-data' }
                  }
                );

                __DEV__ && console.log('response.data', response.data);

                if (response.data.success) {
                  setImages([...images, response.data.data]);
                }
              } catch (err) {
                console.log('err', err);
                setError('Falha ao cadastrar imagem.');
              } finally {
                setIsLoading(false);
                // @ts-ignore
                document.querySelector('#file-image').value = '';
              }
            }}
          />
        </View>
        <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />

        <View
          sx={{
            flexDirection: ['column', 'column', 'row'],
            alignItems: ['center'],
            flexWrap: ['wrap']
          }}>
          {images.map((item, index) => (
            <View
              key={`image-${index}`}
              sx={{
                height: [height / 3 + 20, height / 3 + 15],
                width: [width - 50, (width / 3) * 2, width * 0.3],
                marginRight: [undefined, undefined, 20],
                marginBottom: 20,
                alignSelf: ['center']
              }}>
              <ImageBackground
                source={Deck as any}
                style={sx({ width: '100%', height: '100%' })}
                imageStyle={sx({ borderRadius: [4, 8] })}>
                <Image
                  source={{ uri: item }}
                  style={sx({ width: '100%', height: '100%', borderRadius: [4, 8] })}
                />

                <IconButton
                  icon='trash-can'
                  color={Colors.white}
                  size={30}
                  onPress={() => onDeleteImage(index)}
                  style={sx({
                    position: ['absolute'],
                    top: [10],
                    right: [10],
                    backgroundColor: 'rgba(0,0,0,0.4)'
                  })}
                />
              </ImageBackground>
            </View>
          ))}
        </View>
      </View>
      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ top: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

const ACCardapio: React.FC<{ data: any }> = ({ data }) => {
  const sx = useSx();

  const [prodNome, setProdNome] = useState('');
  const [prodPreco, setProdPreco] = useState('');
  const [prodDescricao, setProdDescricao] = useState('');
  const [prodImage, setProdImage] = useState<any>();
  const [produtos, setProdutos] = useState<Array<any>>(data?.Produtos || []);
  const [error, setError] = useState('');

  const [comerciante] = useAtom(comercianteAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const onSubmit = useCallback(async () => {
    if (!prodNome || !prodPreco || !prodDescricao || !prodImage) {
      setError('Preencha todos os campos!');
      return;
    }

    try {
      setIsLoading(true);

      const formData = new FormData();

      formData.append('index', `${produtos.length}`);
      formData.append('image', prodImage);
      formData.append('nome', prodNome);
      formData.append('preco', prodPreco);
      formData.append('descricao', prodDescricao);
      formData.append('key', data.key);
      formData.append('repo', comerciante?.repo);

      const response = await axios.post(`${BASE_URL}/comercio-cardapio/create.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      __DEV__ && console.log('response.data', response.data);

      if (response.data.success) {
        setProdutos([...produtos, response.data.data]);

        // @ts-ignore
        document.querySelector('#prod-image').value = '';
        setProdImage(undefined);
        setProdNome('');
        setProdPreco('');
        setProdDescricao('');
      } else {
        setError(response.data.message || 'Falha ao cadastrar o produto!');
      }
    } catch (err) {
      __DEV__ && console.log('err', err);
      setError('Falha ao cadastrar imagem.');
    } finally {
      setIsLoading(false);
    }
  }, [
    produtos,
    prodImage,
    setError,
    data,
    comerciante,
    setIsLoading,
    prodNome,
    prodPreco,
    prodDescricao
  ]);

  const onDeleteItem = useCallback(
    async (index: number) => {
      try {
        setIsLoading(true);

        const newProds = produtos.filter((_, i) => i !== index);

        const jsonData = {
          repo: comerciante?.repo,
          key: data.key,
          image: produtos[index].Image.split('/').pop(),
          produtos: newProds
        };

        const response = await axios.post(`${BASE_URL}/comercio-cardapio/delete.php`, jsonData, {
          headers: { 'Content-Type': 'application/json' }
        });
        __DEV__ && console.log('response.data', response.data);

        if (response.data.success) {
          setProdutos(newProds);
        } else {
          setError(response.data.message || 'Falha ao excluir item!');
        }
      } catch (err) {
        __DEV__ && console.log('err', err);
        setError('Falha ao excluir item.');
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setError, produtos, comerciante, data]
  );

  if (!data) return null;

  return (
    <>
      <View sx={{ flex: 1 }}>
        <Text
          sx={{
            marginLeft: [10],
            marginRight: [10],
            marginTop: [25],
            //marginBottom: [25],
            fontSize: [14],
            color: ['$text']
          }}>
          Adicione itens do cardápio!
        </Text>
        <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Nome' />
            <TextInput
              value={prodNome}
              placeholderTextColor={Colors.primary3}
              onChangeText={setProdNome}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Preço' />
            <TextInput
              value={prodPreco}
              placeholderTextColor={Colors.primary3}
              onChangeText={maskText('money', text => setProdPreco(text as string))}
              keyboardType='numeric'
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
        </View>

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Descrição' />
            <TextInput
              value={prodDescricao}
              placeholderTextColor={Colors.primary3}
              onChangeText={setProdDescricao}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Imagem' />
            <View sx={{ marginLeft: [10], marginRight: [10], marginBottom: 15 }}>
              <input
                type='file'
                id='prod-image'
                name='prod-image'
                accept='image/jpg, image/jpeg, image/png'
                onChange={async e => {
                  e.preventDefault();
                  // @ts-ignore
                  const file = e?.target?.files[0];
                  setProdImage(file);
                }}
              />
            </View>
          </View>
        </View>

        <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
          <ContainedButton text='Salvar' color={Colors.primary3} onPress={onSubmit} />
        </View>

        {produtos.length > 0 && (
          <View>
            <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />
            {produtos.map((item: any, index) => (
              <View
                key={`prod-${index}`}
                sx={{
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: '$primary1',
                  marginRight: [15],
                  marginLeft: [15],
                  marginBottom: 10,
                  flexDirection: ['row'],
                  padding: 5
                }}>
                <Image
                  source={{ uri: item.Image }}
                  resizeMode='cover'
                  style={sx({ width: 80, height: 80, borderRadius: 10 })}
                />
                <View sx={{ flex: 1, marginRight: 4, marginLeft: 4 }}>
                  <Text sx={{ fontSize: 16, color: '$text', fontWeight: ['bold'] }}>
                    {item.Nome}
                  </Text>
                  <Text sx={{ fontSize: 14, color: '$text' }}>{item.Descricao}</Text>
                  <Text sx={{ fontSize: 16, color: '$primary3' }}>{item.Preco}</Text>
                  <IconButton
                    icon='trash-can'
                    color={Colors.white}
                    size={20}
                    onPress={() => onDeleteItem(index)}
                    style={sx({
                      position: ['absolute'],
                      top: [0],
                      right: [0],
                      backgroundColor: 'rgba(0,0,0,0.4)'
                    })}
                  />
                </View>
              </View>
            ))}
          </View>
        )}
      </View>
      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ top: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

const ACDisponibilidade: React.FC<{ data: any }> = ({ data }) => {
  const sx = useSx();

  const [date, setDate] = useState<any>('');
  const [disponibilidade, setDisponibilidade] = useState('');
  const [haVagas, setHaVagas] = useState(false);
  const [lotado, setLotado] = useState(false);
  const [dates, setDates] = useState<Array<any>>(data?.Disponibilidade || []);
  const [error, setError] = useState('');

  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [comerciante] = useAtom(comercianteAtom);

  const onSubmit = useCallback(async () => {
    if (!date || (!haVagas && !lotado)) {
      setError('Preencha todos os campos!');
      return;
    }

    if (dates.find(d => d.Data === date)) {
      setError('Essa data já foi inserida!');
      return;
    }

    try {
      setIsLoading(true);

      const response = await ACRepository.addDisponibilidade(comerciante?.repo, data.key, {
        date,
        index: `${dates.length}`,
        status: haVagas ? 'disponivel' : lotado ? 'lotado' : '',
        numVagas: disponibilidade
      });

      __DEV__ && console.log('response', response);

      // @ts-ignore
      document.querySelector('#date-dispon').value = '';
      setDate('');
      setDisponibilidade('');
      setHaVagas(false);
      setLotado(false);
      setDates([...dates, response]);
    } catch (err) {
      __DEV__ && console.log('err', err);
      setError('Falha ao cadastrar imagem.');
    } finally {
      setIsLoading(false);
    }
  }, [date, disponibilidade, haVagas, setIsLoading, lotado, data, comerciante, dates]);

  const onDeleteItem = useCallback(
    async (index: number) => {
      try {
        setIsLoading(true);

        const newDates = dates.filter((_, i) => i !== index);

        const response = await ACRepository.removeDisponibilidade(comerciante?.repo, data.key, {
          dates: newDates
        });
        __DEV__ && console.log('response', response);

        setDates(newDates);
      } catch (err) {
        __DEV__ && console.log('err', err);
        setError('Falha ao excluir item.');
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setError, dates, comerciante, data]
  );

  if (!data) return null;

  return (
    <>
      <View sx={{ flex: 1 }}>
        <Text
          sx={{
            marginLeft: [10],
            marginRight: [10],
            marginTop: [25],
            //marginBottom: [25],
            fontSize: [14],
            color: ['$text']
          }}>
          Informe os clientes sobre disponibilidade!
        </Text>
        <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Data' />
            <View sx={{ marginLeft: [10], marginRight: [10], marginBottom: 15 }}>
              <input
                type='date'
                id='date-dispon'
                name='date-dispon'
                min={new Date().toISOString().slice(0, 10)}
                onChange={async e => {
                  e.preventDefault();
                  // @ts-ignore
                  const value = e?.target?.value;
                  setDate(value);
                }}
              />
            </View>
          </View>

          <View sx={{ flex: 1 }}>
            <FormLabel text='Disponibilidade' />
            <View
              sx={{
                flexDirection: ['row'],
                alignItems: ['center'],
                alignSelf: ['center'],
                marginBottom: [10]
              }}>
              <Checkbox
                color={Colors.primary3}
                status={haVagas ? 'checked' : 'unchecked'}
                onPress={() => {
                  setHaVagas(!haVagas);
                  setLotado(false);
                }}
              />
              <Text
                sx={{
                  color: '$text',
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}>
                Há vagas
              </Text>
              <Checkbox
                color={Colors.primary3}
                disabled={haVagas}
                status={lotado ? 'checked' : 'unchecked'}
                onPress={() => setLotado(!lotado)}
              />
              <Text
                sx={{
                  color: '$text',
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}>
                Lotado
              </Text>
            </View>
            <TextInput
              value={disponibilidade}
              placeholder='Número de Vagas/Mesas disponíveis'
              placeholderTextColor={Colors.text}
              onChangeText={maskText('number', text => setDisponibilidade(text as string))}
              editable={haVagas}
              maxLength={3}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
        </View>

        <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
          <ContainedButton text='Salvar' color={Colors.primary3} onPress={onSubmit} />
        </View>

        {dates.length > 0 && (
          <View>
            <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />
            {dates
              .map((d: any, i: number) => ({ ...d, index: i }))
              .sort(
                // @ts-ignore
                sortByKey('Data')
              )
              .map((item: any, index) => (
                <View
                  key={`prod-${index}`}
                  sx={{
                    borderBottomWidth: 1,
                    borderColor: '$primary3',
                    marginRight: [15],
                    marginLeft: [15],
                    flexDirection: ['row'],
                    justifyContent: ['space-between'],
                    alignItems: ['center'],
                    padding: [10],
                    backgroundColor: index % 2 === 0 ? Colors.placeholderLight : undefined
                  }}>
                  <Text sx={{ fontSize: 16, color: '$primary3', fontWeight: ['bold'] }}>
                    {formatDateDispon(item.Data)}
                  </Text>

                  <View sx={{ marginRight: 4, marginLeft: 4, alignItems: ['center'] }}>
                    <Text sx={{ fontSize: 16, color: '$primary3' }}>
                      {item.Status === 'lotado' ? 'Lotado' : 'Disponível'}
                    </Text>
                    {item.Status === 'disponivel' && item.NumVagas ? (
                      <Text sx={{ fontSize: 12, fontWeight: ['bold'], color: '$success' }}>
                        {item.NumVagas} vagas/mesas
                      </Text>
                    ) : null}
                  </View>

                  <IconButton
                    icon='trash-can'
                    color={Colors.white}
                    size={16}
                    onPress={() => onDeleteItem(item.index)}
                    style={sx({
                      backgroundColor: 'rgba(0,0,0,0.4)'
                    })}
                  />
                </View>
              ))}
          </View>
        )}
      </View>
      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ top: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

const ACDesconto: React.FC<{ data: any }> = ({ data }) => {
  const sx = useSx();

  const [desconto, setDesconto] = useState(data?.Desconto || '');
  const [condicao, setCondicao] = useState(data?.Condicao || '');
  const [deviceCode, setDeviceCode] = useState('');
  const [validator, setValidator] = useState('');
  const [error, setError] = useState('');

  const [comerciante] = useAtom(comercianteAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const onSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      await ACRepository.setDesconto(comerciante?.repo, data.key, {
        desconto,
        condicao
      });

      setIsLoading(false);
    } catch (err) {
      __DEV__ && console.log('[ERROR:ACDesconto.onSubmit]', err);
      setIsLoading(false);
      setError('Falha ao salvar o desconto!');
    }
  }, [setIsLoading, data, comerciante, desconto, condicao]);

  const validarDesconto = useCallback(() => {
    if (!deviceCode) {
      setValidator('');
      return;
    }

    setValidator(getValidator(deviceCode));
  }, [deviceCode]);

  if (!data) return null;

  return (
    <>
      <View sx={{ flex: 1 }}>
        <Text
          sx={{
            marginLeft: [10],
            marginRight: [10],
            marginTop: [25],
            //marginBottom: [25],
            fontSize: [14],
            color: ['$text']
          }}>
          Gerencie o desconto ao cliente que usa o{' '}
          <Text sx={{ fontWeight: ['bold'] }}>Opção Costa Verde!</Text>
        </Text>

        <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Desconto em %' />
            <TextInput
              value={desconto}
              placeholderTextColor={Colors.primary3}
              onChangeText={maskText('number', text => setDesconto(text as string))}
              maxLength={2}
              keyboardType='numeric'
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Condições/Informações ao cliente' />
            <TextInput
              value={condicao}
              placeholderTextColor={Colors.primary3}
              onChangeText={setCondicao}
              multiline
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
        </View>

        <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
          <ContainedButton text='Salvar' color={Colors.primary3} onPress={onSubmit} />
        </View>

        <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Inserir Código do dispositivo' />
            <TextInput
              value={deviceCode}
              placeholderTextColor={Colors.primary3}
              onChangeText={maskText('number', text => setDeviceCode(text as string))}
              maxLength={6}
              keyboardType='numeric'
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
        </View>

        <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
          <ContainedButton text='Validar' color={Colors.primary3} onPress={validarDesconto} />
        </View>

        {validator ? (
          <View
            sx={{
              borderWidth: 1,
              borderColor: '$primary3',
              borderStyle: ['dashed'],
              padding: [10],
              marginTop: [15],
              marginBottom: [15]
            }}>
            <Text sx={{ alignSelf: ['center'] }}>
              A soma de verificação no dispositivo deverá ser igual a:
            </Text>
            <Text sx={{ alignSelf: ['center'], fontSize: 20, fontWeight: ['bold'] }}>
              {validator}
            </Text>
          </View>
        ) : null}
      </View>
      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ top: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

const ACSeguranca: React.FC<{ data: any }> = ({ data }) => {
  const sx = useSx();

  const [senhaAtual, setSenhaAtual] = useState('');
  const [senhaNova, setSenhaNova] = useState('');
  const [repeteSenhaNova, setRepeteSenhaNova] = useState('');
  const [error, setError] = useState('');

  const [comerciante] = useAtom(comercianteAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const onSubmit = useCallback(async () => {
    if (!senhaAtual || !senhaNova || !repeteSenhaNova) {
      setError('Preencha todos os campos!');
      return;
    }

    if (senhaNova !== repeteSenhaNova) {
      setError('A senha nova não coincide com a confirmação!');
      return;
    }

    try {
      setIsLoading(true);

      await ACRepository.updateSenha(
        comerciante?.repo,
        data.key,
        {
          email: comerciante.email,
          senhaAtual,
          senhaNova
        },
        comerciante?.token
      );

      setSenhaAtual('');
      setSenhaNova('');
      setRepeteSenhaNova('');
      setError('Senha atualizada!');
    } catch (err) {
      __DEV__ && console.log('[ERROR:AcSeguranca.onSubmit]', err);
      setError((err as any)?.message || 'Falha ao trocar senha!');
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, data, comerciante, senhaAtual, senhaNova, repeteSenhaNova]);

  if (!data) return null;

  return (
    <>
      <View sx={{ flex: 1 }}>
        <Text
          sx={{
            marginLeft: [10],
            marginRight: [10],
            marginTop: [25],
            //marginBottom: [25],
            fontSize: [14],
            color: ['$text']
          }}>
          Alterar Senha
        </Text>

        <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />

        <View sx={{ flexDirection: ['column', 'column', 'row'] }}>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Senha Atual' />
            <TextInput
              value={senhaAtual}
              placeholderTextColor={Colors.primary3}
              onChangeText={setSenhaAtual}
              secureTextEntry
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Nova Senha' />
            <TextInput
              value={senhaNova}
              placeholderTextColor={Colors.primary3}
              onChangeText={setSenhaNova}
              secureTextEntry
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FormLabel text='Confirme a Nova Senha' />
            <TextInput
              value={repeteSenhaNova}
              placeholderTextColor={Colors.primary3}
              onChangeText={setRepeteSenhaNova}
              secureTextEntry
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />
          </View>
        </View>

        <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
          <ContainedButton text='Salvar' color={Colors.primary3} onPress={onSubmit} />
        </View>
      </View>
      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ top: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

const FormLabel: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Text
      sx={{
        color: '$primary3',
        marginLeft: 30,
        fontSize: '$1',
        fontWeight: Platform.select({ web: ['bold'] }),
        fontFamily: Platform.select({ android: 'Poppins-Bold' })
      }}>
      {text}
    </Text>
  );
};
