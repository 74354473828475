import React, { useContext } from 'react';
import {
  TouchableOpacity,
  useWindowDimensions,
  ImageBackground,
  Image,
  Platform
} from 'react-native';
import Icon from 'react-native-vector-icons/AntDesign';
import { View, Text, useSx } from 'dripsy';
import { useLinkTo } from '@react-navigation/native';
import { useAtom } from 'jotai';
import Deck from '../../assets/images/deck.jpg';
import { LanguageContext } from '../../context/LanguageContext';
import { CIDADE } from '../../values/constants';
import TagTopLeft from '../TagTopLeft';
import TagTopRight from '../TagTopRight';
import TagBottomLeft from '../TagBottomLeft';
import TagBottomRight from '../TagBottomRight';
import { likesAtom, selectedItemAtom, userAtom } from '../../atoms';

type ServiceItemProps = {
  item: any;
};

const ServiceItem: React.FC<ServiceItemProps> = ({ item }) => {
  const sx = useSx();
  const linkTo = useLinkTo();
  const { width, height } = useWindowDimensions();
  const [{ strings: str, lang }] = useContext(LanguageContext);
  const [, setSelectedItem] = useAtom(selectedItemAtom);
  const [likes] = useAtom(likesAtom);
  const [user] = useAtom(userAtom);

  const repo = item?.repo;
  const titleKey = item?.Titulo ? 'Titulo' : ['Promocao'].includes(repo) ? 'Descricao' : '';

  const preco = [
    'Hospedagem',
    'Restaurante',
    'Passeio',
    'Transfer',
    'Mergulho',
    'Camping',
    'Estacionamento'
  ].includes(repo)
    ? item?.Preco || '$$'
    : '';
  const tipo = !['Lugares', 'Lancha'].includes(repo) ? item?.Tipo || item?.Especialidade || '' : '';
  const promocao = item?.Promocao ? str.conhecapromocao : '';
  const desconto = item?.Desconto
    ? `${str.apresente} App.\n${str.Desconto} ${item?.Desconto}%`
    : '';

  const topLeft = item?.Parceiro ? str.destaque : '';
  const topRight = desconto || promocao;
  const bottomLeft = tipo;
  const bottomRight = preco;

  const titulo =
    repo === 'Acesso'
      ? `${item.Origem} ↔ ${
          // @ts-ignore
          CIDADE[item.Local]
        }`
      : titleKey
      ? item[titleKey][lang]
      : item.Nome ||
        // @ts-ignore
        CIDADE[item.Local];

  let curtidas = item.Curtidas ? Object.keys(item.Curtidas).length : 0;
  curtidas =
    user && item.Curtidas && Object.keys(item.Curtidas).includes(user.uid)
      ? curtidas
      : likes.includes(`${item.repo}/${item.id}`)
      ? curtidas + 1
      : curtidas;

  return (
    <TouchableOpacity
      activeOpacity={0.75}
      onPress={() => {
        setSelectedItem(item);
        linkTo(`/servico/${item.Local}/${repo}/${item.id}`);
      }}
      style={sx({
        marginBottom: [10],
        marginLeft: [10],
        marginRight: [10],
        width: [width - 50, (width / 3) * 2, width * 0.3]
      })}>
      <View
        sx={{
          height: [height / 3 + 20, height / 3 + 15],
          width: [width - 50, (width / 3) * 2, width * 0.3]
        }}>
        <ImageBackground
          source={Deck as any}
          style={sx({ width: '100%', height: '100%' })}
          imageStyle={sx({ borderRadius: [4, 8] })}>
          <Image
            source={{ uri: item?.Images?.[0] || item?.UrlImagem }}
            style={sx({ width: '100%', height: '100%', borderRadius: [4, 8] })}
          />

          {topLeft ? <TagTopLeft label={str.destaque} /> : null}

          {topRight ? <TagTopRight label={topRight} /> : null}

          {bottomLeft ? <TagBottomLeft label={bottomLeft} /> : null}

          {bottomRight ? <TagBottomRight label={bottomRight} /> : null}
        </ImageBackground>
      </View>

      <View sx={{ flexDirection: ['row'], padding: [4, 4, 6] }}>
        <View sx={{ flex: 1, flexDirection: ['row'], alignItems: ['flex-start'] }}>
          {typeof titulo === 'string' ? (
            <Text
              numberOfLines={1}
              sx={{
                flex: 1,
                color: '$text',
                fontSize: [16, 16, 18],
                marginLeft: 5,
                textAlign: 'left',
                flexWrap: 'wrap',
                marginRight: 10,
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}>
              {titulo}
            </Text>
          ) : (
            titulo
          )}
        </View>
        <View sx={{ alignItems: 'center', flexDirection: 'row' }}>
          <Icon name='heart' size={16} color='red' />
          <Text
            sx={{
              color: '$primary3',
              fontSize: [16, 16, 18],
              borderRadius: 10,
              marginLeft: 4,
              fontFamily: Platform.select({ android: 'Poppins-Regular' })
            }}>
            {curtidas || ' '}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default ServiceItem;
