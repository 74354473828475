import React from 'react';
import { useSx } from 'dripsy';
import { useLinkTo } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';
import { Colors } from '../../theme';

const HeaderOptions: React.FC = () => {
  const sx = useSx();
  const linkTo = useLinkTo();

  return (
    <IconButton
      icon='information'
      onPress={() => linkTo('/saiba-mais')}
      size={24}
      color={Colors.primary3}
      style={sx({ marginRight: [10, 10, 15] })}
    />
  );
};

export default HeaderOptions;
