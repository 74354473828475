import { CadAnuncioComercio, CadComercio, SegmentoComercio } from '../model/CadComercio';
import { Local } from '../model/Local';
import ApiService from '../services/ApiService';

type CreateRegisterResponse = {
  token: string;
  email: string;
  nome: string;
  segmento: string;
};

const REPO_BY_SEGMENTO = {
  Hospedagem: 'Hospedagem',
  BaresRestaurantes: 'Restaurante',
  Camping: 'Camping',
  Estacionamento: 'Estacionamento',
  Lanchas: 'Lancha',
  //'' => 'Loja',
  //'' => 'Marina',
  PasseiosDeBarco: 'Passeio',
  Experiencias: 'Experiencia',
  Mergulho: 'Mergulho',
  Transfer: 'Transfer',
  TaxiBoat: 'TaxiBoat'
};

export class ACRepository {
  static async signin(email: string, senha: string) {
    try {
      const res = await ApiService.post<CreateRegisterResponse>({
        route: '/login', //, 'comercio-login/index',
        data: {
          username: email,
          password: senha
        }
      });

      //if (!res.success || !res.data) throw new Error(res.message || 'ERROR:signin');

      return {
        ...res,
        // @ts-ignore
        repo: REPO_BY_SEGMENTO[res.segmento]
      };
    } catch (err) {
      throw err;
    }
  }

  static async createRegister(data: CadComercio) {
    try {
      const res = await ApiService.post<CreateRegisterResponse>({
        route: '/cadastrar-empresa', //'comercio/create',
        data: {
          username: data.email,
          email: data.email,
          password: data.senha,
          rePassword: data.senha,
          local: (
            ['ilha-grande', 'paraty', 'angra-dos-reis', 'mangaratiba'] as Array<Local>
          ).findIndex(l => l === data.local),
          segmento: (
            [
              'Hospedagem',
              'Bares e Restaurantes',
              'Camping',
              'Estacionamento',
              'Experiências',
              'Transfer',
              'Mergulho',
              'Passeios de Barco',
              'Lanchas'
            ] as Array<SegmentoComercio>
          ).findIndex(s => s === data.segmento),
          phoneNumber: data.telefone,
          cnpj: data.cnpj,
          endereco: data.endereco,
          plano: data.plano
        }
      });

      //if (!res.success || !res.data) throw new Error(res.message || 'ERROR:createRegister');

      return res;
    } catch (err) {
      throw err;
    }
  }

  static async recovery(email: string, token?: string) {
    try {
      const res = await ApiService.post<any>({
        route: '/reset-senha', //'comercio-login/recovery',
        data: { email },
        token
      });

      //if (!res.success) throw new Error(res.message || 'ERROR:recovery');
      return res;
    } catch (err) {
      throw err;
    }
  }

  static async logout(email: string, token?: string) {
    try {
      const res = await ApiService.post<any>({
        route: '/logout',
        data: { email },
        token
      });

      //if (!res.success) throw new Error(res.message || 'ERROR:logout');
      return res;
    } catch (err) {
      throw err;
    }
  }

  static async updateRegister(cnpj: string, repo: string, key: string, data: CadAnuncioComercio) {
    try {
      const res: any = await ApiService.post<CreateRegisterResponse>({
        route: 'comercio/update',
        data: { cnpj, repo, key, ...data }
      });

      if (!res.success) throw new Error(res.message || 'ERROR:updateRegister');

      return res.data;
    } catch (err) {
      throw err;
    }
  }

  static async uploadImage(cnpj: string, repo: string, key: string, data: CadAnuncioComercio) {
    try {
      const res: any = await ApiService.post<CreateRegisterResponse>({
        route: 'comercio/update',
        data: { cnpj, repo, key, ...data }
      });

      if (!res.success) throw new Error(res.message || 'ERROR:updateRegister');

      return res.data;
    } catch (err) {
      throw err;
    }
  }

  static async setDesconto(
    repo: string,
    key: string,
    data: { desconto: string; condicao: string }
  ) {
    try {
      const res: any = await ApiService.post<CreateRegisterResponse>({
        route: 'comercio-desconto/index',
        data: { repo, key, ...data }
      });

      if (!res.success) throw new Error(res.message || 'ERROR:setDesconto');

      return res.data;
    } catch (err) {
      throw err;
    }
  }

  static async addDisponibilidade(
    repo: string,
    key: string,
    data: { status: string; date: string; numVagas: string; index: string }
  ) {
    try {
      const res = await ApiService.post<any>({
        route: 'comercio-disponibilidade/create',
        data: { repo, key, ...data }
      });

      if (!res.success) throw new Error(res.message || 'ERROR:addDisponibilidade');

      return res.data;
    } catch (err) {
      throw err;
    }
  }

  static async removeDisponibilidade(repo: string, key: string, data: { dates: any }) {
    try {
      const res = await ApiService.post<any>({
        route: 'comercio-disponibilidade/delete',
        data: { repo, key, ...data }
      });

      if (!res.success) throw new Error(res.message || 'ERROR:removeDisponibilidade');

      return res.data;
    } catch (err) {
      throw err;
    }
  }

  static async migrarPlano(repo: string, key: string) {
    try {
      const res = await ApiService.post<any>({
        route: 'comercio-plano/migrar',
        data: { repo, key }
      });

      if (!res.success) throw new Error(res.message || 'ERROR:migrarPlano');

      return res.data;
    } catch (err) {
      throw err;
    }
  }

  static async updateSenha(
    repo: string,
    key: string,
    data: { email: string; senhaAtual: string; senhaNova: string },
    token?: string
  ) {
    try {
      const res = await ApiService.post<any>({
        route: '/efetua-reset', //'comercio-login/update-senha',
        data: {
          password: data.senhaNova,
          rePassword: data.senhaNova,
          email: data.email,
          token
        },
        token
        //{ repo, key, ...data }
      });

      //if (!res.success) throw new Error(res.message || 'ERROR:updateSenha');

      return res;
    } catch (err) {
      throw err;
    }
  }
}
