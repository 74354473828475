import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import { TouchableOpacity, Linking, useWindowDimensions, FlatList, Platform } from 'react-native';
import { View, Text, Image, useSx } from 'dripsy';
import { LanguageContext } from '../../context/LanguageContext';
// @ts-ignore
import { RTDatabase } from '../../shims/firebase';
// @ts-ignore
import { Dialog } from '../../shims/dialog';

const BannerNoticias: React.FC = () => {
  const sx = useSx();
  const listRef = useRef<FlatList>(null);
  const activeRef = useRef(0);
  const sizeRef = useRef(1);
  const { width, height } = useWindowDimensions();
  const [items, setItems] = useState<Array<any>>([ITEM_PLACEHOLDER]);
  const [{ strings: str }] = useContext(LanguageContext);

  const isVertical = useMemo(() => height > width, [height, width]);

  useEffect(() => {
    const unsubscribe = RTDatabase.onValue('Banner', (snapshot: any) => {
      if (snapshot) {
        let _items = Object.entries(snapshot)
          // @ts-ignore
          .map(([key, value]) => ({ id: key, ...value }))
          .filter(i => i.Ativo);
        setItems(_items);
        sizeRef.current = _items.length;
      }
    });

    return () => unsubscribe?.();
  }, []);

  useEffect(() => {
    if (items.length > 1) {
      let interval = setInterval(() => {
        listRef.current?.scrollToIndex({
          index: activeRef.current < sizeRef.current - 1 ? activeRef.current + 1 : 0,
          animated: false
        });
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [items]);

  return (
    <View
      sx={{
        width,
        height: isVertical ? [height * 0.12, height * 0.1] : [height * 0.18, height * 0.1],
        marginTop: [4, 8, 6],
        marginBottom: [4, 8, 6]
      }}>
      <FlatList
        ref={listRef}
        data={items}
        horizontal
        scrollEnabled={false}
        showsHorizontalScrollIndicator={false}
        onScroll={event => {
          const pos = event.nativeEvent.contentOffset.x / width;
          activeRef.current = pos;
        }}
        onScrollToIndexFailed={info => {
          __DEV__ && console.log('info', info);
          activeRef.current = 0;
        }}
        renderItem={({ item }) => {
          if (item.Noticia) {
            return (
              <TouchableOpacity
                disabled={Platform.OS === 'web'}
                // @ts-ignore
                onPress={() => Dialog.alert(str.noticia, item.Noticia)}
                style={sx({
                  width,
                  height: ['100%'],
                  backgroundColor: 'white',
                  justifyContent: 'center',
                  alignItems: ['center']
                })}>
                <Text
                  numberOfLines={3}
                  sx={{
                    textAlign: 'center',
                    fontFamily: Platform.select({ android: 'Poppins-Regular' }),
                    color: '$primary1',
                    fontSize: isVertical ? [14, 20] : [10, 12, 20],
                    marginRight: [5, 10, 10],
                    marginLeft: [5, 10, 10]
                  }}>
                  {item.Noticia}
                </Text>
              </TouchableOpacity>
            );
          }

          return (
            <TouchableOpacity
              activeOpacity={0.9}
              style={sx({
                width,
                height: ['100%'],
                backgroundColor: 'white',
                justifyContent: 'center'
              })}
              onPress={async () => {
                if (!item.UrlLink) return;
                await Linking.openURL(item.UrlLink);
              }}>
              <Image
                source={{ uri: item.UrlImagem }}
                sx={{
                  width: '100%',
                  height: '100%'
                }}
                resizeMode='contain'
              />
            </TouchableOpacity>
          );
        }}
        style={sx({ width, height: '100%' })}
      />
    </View>
  );
};

export default BannerNoticias;

const ITEM_PLACEHOLDER = {
  id: '',
  Noticia: 'Opção Costa Verde',
  Nome: '',
  DataAlteracao: 0,
  Ativo: true
};
