import React from 'react';
import { Image, Platform } from 'react-native';
import { useSx, Text, View } from 'dripsy';
import Logo from '../../assets/images/img-logo.png';

const LogoTitle: React.FC = () => {
  const sx = useSx();

  return (
    <View sx={{ alignItems: ['center'], alignSelf: ['center'] }}>
      <Image
        source={Logo as any}
        style={sx({
          height: [60, 60, 80],
          width: [60, 60, 80],
          alignSelf: ['center'],
          marginTop: [15]
        })}
      />
      <Text
        sx={{
          color: '$primary5',
          alignSelf: 'center',
          marginTop: 10,
          marginBottom: 10,
          fontSize: 20,
          fontFamily: Platform.select({ android: 'Poppins-Regular' })
        }}>
        Opção Costa Verde
      </Text>
    </View>
  );
};

export default LogoTitle;
