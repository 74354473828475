import React, { useEffect, useContext } from 'react';
import { View } from 'dripsy';
import { useAtom } from 'jotai';
import { FeProps } from '../../navigation/types';
import { LanguageContext } from '../../context/LanguageContext';
import { localAtom } from '../../atoms';
import { CIDADE } from '../../values/constants';
import ServiceAlone from '../../components/ServiceAlone';

const Fe: React.FC<FeProps> = ({ navigation }) => {
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    navigation.setOptions({ headerTitle: `${CIDADE[local]} / ${str.fe}` });
  }, [navigation, local, str]);

  return (
    <View sx={{ flex: 1 }}>
      <ServiceAlone repo='Lugares' tipo='religiao' />
    </View>
  );
};

export default Fe;
