import React from 'react';
import { Platform } from 'react-native';
import { View, Text } from 'dripsy';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Colors } from '../../theme';

const Feedback: React.FC<{ type: 'success' | 'warning' | 'alert'; message: string }> = ({
  type,
  message
}) => {
  const color =
    type === 'success'
      ? Colors.success
      : type === 'warning'
      ? Colors.warning
      : type === 'alert'
      ? Colors.info
      : Colors.primary1;

  return (
    <View
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50,
        paddingRight: 30,
        paddingLeft: 30
      }}>
      <Icon name='emoticon-confused' color={color} size={48} />
      <Text
        sx={{
          fontSize: 16,
          textAlign: 'center',
          marginTop: 20,
          color,
          fontFamily: Platform.select({ android: 'Poppins-Regular' })
        }}>
        {message}
      </Text>
    </View>
  );
};

export default Feedback;
