import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import AppContainer from './lib/AppContainer';

const firebaseConfig = {
  apiKey: 'AIzaSyBrovRXAOJ0pYzcsRRzLqG973NeZg7fm2w',
  authDomain: 'ilhagrandeapp.firebaseapp.com',
  databaseURL: 'https://ilhagrandeapp.firebaseio.com',
  projectId: 'ilhagrandeapp',
  storageBucket: 'ilhagrandeapp.appspot.com',
  messagingSenderId: '120896006613',
  appId: '1:120896006613:web:ff18b12d5850f97f7d355c',
  measurementId: 'G-J88VZFJRE0'
};

const App: React.FC = () => {
  const [app, setApp] = useState<any>();

  useEffect(() => {
    setApp(initializeApp(firebaseConfig));
  }, []);

  if (!app) {
    return null;
  }

  return <AppContainer />;
};

export default App;
