import React, { useContext, useEffect } from 'react';
import { View } from 'dripsy';
import { useAtom } from 'jotai';
import ServiceList from '../../components/ServiceList';
import { RestaurantesProps } from '../../navigation/types';
import { localAtom } from '../../atoms';
import { LanguageContext } from '../../context/LanguageContext';
import { CIDADE } from '../../values/constants';

const Restaurante: React.FC<RestaurantesProps> = ({ navigation }) => {
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: `${CIDADE[local]} / ${str.bareserestaurantes}`
    });
  }, [navigation, local, str]);

  return (
    <View sx={{ flex: 1 }}>
      <ServiceList repo='Restaurante' />
    </View>
  );
};

export default Restaurante;
