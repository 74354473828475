import React, { useEffect } from 'react';
import { View } from 'dripsy';
import { useAtom } from 'jotai';
import ServiceList from '../../components/ServiceList';
import { localAtom, servicoAtom } from '../../atoms';
import { Repo } from '../../model/Repo';
import { Local } from '../../model/Local';
import { CIDADE } from '../../values/constants';

const Cidade: React.FC<{
  local?: Local;
  filterSections?: Array<{ title: string; key: string; value: any }>;
  tipos?: Array<string>;
}> = ({ local, filterSections, tipos }) => {
  const [servico] = useAtom(servicoAtom);

  const sections =
    servico === 'Top'
      ? [
          { title: '$$', key: 'Preco', value: '$$' },
          { title: '$$$', key: 'Preco', value: '$$$' },
          { title: '$$$$$', key: 'Preco', value: '$$$$$' }
        ]
      : servico === 'Lancha'
      ? filterSections
      : undefined;
  const repo: Repo = servico === 'Top' ? 'Hospedagem' : (servico as Repo);

  return (
    <View sx={{ flex: 1 }}>
      <ServiceList repo={repo} cidade={local} sections={sections} tipos={tipos} />
    </View>
  );
};

const IlhaGrande: React.FC = () => {
  return <Cidade local='ilha-grande' />;
};

const Paraty: React.FC = () => {
  return <Cidade local='paraty' />;
};

const AngraDosReis: React.FC = () => {
  return <Cidade local='angra-dos-reis' />;
};

const Mangaratiba: React.FC = () => {
  return <Cidade local='mangaratiba' />;
};

const RioDeJaneiro: React.FC = () => {
  return <Cidade local='rio-de-janeiro' />;
};

const SaoPaulo: React.FC = () => {
  return <Cidade local='sao-paulo' />;
};

const LanchaAluguel: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);

  useEffect(() => {
    // @ts-ignore
    setLocal(undefined);
  }, [setLocal]);

  return (
    <Cidade
      filterSections={[
        { title: CIDADE['angra-dos-reis'], key: 'Local', value: 'angra-dos-reis' as Local },
        { title: CIDADE.paraty, key: 'Local', value: 'paraty' as Local }
      ]}
      tipos={['aluguel']}
    />
  );
};

const LanchaLojas: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);

  useEffect(() => {
    // @ts-ignore
    setLocal(undefined);
  }, [setLocal]);

  return (
    <Cidade
      filterSections={[
        { title: CIDADE['angra-dos-reis'], key: 'Local', value: 'angra-dos-reis' as Local },
        { title: CIDADE.paraty, key: 'Local', value: 'paraty' as Local }
      ]}
      tipos={['lojas']}
    />
  );
};

const LanchaVenda: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);

  useEffect(() => {
    // @ts-ignore
    setLocal(undefined);
  }, [setLocal]);

  return <Cidade tipos={['venda']} />;
};

const LanchaEstaleiro: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);

  useEffect(() => {
    // @ts-ignore
    setLocal(undefined);
  }, [setLocal]);

  return <Cidade tipos={['estaleiros']} />;
};

const LanchaMarina: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);

  useEffect(() => {
    // @ts-ignore
    setLocal(undefined);
  }, [setLocal]);

  return <Cidade tipos={['marinas']} />;
};

export default {
  IlhaGrande,
  Paraty,
  AngraDosReis,
  Mangaratiba,
  RioDeJaneiro,
  SaoPaulo,
  //
  LanchaAluguel,
  LanchaLojas,
  LanchaVenda,
  LanchaEstaleiro,
  LanchaMarina
};
