import { makeTheme } from 'dripsy';
import { DefaultTheme } from 'react-native-paper';
import { DefaultTheme as NavigationTheme } from '@react-navigation/native';

export const Colors = {
  primary1: '#3490ff',
  primary2: '#51d3ff',
  primary3: '#01001e',
  primary4: '#f5711e',
  primary5: '#39b54a',
  screenBg: NavigationTheme.colors.background,
  success: '#28a745',
  danger: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8',
  text: '#424242',
  placeholder: '#a4a7a3',
  placeholderLight: '#E0E0E0',
  whatsapp: '#4dc247',
  // Common
  white: '#ffffff',
  whiteAlpha3: 'rgba(255,255,255,.3)',
  whiteAlpha2: 'rgba(255,255,255,.2)',
  red: '#FF0000',
  black: '#000000'
};

export const dripsyTheme = makeTheme({
  colors: {
    ...parseColors()
  },
  space: {
    $0: 0,
    $1: 4,
    $2: 8,
    $3: 16,
    $4: 32,
    $5: 64,
    $6: 128,
    $7: 256
  },
  fontSizes: {
    $0: 12,
    $1: 14,
    $2: 16,
    $3: 18,
    $4: 24,
    $5: 28,
    $6: 32
  },
  shadows: {
    s2: {
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.2,
      shadowRadius: 1.41,
      elevation: 2
    },
    s5: {
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5
    },
    s7: {
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 0.29,
      shadowRadius: 4.65,
      elevation: 7
    }
  }
});

export const paperTheme: typeof DefaultTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    accent: Colors.primary4,
    primary: Colors.primary1,
    error: Colors.danger,
    text: Colors.text
  }
};

function parseColors() {
  let parsedColors: any = {};
  for (let key in Colors) {
    // @ts-ignore
    parsedColors[`$${key}`] = Colors[key];
  }
  return parsedColors;
}
