import React, { useContext, useEffect } from 'react';
import { View } from 'dripsy';
import { useAtom } from 'jotai';
import ServiceList from '../../components/ServiceList';
import { localAtom } from '../../atoms';
import { LanguageContext } from '../../context/LanguageContext';
import { CIDADE } from '../../values/constants';
import { AmbientalProps } from '../../navigation/types';

const Ambiental: React.FC<AmbientalProps> = ({ navigation }) => {
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    navigation.setOptions({ headerTitle: `${CIDADE[local]} / ${str.ambiental}` });
  }, [navigation, local, str]);

  return (
    <View sx={{ flex: 1 }}>
      <ServiceList repo='Lugares' tipos={['ambiental', 'ecomuseu', 'geografia']} />
    </View>
  );
};

export default Ambiental;
