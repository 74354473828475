import AsyncStorage from '@react-native-async-storage/async-storage';

export default class StorageService {
  static async set(key: string, val: any): Promise<void> {
    try {
      await AsyncStorage.setItem(key, typeof val === 'string' ? val : JSON.stringify(val));
    } catch (e) {
      __DEV__ && console.log('[ERROR:Storage.write]', e);
    }
  }

  static async get(key: string): Promise<string | null> {
    try {
      const value = await AsyncStorage.getItem(key);
      return value;
    } catch (e) {
      __DEV__ && console.log('[ERROR:Storage.read]', e);
      return null;
    }
  }

  static async remove(key: string): Promise<void> {
    try {
      await AsyncStorage.removeItem(key);
    } catch (e) {
      __DEV__ && console.log('[ERROR:Storage.remove]', e);
    }
  }

  static async clear(): Promise<void> {
    try {
      await AsyncStorage.clear();
    } catch (e) {
      __DEV__ && console.log('[ERROR:Storage.clear]', e);
    }
  }
}
