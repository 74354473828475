import { atom } from 'jotai';
import { Local } from '../model/Local';
import { Servico } from '../model/Servico';

export const localAtom = atom<Local>('ilha-grande');
export const servicoAtom = atom<Servico>('Experiencia');
export const isLoadingAtom = atom(true);

export const userAtom = atom<any | null>(null);
export const selectedItemAtom = atom<any>(null);
export const showModalAtom = atom(true);

export const likesAtom = atom<Array<string>>([]);
export const comercianteAtom = atom<any | null>(null);
