import axios from 'axios';

type RequestConfig = {
  route: string;
  data?: any;
  isFormData?: boolean;
  token?: string;
};

type ResponseResult<T> = /* {
  success: boolean;
  message?: string;
  data?: T;
} */ T;

const TIMEOUT = 1000 * 60;
//const API_URL = __DEV__ ? 'http://localhost:8000' : 'https://opcaocostaverde.com.br';
export const BASE_URL = 'https://cadastro-login.azurewebsites.net'; // `${API_URL}${__DEV__ ? '' : '/server'}`;

const api = axios.create({
  timeout: TIMEOUT,
  baseURL: BASE_URL,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
});

// On request
api.interceptors.request.use(async config => {
  return { ...config };
});

// On response
api.interceptors.response.use(
  res => {
    /* // Obter o refreshToken
    if (res?.config?.url && ['/login'].includes(res.config.url)) {
      const cookies = res.headers; //['Set-Cookie']?.split(';').map(c => c.trim());
      //const refreshCookie = cookies?.find(c => c.startsWith('refreshToken'));
      //const refreshToken = refreshCookie?.slice(13);
      console.log('cookies', cookies);
      return { ...res , data: { ...res.data, refreshToken } };
    } */
    __DEV__ && console.log('[RESPONSE]', JSON.stringify(res.data));
    return res;
  },
  async err => {
    __DEV__ && console.log('[ERROR:Axios]', JSON.stringify(err));

    // Status code
    //const code = err?.status || err?.response?.data?.statusCode || null;

    const errData = err?.response?.data || null;
    if (errData) return Promise.reject(errData?.message || JSON.stringify(errData));
    else return Promise.reject(err);
  }
);

export default class ApiService {
  static async request<T>(
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT',
    config: RequestConfig
  ): Promise<ResponseResult<T>> {
    try {
      const { route, data, isFormData, token } = config;

      __DEV__ && console.log('[FETCHING]', `${BASE_URL}${route}`);

      let headers: any = {
        'Content-Type': isFormData ? `multipart/form-data` : 'application/json'
      };

      if (token) {
        headers = {
          ...headers,
          Authorization: `Bearer ${token}`
        };
      }

      const response = await api.request<ResponseResult<T>>({
        url: route, //`${route}.php`,
        method,
        headers,
        data /* ,
        withCredentials: true */
      });

      return response.data;
    } catch (err) {
      throw err;
    }
  }

  static async get<T>(config: RequestConfig) {
    return await ApiService.request<T>('GET', config);
  }

  static async post<T>(config: RequestConfig) {
    return await ApiService.request<T>('POST', config);
  }

  static async patch<T>(config: RequestConfig) {
    return await ApiService.request<T>('PATCH', config);
  }

  static async delete<T>(config: RequestConfig) {
    return await ApiService.request<T>('DELETE', config);
  }

  static async put<T>(config: RequestConfig) {
    return await ApiService.request<T>('PUT', config);
  }
}
