import React, { useContext, useEffect } from 'react';
import { View } from 'dripsy';
import { useAtom } from 'jotai';
import ServiceList from '../../components/ServiceList';
import { LanguageContext } from '../../context/LanguageContext';
import { localAtom } from '../../atoms';
import { CIDADE } from '../../values/constants';
import { AcessosProps } from '../../navigation/types';

const Acessos: React.FC<AcessosProps> = ({ navigation }) => {
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    navigation.setOptions({ headerTitle: `${CIDADE[local]} / ${str.acessos}` });
  }, [navigation, local, str]);

  return (
    <View sx={{ flex: 1 }}>
      <ServiceList repo='Acesso' />
    </View>
  );
};

export default Acessos;
