export class Dialog {
  static alert(title: string, message: string) {
    __DEV__ && console.log('title', title);
    window.alert(message);
  }

  static confirm(message: string, onResult: (value: boolean) => void) {
    const value = window.confirm(message);
    onResult(value);
  }
}
