import React, { useEffect, useContext } from 'react';
import { Platform } from 'react-native';
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context';
import { useSx } from 'dripsy';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useAtom } from 'jotai';
import Home from '../pages/Home';
import Hospedagem from '../pages/Hospedagem';
import Restaurantes from '../pages/Restaurantes';
import DrawerMenu from '../components/DrawerMenu';
import { Colors } from '../theme';
import { comercianteAtom, isLoadingAtom, localAtom, servicoAtom, userAtom } from '../atoms';
import Loading from '../components/Loading';
import Praias from '../pages/Praias';
import Cachoeiras from '../pages/Cachoeiras';
import Monumentos from '../pages/Monumentos';
import Historia from '../pages/Historia';
import Camping from '../pages/Camping';
import Trilhas from '../pages/Trilhas';
import Fe from '../pages/Fe';
import Acessos from '../pages/Acessos';
import Ambiental from '../pages/Ambiental';
import Estacionamento from '../pages/Estacionamento';
import TelefonesUteis from '../pages/TelefonesUteis';
import {
  ComercianteParamList,
  LocalHomeParamList,
  RootParamList,
  ServicoHomeParamList
} from './types';
import { LanguageContext } from '../context/LanguageContext';
import DetalhesServico from '../pages/DetalhesServico';
import Cidade from '../pages/Cidade';
import { CIDADE, STORAGEKEY_COMERCIANTE } from '../values/constants';
import CadastroLogin from '../pages/CadastroLogin';
// @ts-ignore
import { Auth } from '../shims/firebase';
// @ts-ignore
import ImageZoom from '../pages/ImageZoom';
import HeaderOptions from '../components/HeaderOptions';
import SaibaMais from '../pages/SaibaMais';
// @ts-ignore
import { hideSplash } from '../shims/splash';
import ACSignin from '../pages/AreaComerciante/ACSignin';
import StorageService from '../services/StorageService';
import ACRegister from '../pages/AreaComerciante/ACRegister';
import ACHome from '../pages/AreaComerciante/ACHome';
import ACRecovery from '../pages/AreaComerciante/ACRecovery';

const Stack = createNativeStackNavigator<RootParamList>();
const DrawerLocal = createDrawerNavigator<LocalHomeParamList>();
const DrawerServico = createDrawerNavigator<ServicoHomeParamList>();
const ACStack = createNativeStackNavigator<ComercianteParamList>();

const Navigator: React.FC = () => {
  const sx = useSx();
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [, setUser] = useAtom(userAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    let unsubscribe: () => void | undefined;
    Auth.onAuthChange(
      (usr: any) => {
        setUser(usr);
        setIsLoading(false);
      },
      (unsub: any) => {
        unsubscribe = unsub;
      }
    );
    return () => unsubscribe?.();
  }, [setUser, setIsLoading]);

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <NavigationContainer
        onReady={() => hideSplash()}
        linking={{
          prefixes: ['opcaocostaverde.com.br', 'localhost'],
          config: {
            initialRouteName: 'Home',
            screens: {
              Home: '*',
              IlhaHome: {
                screens: {
                  Hospedagem: 'ilha-grande/hospedagem',
                  Restaurantes: 'ilha-grande/restaurantes',
                  Praias: 'ilha-grande/praias',
                  Cachoeiras: 'ilha-grande/cachoeiras',
                  Monumentos: 'ilha-grande/monumentos',
                  Historia: 'ilha-grande/historia',
                  Camping: 'ilha-grande/camping',
                  Trilhas: 'ilha-grande/trilhas',
                  Fe: 'ilha-grande/fe',
                  Acessos: 'ilha-grande/acessos',
                  Ambiental: 'ilha-grande/ambiental',
                  Estacionamento: 'ilha-grande/estacionamento',
                  TelefonesUteis: 'ilha-grande/telefones-uteis'
                }
              },
              ParatyHome: {
                screens: {
                  Hospedagem: 'paraty/hospedagem',
                  Restaurantes: 'paraty/restaurantes',
                  Praias: 'paraty/praias',
                  Cachoeiras: 'paraty/cachoeiras',
                  Monumentos: 'paraty/monumentos',
                  Historia: 'paraty/historia',
                  Camping: 'paraty/camping',
                  Trilhas: 'paraty/trilhas',
                  Fe: 'paraty/fe',
                  Acessos: 'paraty/acessos',
                  Ambiental: 'paraty/ambiental',
                  Estacionamento: 'paraty/estacionamento',
                  TelefonesUteis: 'paraty/telefones-uteis'
                }
              },
              AngraHome: {
                screens: {
                  Hospedagem: 'angra-dos-reis/hospedagem',
                  Restaurantes: 'angra-dos-reis/restaurantes',
                  Praias: 'angra-dos-reis/praias',
                  Monumentos: 'angra-dos-reis/monumentos',
                  Historia: 'angra-dos-reis/historia',
                  Fe: 'angra-dos-reis/fe',
                  Acessos: 'angra-dos-reis/acessos',
                  Ambiental: 'angra-dos-reis/ambiental',
                  Estacionamento: 'angra-dos-reis/estacionamento',
                  TelefonesUteis: 'angra-dos-reis/telefones-uteis'
                }
              },
              MangaratibaHome: {
                screens: {
                  Hospedagem: 'mangaratiba/hospedagem',
                  Restaurantes: 'mangaratiba/restaurantes',
                  Praias: 'mangaratiba/praias',
                  Cachoeiras: 'mangaratiba/cachoeiras',
                  Monumentos: 'mangaratiba/monumentos',
                  Historia: 'mangaratiba/historia',
                  Fe: 'mangaratiba/fe',
                  Acessos: 'mangaratiba/acessos',
                  Estacionamento: 'mangaratiba/estacionamento',
                  TelefonesUteis: 'mangaratiba/telefones-uteis'
                }
              },
              ConceicaoHome: {
                screens: {
                  Estacionamento: 'conceicao-de-jacarei/estacionamento'
                }
              },
              DetalhesServico: 'servico/:local/:tipo/:id',
              CadastroLogin: 'cadastro',
              ExperienciaHome: {
                screens: {
                  IlhaGrande: 'experiencia/ilha-grande',
                  Paraty: 'experiencia/paraty',
                  AngraDosReis: 'experiencia/angra-dos-reis',
                  Mangaratiba: 'experiencia/mangaratiba',
                  SaoPaulo: 'experiencia/sao-paulo',
                  RioDeJaneiro: 'experiencia/rio-de-janeiro'
                }
              },
              MergulhoHome: {
                screens: {
                  IlhaGrande: 'mergulho/ilha-grande',
                  Paraty: 'mergulho/paraty',
                  AngraDosReis: 'mergulho/angra-dos-reis',
                  SaoPaulo: 'mergulho/sao-paulo',
                  RioDeJaneiro: 'mergulho/rio-de-janeiro'
                }
              },
              PasseioHome: {
                screens: {
                  IlhaGrande: 'passeio/ilha-grande',
                  Paraty: 'passeio/paraty',
                  AngraDosReis: 'passeio/angra-dos-reis',
                  Mangaratiba: 'passeio/mangaratiba'
                }
              },
              TopHome: {
                screens: {
                  IlhaGrande: 'top/ilha-grande',
                  Paraty: 'top/paraty',
                  AngraDosReis: 'top/angra-dos-reis',
                  Mangaratiba: 'top/mangaratiba'
                }
              },
              TransferHome: {
                screens: {
                  IlhaGrande: 'transfer/ilha-grande',
                  Paraty: 'transfer/paraty',
                  AngraDosReis: 'transfer/angra-dos-reis',
                  Mangaratiba: 'transfer/mangaratiba',
                  SaoPaulo: 'transfer/sao-paulo',
                  RioDeJaneiro: 'transfer/rio-de-janeiro'
                }
              },
              LanchaHome: {
                screens: {
                  Aluguel: 'lancha/aluguel',
                  Lojas: 'lancha/lojas',
                  Venda: 'lancha/venda'
                }
              },
              SaibaMais: 'saiba-mais',
              TermosUso: 'termos-de-uso',
              PoliticaPrivacidade: 'politica-de-privacidade',
              AreaComerciante: {
                path: 'area-do-comerciante',
                screens: {
                  ACSignin: 'entrar',
                  ACRegister: 'cadastro',
                  ACHome: 'home',
                  ACRecovery: 'recuperar-senha'
                }
              }
            }
          } as any
        }}
        documentTitle={{
          formatter: (options, route) =>
            `${options?.title || route?.name ? options?.title || route?.name : ''}`
        }}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            contentStyle: { width: '100%', height: '60%' },
            headerTintColor: Colors.primary3,
            headerTitleStyle: sx({
              fontSize: [16, 18, 20],
              fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
            })
          }}>
          <Stack.Screen name='Home' component={Home} options={{ title: 'Opção Costa Verde' }} />
          <Stack.Screen name='IlhaHome' component={IlhaHome} />
          <Stack.Screen name='AngraHome' component={AngraHome} />
          <Stack.Screen name='ParatyHome' component={ParatyHome} />
          <Stack.Screen name='MangaratibaHome' component={MangaratibaHome} />
          <Stack.Screen name='ConceicaoHome' component={ConceicaoHome} />
          <Stack.Screen
            name='DetalhesServico'
            component={DetalhesServico}
            options={{ headerShown: true, title: 'Detalhes Serviço' }}
          />
          <Stack.Screen
            name='CadastroLogin'
            component={CadastroLogin}
            options={{ title: 'Entre/Cadastre-se' }}
          />
          <Stack.Screen name='ExperienciaHome' component={ExperienciaHome} />
          <Stack.Screen name='MergulhoHome' component={MergulhoHome} />
          <Stack.Screen name='PasseioHome' component={PasseioHome} />
          <Stack.Screen name='TopHome' component={TopHome} />
          <Stack.Screen name='TransferHome' component={TransferHome} />
          <Stack.Screen name='LanchaHome' component={LanchaHome} />
          <Stack.Screen
            name='ImageZoom'
            component={ImageZoom}
            options={{ headerShown: true, title: 'Zoom' }}
          />
          <Stack.Screen
            name='SaibaMais'
            component={SaibaMais.Informacao}
            options={{ headerShown: true, title: str.saibaMais }}
          />
          <Stack.Screen
            name='TermosUso'
            component={SaibaMais.TermosUso}
            options={{ headerShown: true, title: str.termosuso }}
          />
          <Stack.Screen
            name='PoliticaPrivacidade'
            component={SaibaMais.PoliticaPrivacidade}
            options={{ headerShown: true, title: str.politicaprivacidade }}
          />
          <Stack.Screen
            name='AreaComerciante'
            component={ComercianteHome}
            options={{ headerShown: true, title: 'Área do Comerciante' }}
          />
        </Stack.Navigator>
      </NavigationContainer>

      {isLoading && <Loading />}
    </SafeAreaProvider>
  );
};

export default Navigator;

/**
 * NAVEGAÇÃO POR LOCAL/CIDADE
 */

const LocalNavigator: React.FC = ({ children }) => {
  const sx = useSx();

  return (
    <DrawerLocal.Navigator
      initialRouteName='Hospedagem'
      drawerContent={props => <DrawerMenu {...props} />}
      screenOptions={{
        drawerType: 'front',
        drawerLabelStyle: sx({
          color: '$primary3',
          fontWeight: Platform.select({ web: ['600'] }),
          fontFamily: Platform.select({ android: 'Poppins-Bold', web: 'Poppins' })
        }),
        drawerActiveBackgroundColor: Colors.primary2,
        headerTintColor: Colors.primary3,
        headerTitleStyle: sx({
          fontSize: [16, 18, 20],
          fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
        }),
        headerRight: () => <HeaderOptions />
      }}>
      {children}
    </DrawerLocal.Navigator>
  );
};

const IlhaHome: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setLocal('ilha-grande');
  }, [setLocal]);

  return (
    <LocalNavigator>
      <DrawerLocal.Screen
        name='Hospedagem'
        component={Hospedagem}
        options={{ title: str.hospedagem }}
      />
      <DrawerLocal.Screen
        name='Restaurantes'
        component={Restaurantes}
        options={{ title: str.bareserestaurantes }}
      />
      <DrawerLocal.Screen name='Praias' component={Praias} options={{ title: str.praias }} />
      <DrawerLocal.Screen
        name='Cachoeiras'
        component={Cachoeiras}
        options={{ title: str.cachoeiras }}
      />
      <DrawerLocal.Screen
        name='Monumentos'
        component={Monumentos}
        options={{ title: str.monumentos }}
      />
      <DrawerLocal.Screen name='Historia' component={Historia} options={{ title: str.historia }} />
      <DrawerLocal.Screen name='Camping' component={Camping} options={{ title: str.camping }} />
      <DrawerLocal.Screen name='Trilhas' component={Trilhas} options={{ title: str.trilhas }} />
      <DrawerLocal.Screen name='Fe' component={Fe} options={{ title: str.fe }} />
      <DrawerLocal.Screen name='Acessos' component={Acessos} options={{ title: str.acessos }} />
      <DrawerLocal.Screen
        name='Ambiental'
        component={Ambiental}
        options={{ title: str.ambiental }}
      />
      <DrawerLocal.Screen
        name='Estacionamento'
        component={Estacionamento}
        options={{ title: str.estacionamento }}
      />
      <DrawerLocal.Screen
        name='TelefonesUteis'
        component={TelefonesUteis}
        options={{ title: str.telefonesuteis }}
      />
    </LocalNavigator>
  );
};

const AngraHome: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setLocal('angra-dos-reis');
  }, [setLocal]);

  return (
    <LocalNavigator>
      <DrawerLocal.Screen
        name='Hospedagem'
        component={Hospedagem}
        options={{ title: str.hospedagem }}
      />
      <DrawerLocal.Screen
        name='Restaurantes'
        component={Restaurantes}
        options={{ title: str.bareserestaurantes }}
      />
      <DrawerLocal.Screen name='Praias' component={Praias} options={{ title: str.praias }} />
      <DrawerLocal.Screen
        name='Monumentos'
        component={Monumentos}
        options={{ title: str.monumentos }}
      />
      <DrawerLocal.Screen name='Historia' component={Historia} options={{ title: str.historia }} />
      <DrawerLocal.Screen name='Fe' component={Fe} options={{ title: str.fe }} />
      <DrawerLocal.Screen name='Acessos' component={Acessos} options={{ title: str.acessos }} />
      <DrawerLocal.Screen
        name='Ambiental'
        component={Ambiental}
        options={{ title: str.ambiental }}
      />
      <DrawerLocal.Screen
        name='Estacionamento'
        component={Estacionamento}
        options={{ title: str.estacionamento }}
      />
      <DrawerLocal.Screen
        name='TelefonesUteis'
        component={TelefonesUteis}
        options={{ title: str.telefonesuteis }}
      />
    </LocalNavigator>
  );
};

const ParatyHome: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setLocal('paraty');
  }, [setLocal]);

  return (
    <LocalNavigator>
      <DrawerLocal.Screen
        name='Hospedagem'
        component={Hospedagem}
        options={{ title: str.hospedagem }}
      />
      <DrawerLocal.Screen
        name='Restaurantes'
        component={Restaurantes}
        options={{ title: str.bareserestaurantes }}
      />
      <DrawerLocal.Screen name='Praias' component={Praias} options={{ title: str.praias }} />
      <DrawerLocal.Screen
        name='Cachoeiras'
        component={Cachoeiras}
        options={{ title: str.cachoeiras }}
      />
      <DrawerLocal.Screen
        name='Monumentos'
        component={Monumentos}
        options={{ title: str.monumentos }}
      />
      <DrawerLocal.Screen name='Historia' component={Historia} options={{ title: str.historia }} />
      <DrawerLocal.Screen name='Camping' component={Camping} options={{ title: str.camping }} />
      <DrawerLocal.Screen name='Trilhas' component={Trilhas} options={{ title: str.trilhas }} />
      <DrawerLocal.Screen name='Fe' component={Fe} options={{ title: str.fe }} />
      <DrawerLocal.Screen name='Acessos' component={Acessos} options={{ title: str.acessos }} />
      <DrawerLocal.Screen
        name='Ambiental'
        component={Ambiental}
        options={{ title: str.ambiental }}
      />
      <DrawerLocal.Screen
        name='Estacionamento'
        component={Estacionamento}
        options={{ title: str.estacionamento }}
      />
      <DrawerLocal.Screen
        name='TelefonesUteis'
        component={TelefonesUteis}
        options={{ title: str.telefonesuteis }}
      />
    </LocalNavigator>
  );
};

const MangaratibaHome: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setLocal('mangaratiba');
  }, [setLocal]);

  return (
    <LocalNavigator>
      <DrawerLocal.Screen
        name='Hospedagem'
        component={Hospedagem}
        options={{ title: str.hospedagem }}
      />
      <DrawerLocal.Screen
        name='Restaurantes'
        component={Restaurantes}
        options={{ title: str.bareserestaurantes }}
      />
      <DrawerLocal.Screen name='Praias' component={Praias} options={{ title: str.praias }} />
      <DrawerLocal.Screen
        name='Cachoeiras'
        component={Cachoeiras}
        options={{ title: str.cachoeiras }}
      />
      <DrawerLocal.Screen
        name='Monumentos'
        component={Monumentos}
        options={{ title: str.monumentos }}
      />
      <DrawerLocal.Screen name='Historia' component={Historia} options={{ title: str.historia }} />
      <DrawerLocal.Screen name='Fe' component={Fe} options={{ title: str.fe }} />
      <DrawerLocal.Screen name='Acessos' component={Acessos} options={{ title: str.acessos }} />
      <DrawerLocal.Screen
        name='Estacionamento'
        component={Estacionamento}
        options={{ title: str.estacionamento }}
      />
      <DrawerLocal.Screen
        name='TelefonesUteis'
        component={TelefonesUteis}
        options={{ title: str.telefonesuteis }}
      />
    </LocalNavigator>
  );
};

const ConceicaoHome: React.FC = () => {
  const [, setLocal] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setLocal('conceicao-de-jacarei');
  }, [setLocal]);

  return (
    <LocalNavigator>
      <DrawerLocal.Screen
        name='Estacionamento'
        component={Estacionamento}
        options={{ title: str.estacionamento }}
      />
    </LocalNavigator>
  );
};

/**
 * NAVEGAÇÃO POR SERVIÇO
 */

const ServicoNavigator: React.FC = ({ children }) => {
  const sx = useSx();

  return (
    <DrawerServico.Navigator
      initialRouteName='IlhaGrande'
      drawerContent={props => <DrawerMenu {...props} />}
      screenOptions={{
        drawerType: 'front',
        drawerLabelStyle: sx({
          color: '$primary3',
          fontWeight: Platform.select({ web: ['600'] }),
          fontFamily: Platform.select({ android: 'Poppins-Bold', web: 'Poppins' })
        }),
        drawerActiveBackgroundColor: Colors.primary2,
        headerTintColor: Colors.primary3,
        headerTitleStyle: sx({
          fontSize: [16, 18, 20],
          fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
        }),
        headerRight: () => <HeaderOptions />
      }}>
      {children}
    </DrawerServico.Navigator>
  );
};

const ExperienciaHome: React.FC = () => {
  const [, setServico] = useAtom(servicoAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setServico('Experiencia');
  }, [setServico]);

  return (
    <ServicoNavigator>
      <DrawerServico.Screen
        name='IlhaGrande'
        component={Cidade.IlhaGrande}
        options={{
          title: CIDADE['ilha-grande'],
          headerTitle: `${str.experiencias} / ${CIDADE['ilha-grande']}`
        }}
      />
      <DrawerServico.Screen
        name='Paraty'
        component={Cidade.Paraty}
        options={{ title: CIDADE.paraty, headerTitle: `${str.experiencias} / ${CIDADE.paraty}` }}
      />
      <DrawerServico.Screen
        name='AngraDosReis'
        component={Cidade.AngraDosReis}
        options={{
          title: CIDADE['angra-dos-reis'],
          headerTitle: `${str.experiencias} / ${CIDADE['angra-dos-reis']}`
        }}
      />
      <DrawerServico.Screen
        name='Mangaratiba'
        component={Cidade.Mangaratiba}
        options={{
          title: CIDADE.mangaratiba,
          headerTitle: `${str.experiencias} / ${CIDADE.mangaratiba}`
        }}
      />
      <DrawerServico.Screen
        name='SaoPaulo'
        component={Cidade.SaoPaulo}
        options={{
          title: `${CIDADE['sao-paulo']} ↔ Costa Verde`,
          headerTitle: `${str.experiencias} / ${CIDADE['sao-paulo']} ↔ Costa Verde`
        }}
      />
      <DrawerServico.Screen
        name='RioDeJaneiro'
        component={Cidade.RioDeJaneiro}
        options={{
          title: `${CIDADE['rio-de-janeiro']} ↔ Costa Verde`,
          headerTitle: `${str.experiencias} / ${CIDADE['rio-de-janeiro']} ↔ Costa Verde`
        }}
      />
    </ServicoNavigator>
  );
};

const MergulhoHome: React.FC = () => {
  const [, setServico] = useAtom(servicoAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setServico('Mergulho');
  }, [setServico]);

  return (
    <ServicoNavigator>
      <DrawerServico.Screen
        name='IlhaGrande'
        component={Cidade.IlhaGrande}
        options={{
          title: CIDADE['ilha-grande'],
          headerTitle: `${str.mergulho} / ${CIDADE['ilha-grande']}`
        }}
      />
      <DrawerServico.Screen
        name='Paraty'
        component={Cidade.Paraty}
        options={{ title: CIDADE.paraty, headerTitle: `${str.mergulho} / ${CIDADE.paraty}` }}
      />
      <DrawerServico.Screen
        name='AngraDosReis'
        component={Cidade.AngraDosReis}
        options={{
          title: CIDADE['angra-dos-reis'],
          headerTitle: `${str.mergulho} / ${CIDADE['angra-dos-reis']}`
        }}
      />
      <DrawerServico.Screen
        name='SaoPaulo'
        component={Cidade.SaoPaulo}
        options={{
          title: `${CIDADE['sao-paulo']} ↔ Costa Verde`,
          headerTitle: `${str.mergulho} / ${CIDADE['sao-paulo']} ↔ Costa Verde`
        }}
      />
      <DrawerServico.Screen
        name='RioDeJaneiro'
        component={Cidade.RioDeJaneiro}
        options={{
          title: `${CIDADE['rio-de-janeiro']} ↔ Costa Verde`,
          headerTitle: `${str.mergulho} / ${CIDADE['rio-de-janeiro']} ↔ Costa Verde`
        }}
      />
    </ServicoNavigator>
  );
};

const PasseioHome: React.FC = () => {
  const [, setServico] = useAtom(servicoAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setServico('Passeio');
  }, [setServico]);

  return (
    <ServicoNavigator>
      <DrawerServico.Screen
        name='IlhaGrande'
        component={Cidade.IlhaGrande}
        options={{
          title: CIDADE['ilha-grande'],
          headerTitle: `${str.passeiosbarco} / ${CIDADE['ilha-grande']}`
        }}
      />
      <DrawerServico.Screen
        name='Paraty'
        component={Cidade.Paraty}
        options={{ title: CIDADE.paraty, headerTitle: `${str.passeiosbarco} / ${CIDADE.paraty}` }}
      />
      <DrawerServico.Screen
        name='AngraDosReis'
        component={Cidade.AngraDosReis}
        options={{
          title: CIDADE['angra-dos-reis'],
          headerTitle: `${str.passeiosbarco} / ${CIDADE['angra-dos-reis']}`
        }}
      />
      <DrawerServico.Screen
        name='Mangaratiba'
        component={Cidade.Mangaratiba}
        options={{
          title: CIDADE.mangaratiba,
          headerTitle: `${str.passeiosbarco} / ${CIDADE.mangaratiba}`
        }}
      />
    </ServicoNavigator>
  );
};

const TopHome: React.FC = () => {
  const [, setServico] = useAtom(servicoAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setServico('Top');
  }, [setServico]);

  return (
    <ServicoNavigator>
      <DrawerServico.Screen
        name='IlhaGrande'
        component={Cidade.IlhaGrande}
        options={{
          title: CIDADE['ilha-grande'],
          headerTitle: `${str.top} / ${CIDADE['ilha-grande']}`
        }}
      />
      <DrawerServico.Screen
        name='Paraty'
        component={Cidade.Paraty}
        options={{ title: CIDADE.paraty, headerTitle: `${str.top} / ${CIDADE.paraty}` }}
      />
      <DrawerServico.Screen
        name='AngraDosReis'
        component={Cidade.AngraDosReis}
        options={{
          title: CIDADE['angra-dos-reis'],
          headerTitle: `${str.top} / ${CIDADE['angra-dos-reis']}`
        }}
      />
      <DrawerServico.Screen
        name='Mangaratiba'
        component={Cidade.Mangaratiba}
        options={{
          title: CIDADE.mangaratiba,
          headerTitle: `${str.top} / ${CIDADE.mangaratiba}`
        }}
      />
    </ServicoNavigator>
  );
};

const TransferHome: React.FC = () => {
  const [, setServico] = useAtom(servicoAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setServico('Transfer');
  }, [setServico]);

  return (
    <ServicoNavigator>
      <DrawerServico.Screen
        name='IlhaGrande'
        component={Cidade.IlhaGrande}
        options={{
          title: CIDADE['ilha-grande'],
          headerTitle: `${str.transfer} / ${CIDADE['ilha-grande']}`
        }}
      />
      <DrawerServico.Screen
        name='Paraty'
        component={Cidade.Paraty}
        options={{ title: CIDADE.paraty, headerTitle: `${str.transfer} / ${CIDADE.paraty}` }}
      />
      <DrawerServico.Screen
        name='AngraDosReis'
        component={Cidade.AngraDosReis}
        options={{
          title: CIDADE['angra-dos-reis'],
          headerTitle: `${str.transfer} / ${CIDADE['angra-dos-reis']}`
        }}
      />
      <DrawerServico.Screen
        name='Mangaratiba'
        component={Cidade.Mangaratiba}
        options={{
          title: CIDADE.mangaratiba,
          headerTitle: `${str.transfer} / ${CIDADE.mangaratiba}`
        }}
      />
      <DrawerServico.Screen
        name='SaoPaulo'
        component={Cidade.SaoPaulo}
        options={{
          title: `${CIDADE['sao-paulo']} ↔ Costa Verde`,
          headerTitle: `${str.transfer} / ${CIDADE['sao-paulo']} ↔ Costa Verde`
        }}
      />
      <DrawerServico.Screen
        name='RioDeJaneiro'
        component={Cidade.RioDeJaneiro}
        options={{
          title: `${CIDADE['rio-de-janeiro']} ↔ Costa Verde`,
          headerTitle: `${str.transfer} / ${CIDADE['rio-de-janeiro']} ↔ Costa Verde`
        }}
      />
    </ServicoNavigator>
  );
};

const LanchaHome: React.FC = () => {
  const [, setServico] = useAtom(servicoAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setServico('Lancha');
  }, [setServico]);

  return (
    <ServicoNavigator>
      <DrawerServico.Screen
        name='Aluguel'
        component={Cidade.LanchaAluguel}
        options={{
          title: str.aluguel,
          headerTitle: `${str.lanchas} / ${str.aluguel}`
        }}
      />
      <DrawerServico.Screen
        name='Lojas'
        component={Cidade.LanchaLojas}
        options={{ title: str.lojas, headerTitle: `${str.lanchas} / ${str.lojas}` }}
      />
      <DrawerServico.Screen
        name='Venda'
        component={Cidade.LanchaVenda}
        options={{
          title: str.venda,
          headerTitle: `${str.lanchas} / ${str.venda}`
        }}
      />
    </ServicoNavigator>
  );
};

/**
 * NAVEGAÇÃO DO COMERCIANTE
 */

const ComercianteHome: React.FC = () => {
  // const [{ strings: str }] = useContext(LanguageContext);
  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [comerciante, setComerciante] = useAtom(comercianteAtom);

  useEffect(() => {
    setIsLoading(true);

    StorageService.get(STORAGEKEY_COMERCIANTE)
      .then(value => {
        if (value) setComerciante(JSON.parse(value));
      })
      .finally(() => setIsLoading(false));
  }, [setIsLoading, setComerciante]);

  return (
    <ACStack.Navigator
      initialRouteName={comerciante ? 'ACHome' : 'ACSignin'}
      screenOptions={{ headerShown: false }}>
      <ACStack.Screen
        name='ACSignin'
        component={ACSignin}
        options={{ title: 'Login Comerciante' }}
      />
      <ACStack.Screen
        name='ACRegister'
        component={ACRegister}
        options={{ title: 'Cadastro Comerciante' }}
      />
      <ACStack.Screen
        name='ACRecovery'
        component={ACRecovery}
        options={{ title: 'Recuperar Acesso' }}
      />
      <ACStack.Screen name='ACHome' component={ACHome} options={{ title: 'Home Comerciante' }} />
    </ACStack.Navigator>
  );
};
