import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Platform, ScrollView, TouchableOpacity } from 'react-native';
import { View, Text, useSx, Image, TextInput } from 'dripsy';
import { Button, Snackbar, RadioButton, Divider, Modal, Portal, List } from 'react-native-paper';
import { useAtom } from 'jotai';
import { useLinkTo } from '@react-navigation/native';
import { maskText } from 'js-util/functions/mask-text';
import { phoneIsValid } from 'js-util/functions/validate';
import Icon from 'react-native-vector-icons/Ionicons';
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import { comercianteAtom, isLoadingAtom } from '../../../atoms';
import { LanguageContext } from '../../../context/LanguageContext';
import Logo from '../../../assets/images/img-logo.png';
import { Colors } from '../../../theme';
import { Local } from '../../../model/Local';
import { CIDADE, STORAGEKEY_COMERCIANTE, VALOR_PLANO } from '../../../values/constants';
import { ACRepository } from '../../../repositories/ACRepository';
import { SegmentoComercio } from '../../../model/CadComercio';
import StorageService from '../../../services/StorageService';
import { ACRegisterProps } from '../../../navigation/types';
import { Auth, RTDatabase } from '../../../shims/firebase/index.web';

const ACRegister: React.FC<ACRegisterProps> = ({ navigation }) => {
  const sx = useSx();
  const linkTo = useLinkTo();
  const [{ strings: str }] = useContext(LanguageContext);

  const [local, setLocal] = useState<Local>('ilha-grande');
  const [segmento, setSegmento] = useState('');
  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [telefone, setTelefone] = useState('');
  const [endereco, setEndereco] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [error, setError] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [plano, setPlano] = useState('completo');

  const [, setIsLoading] = useAtom(isLoadingAtom);
  const [comerciante, setComerciante] = useAtom(comercianteAtom);

  const onSubmit = useCallback(async () => {
    try {
      if (!nome || !email || !cnpj || !telefone || !endereco || !senha || !local || !segmento) {
        setError('Preencha todos os campos para continuar!');
        return;
      }

      if (!cnpjValidator.isValid(cnpj)) {
        setError('Informe um CNPJ válido!');
        return;
      }

      if (!phoneIsValid(telefone)) {
        setError('Informe um telefone válido!');
        return;
      }

      if (!email.includes('@')) {
        setError('Informe um email válido!');
        return;
      }

      setIsLoading(true);

      await ACRepository.createRegister({
        local,
        segmento: segmento as SegmentoComercio,
        nome: nome.trim(),
        cnpj: cnpj.replace(/\D/g, ''),
        telefone: telefone.replace(/\D/g, ''),
        endereco: endereco.trim(),
        email: email.trim().toLowerCase(),
        senha,
        plano
      });

      const uid = await Auth.createWithEmail(
        email.trim().toLowerCase(),
        email.trim().toLowerCase()
      );

      const res = await ACRepository.signin(email.trim(), senha);

      await RTDatabase.set(`/${res.repo}/${uid}`, {
        Local: local,
        Nome: nome.trim(),
        CNPJ: cnpj.replace(/\D/g, ''),
        Endereco: endereco.trim(),
        Email: email.trim().toLowerCase(),
        Plano: plano,
        Telefones: {
          0: {
            Numero: telefone.replace(/\D/g, ''),
            Tipo: 'fixo',
            WhatsApp: false
          }
        },
        Parceiro: false,
        Ativo: false,
        Aguardando: true,
        DataAlteracao: Date.now()
      });

      if (res.repo === 'Hospedagem') {
        window.location.replace('https://opcaocostaverde.com.br/central-reservas');
        return;
      }

      await StorageService.set(STORAGEKEY_COMERCIANTE, res);

      setIsLoading(false);
      setComerciante(res);
    } catch (err) {
      __DEV__ && console.log('[ERROR:CadastroUsuario.onSubmit]', err);
      setIsLoading(false);
      setError(str.errocadastro);
    }
  }, [
    str,
    local,
    segmento,
    nome,
    email,
    setIsLoading,
    endereco,
    cnpj,
    telefone,
    senha,
    setComerciante,
    plano
  ]);

  useEffect(() => {
    if (comerciante) {
      navigation.replace('ACHome');
    }
  }, [comerciante, navigation]);

  return (
    <>
      <View sx={{ flex: 1 }}>
        <ScrollView>
          <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
            <View sx={{ margin: [15], alignSelf: ['center'] }}>
              <Image
                source={Logo as any}
                sx={{
                  height: [60, 60, 80],
                  width: [60, 60, 80],
                  alignSelf: ['center'],
                  marginTop: [15]
                }}
              />
              <Text
                sx={{
                  color: '$primary5',
                  alignSelf: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                  fontSize: [16, 16, 20],
                  fontFamily: Platform.select({ android: 'Poppins-Medium' }),
                  textAlign: 'center'
                }}>
                Opção Costa Verde | Comerciante
              </Text>
            </View>

            <FormLabel text='Escolha seu Plano' />

            <View sx={{ flexDirection: ['column', 'row', 'row'], margin: 15 }}>
              <TouchableOpacity
                onPress={() => setPlano('gratis')}
                style={sx({
                  flex: 1,
                  borderWidth: plano === 'gratis' ? 3 : 1,
                  borderColor: plano === 'gratis' ? '$primary5' : '$placeholderLight',
                  borderRadius: 5,
                  padding: [15]
                })}>
                <Text
                  sx={{
                    fontWeight: ['bold'],
                    fontSize: 20,
                    color: '$primary3',
                    textAlign: 'center'
                  }}>
                  Teste Grátis
                </Text>

                <Text
                  sx={{
                    fontWeight: ['bold'],
                    fontSize: 18,
                    color: '$primary3',
                    textAlign: 'center'
                  }}>
                  R$ 0,00
                  <Text sx={{ color: '$text', fontSize: 14, fontWeight: ['normal'] }}>/mês</Text>
                </Text>

                <Text sx={{ fontSize: 16, color: '$text', textAlign: ['center'] }}>
                  Todos os recursos inclusos por 7 dias
                </Text>
              </TouchableOpacity>

              <View sx={{ width: 15, height: 15 }} />

              <TouchableOpacity
                onPress={() => setPlano('completo')}
                style={sx({
                  flex: 1,
                  borderWidth: plano === 'completo' ? 3 : 1,
                  borderColor: plano === 'completo' ? '$primary5' : '$placeholderLight',
                  borderRadius: 5,
                  padding: [15]
                })}>
                <Text
                  sx={{
                    fontWeight: ['bold'],
                    fontSize: 20,
                    color: '$primary3',
                    textAlign: 'center'
                  }}>
                  Completo
                </Text>

                <Text
                  sx={{
                    fontWeight: ['bold'],
                    fontSize: 18,
                    color: '$primary3',
                    textAlign: 'center'
                  }}>
                  {VALOR_PLANO}
                  <Text sx={{ color: '$text', fontSize: 14, fontWeight: ['normal'] }}>/mês</Text>
                </Text>

                <Text sx={{ fontSize: 16, color: '$text', textAlign: ['center'] }}>
                  Todos os recursos inclusos
                </Text>

                <Text sx={{ fontSize: 16, color: '$text', textAlign: ['center'] }}>
                  Apareça em Destaque
                </Text>
              </TouchableOpacity>
            </View>

            <FormLabel text='Local' />
            <RadioButton.Group onValueChange={value => setLocal(value as Local)} value={local}>
              <View
                sx={{
                  flexDirection: ['column', 'row'],
                  justifyContent: ['center'],
                  marginRight: 25,
                  marginLeft: 25,
                  marginBottom: 10
                }}>
                <View sx={{ flexDirection: 'row', alignItems: ['center'] }}>
                  <RadioButton value='ilha-grande' color={Colors.primary1} />
                  <RadioLabel text={CIDADE['ilha-grande']} />
                </View>
                <View sx={{ flexDirection: 'row', alignItems: ['center'] }}>
                  <RadioButton value='paraty' color={Colors.primary1} />
                  <RadioLabel text={CIDADE.paraty} />
                </View>
                <View sx={{ flexDirection: 'row', alignItems: ['center'] }}>
                  <RadioButton value='angra-dos-reis' color={Colors.primary1} />
                  <RadioLabel text={CIDADE['angra-dos-reis']} />
                </View>
                <View sx={{ flexDirection: 'row', alignItems: ['center'] }}>
                  <RadioButton value='mangaratiba' color={Colors.primary1} />
                  <RadioLabel text={CIDADE.mangaratiba} />
                </View>
              </View>
            </RadioButton.Group>

            <FormLabel text='Segmento' />
            <TouchableOpacity
              onPress={() => setModalVisible(true)}
              style={sx({
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                height: Platform.select({ web: [30], android: [45] }),
                flexDirection: ['row'],
                alignItems: ['center']
              })}>
              <Text
                sx={{
                  flex: 1,
                  color: '$primary3',
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}>
                {segmento}
              </Text>
              <Icon name='caret-down' color={Colors.primary3} size={25} />
            </TouchableOpacity>

            <FormLabel text='Nome da empresa' />
            <TextInput
              value={nome}
              placeholderTextColor={Colors.primary3}
              onChangeText={setNome}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />

            <FormLabel text='CNPJ' />
            <TextInput
              value={cnpj}
              placeholderTextColor={Colors.primary3}
              onChangeText={maskText('cnpj', text => setCnpj(text as string))}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />

            <FormLabel text='Telefone' />
            <TextInput
              value={telefone}
              placeholderTextColor={Colors.primary3}
              onChangeText={maskText('phone', text => setTelefone(text as string))}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />

            <FormLabel text='Endereço' />
            <TextInput
              value={endereco}
              placeholderTextColor={Colors.primary3}
              onChangeText={setEndereco}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />

            <FormLabel text={str.email} />
            <TextInput
              value={email}
              placeholderTextColor={Colors.primary3}
              onChangeText={setEmail}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
              keyboardType='email-address'
              autoCapitalize='none'
            />

            <FormLabel text='Senha' />
            <TextInput
              value={senha}
              placeholderTextColor={Colors.primary3}
              onChangeText={setSenha}
              secureTextEntry
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />

            <Button
              mode='contained'
              color={Colors.primary5}
              onPress={onSubmit}
              labelStyle={sx({
                color: '$white',
                fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
              })}
              style={sx({ marginBottom: 10, marginTop: 5, marginLeft: 15, marginRight: 15 })}>
              Cadastrar
            </Button>

            <Text
              sx={{
                alignSelf: ['center'],
                color: '$primary3',
                fontSize: ['$2'],
                marginBottom: [5],
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}>
              Já tem um cadastro?
            </Text>

            <Button
              mode='text'
              color={Colors.primary1}
              onPress={() => linkTo('/area-do-comerciante/entrar')}
              labelStyle={sx({
                fontWeight: Platform.select({ web: ['bold'] }),
                fontFamily: Platform.select({ android: 'Poppins-Bold', web: 'Poppins' })
              })}
              style={sx({ marginBottom: 10, marginTop: 5, marginLeft: 15, marginRight: 15 })}>
              Entre
            </Button>

            <Text
              sx={{
                color: '$primary3',
                marginLeft: 30,
                marginRight: 30,
                textAlign: 'center',
                fontSize: 14,
                marginBottom: 15,
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}>
              {str.aocriar}
              {'\n'}
              <Text
                sx={{ textDecorationLine: 'underline', fontWeight: ['bold'] }}
                onPress={() => linkTo('/termos-de-uso')}>
                {str.termosuso}
              </Text>{' '}
              e{' '}
              <Text
                sx={{ textDecorationLine: 'underline', fontWeight: ['bold'] }}
                onPress={() => linkTo('/politica-de-privacidade')}>
                {str.politicaprivacidade}
              </Text>
            </Text>
          </View>
        </ScrollView>
      </View>

      <Portal>
        <Modal
          visible={modalVisible}
          onDismiss={() => setModalVisible(false)}
          contentContainerStyle={sx({
            width: ['95%', '95%', '50%'],
            alignSelf: ['center'],
            backgroundColor: '$white',
            margin: ['$4'],
            borderRadius: [10]
            //marginLeft: [10],
            //marginRight: [10]
          })}>
          {[
            'Hospedagem',
            'Bares e Restaurantes',
            'Camping',
            'Estacionamento',
            'Experiências',
            'Transfer',
            'Mergulho',
            'Passeios de Barco',
            'Lanchas'
          ]
            .sort()
            .map((item, index) => (
              <React.Fragment key={`segmento-${index}`}>
                <List.Item
                  title={item}
                  onPress={() => {
                    setSegmento(item);
                    setModalVisible(false);
                  }}
                  titleStyle={sx({
                    fontSize: [16, 16, 18],
                    color: '$primary3'
                  })}
                />
                <Divider />
              </React.Fragment>
            ))}
        </Modal>
      </Portal>

      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ top: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

export default ACRegister;

const RadioLabel: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Text
      sx={{
        color: '$primary3',
        fontWeight: Platform.select({ web: ['600'] }),
        fontFamily: Platform.select({ android: 'Poppins-Regular' }),
        fontSize: [16]
      }}>
      {text}
    </Text>
  );
};

const FormLabel: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Text
      sx={{
        color: '$primary3',
        marginLeft: 30,
        fontSize: '$1',
        fontWeight: Platform.select({ web: ['bold'] }),
        fontFamily: Platform.select({ android: 'Poppins-Bold' })
      }}>
      {text}
    </Text>
  );
};
