import React from 'react';
import { View } from 'dripsy';

type MapLocalProps = {
  url: string;
};

const MapLocal: React.FC<MapLocalProps> = ({ url }) => {
  if (!url) return null;

  return (
    <View sx={{ height: 300, width: '100%', marginTop: [30], marginBottom: 30 }}>
      <iframe
        height='100%'
        width='100%'
        // @ts-ignore
        frameborder='0'
        //style='border: 0'
        src={url}
        allowfullscreen
      />
    </View>
  );
};

export default MapLocal;
