import React, { useEffect, useState, useContext } from 'react';
import { FlatList, Platform, ScrollView } from 'react-native';
import { View, TextInput, useSx, Text } from 'dripsy';
import { useAtom } from 'jotai';
import Icon from 'react-native-vector-icons/FontAwesome';
import { normalizeLower } from 'js-util/functions/string-util';
import { isLoadingAtom, localAtom } from '../../atoms';
import { mountServiceList, orderList } from '../../functions';
import ServiceItem from '../ServiceItem';
import { Colors } from '../../theme';
import { Repo } from '../../model/Repo';
import { LanguageContext } from '../../context/LanguageContext';
import BannerNoticias from '../BannerNoticias';
import { Local } from '../../model/Local';
import Feedback from '../Feedback';
// @ts-ignore
import { RTDatabase } from '../../shims/firebase';

type ServiceListProps = {
  repo: Repo;
  tipos?: Array<string>;
  cidade?: Local;
  sections?: Array<{ title: string; key: string; value: any }>;
  customRender?: (items: Array<any>) => React.ReactNode;
  withLocal?: boolean;
  withBanner?: boolean;
  withSearch?: boolean;
  withFeedback?: boolean;
  tipoKey?: string;
  filters?: Array<{ key: string; value: any }>;
  maxSectionItems?: number;
  sectionsHeaderStyle?: any;
};

const ServiceList: React.FC<ServiceListProps> = ({
  repo,
  tipos,
  cidade,
  sections,
  customRender,
  withLocal = true,
  withBanner = true,
  withSearch = true,
  withFeedback = true,
  tipoKey,
  filters,
  maxSectionItems,
  sectionsHeaderStyle
}) => {
  const sx = useSx();
  const [data, setData] = useState<Array<any>>([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    setIsLoading(true);

    RTDatabase.get(repo)
      .then((result: any) => {
        if (result) {
          let items = Object.entries(result)
            .map(([key, value]) => ({
              ...(value as any),
              id: key,
              repo
            }))
            .filter(i => i.Ativo)
            .filter(i =>
              (tipos || []).length >= 1 ? tipos?.includes(i[tipoKey ? tipoKey : 'Tipo']) : true
            )
            .filter(i =>
              (filters || []).length >= 1 ? filters?.every(f => i[f.key] === f.value) : true
            );
          setData(orderList(items));
        }
      })
      .catch((err: any) => {
        console.log('err', err);
      })
      .finally(() => setIsLoading(false));

    return () => setIsLoading(false);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);
  /* repo, tipos, tipoKey, filters, setIsLoading */

  let localList = withLocal
    ? data.filter(i => (cidade || local ? i.Local === (cidade || local) : true))
    : data;
  localList = search
    ? localList.filter(
        i => normalizeLower(i.Nome).search(new RegExp(normalizeLower(search), 'ig')) !== -1
      )
    : localList;

  const mountedList = sections ? localList : mountServiceList(localList);

  if (customRender) {
    return <>{customRender(mountedList)}</>;
  }

  return (
    <View sx={{ flex: 1 }}>
      {withBanner && <BannerNoticias />}

      <ScrollView>
        {withSearch && (
          <View
            sx={{
              backgroundColor: '$white',
              padding: [10, 15, 8],
              alignItems: ['center'],
              width: ['100%'],
              marginBottom: [4, 8, 6]
            }}>
            <View
              sx={{
                flexDirection: 'row',
                alignItems: ['center'],
                borderRadius: [10],
                borderWidth: [1],
                borderColor: ['$primary3'],
                paddingLeft: [10],
                paddingRight: [10],
                alignSelf: ['center'],
                width: ['100%', '100%', '40%']
              }}>
              <Icon name='search' color={Colors.primary3} size={16} />
              <TextInput
                placeholder={`${str.busca}...`}
                onChangeText={setSearch}
                value={search}
                sx={{
                  flex: 1,
                  height: [45],
                  marginLeft: [8],
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}
              />
            </View>
          </View>
        )}

        {sections
          ? sections.map((section, sectionIndex) => (
              <View key={`section-${section.title}-${sectionIndex}`}>
                <Text
                  sx={{
                    fontSize: 18,
                    color: Colors.primary3,
                    padding: 20,
                    backgroundColor: '$white',
                    marginBottom: 10,
                    fontWeight: Platform.select({ web: ['bold'] }),
                    fontFamily: Platform.select({ android: 'Poppins-Regular' }),
                    ...(sectionsHeaderStyle || {})
                  }}>
                  {section.title}
                </Text>

                {mountServiceList(
                  mountedList
                    .filter((l: any) => l[section.key] === section.value)
                    .filter((_, i) => (maxSectionItems ? i < maxSectionItems : true))
                ).map((list, listIndex) => (
                  <FlatList
                    key={`list-${listIndex}`}
                    horizontal
                    data={list}
                    keyExtractor={item => item.id}
                    renderItem={({ item }) => <ServiceItem item={item} />}
                    style={sx({ marginBottom: 20 })}
                    ListFooterComponent={() => <View style={{ width: 15 }} />}
                  />
                ))}
              </View>
            ))
          : mountedList.map((list, listIndex) => (
              <FlatList
                key={`list-${listIndex}`}
                horizontal
                data={list}
                keyExtractor={item => item.id}
                renderItem={({ item }) => <ServiceItem item={item} />}
                style={sx({ marginBottom: 20 })}
                ListFooterComponent={() => <View style={{ width: 15 }} />}
              />
            ))}

        {withFeedback && !isLoading && mountedList.length === 0 && (
          <Feedback type='warning' message={str.listavazia} />
        )}
      </ScrollView>
    </View>
  );
};

export default ServiceList;
