// @ts-nocheck

/**
 * When compiling for web, it will search for index.js on root/src.
 */
import React from 'react';
import 'react-native-gesture-handler';
import { Text, TextInput } from 'dripsy';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;
Text.defaultProps.style = { ...Text.defaultProps.style, fontFamily: 'Poppins' };
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.allowFontScaling = false;
TextInput.defaultProps.style = { ...TextInput.defaultProps.style, fontFamily: 'Poppins' };

// RNVI integration for web
import './icons';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
