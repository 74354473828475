import React from 'react';
import { Platform } from 'react-native';
import { View, Text } from 'dripsy';
import { Colors } from '../../theme';

const TagTopLeft: React.FC<{ label: string }> = ({ label }) => {
  return (
    <View
      sx={{
        backgroundColor: Colors.primary4,
        borderRadius: 5,
        alignSelf: 'flex-start',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 2,
        paddingBottom: 2,
        position: 'absolute',
        top: 10,
        left: 10
      }}>
      <Text
        sx={{
          color: 'white',
          fontSize: 14,
          textAlign: 'center',
          fontFamily: Platform.select({ android: 'Poppins-Regular' }),
          fontWeight: Platform.select({ web: ['normal', 'normal', 'bold'] })
        }}>
        {label}
      </Text>
    </View>
  );
};

export default TagTopLeft;
