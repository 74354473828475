import React from 'react';
import { ActivityIndicator, Platform } from 'react-native';
import { View, Text } from 'dripsy';
import { Colors } from '../../theme';

const Loading: React.FC = () => {
  return (
    <View
      sx={{
        height: '100%',
        width: '100%',
        position: ['absolute'],
        top: [0],
        bottom: [0],
        left: [0],
        right: [0],
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: ['center'],
        alignItems: ['center']
      }}>
      <ActivityIndicator size='large' color={Colors.white} />
      <Text
        sx={{
          color: '$white',
          fontSize: [16, 18, 20],
          fontFamily: Platform.select({ android: 'Poppins-Regular' })
        }}>
        Carregando...
      </Text>
    </View>
  );
};

export default Loading;
