import React from 'react';
import { useWindowDimensions } from 'react-native';
import { DripsyProvider } from 'dripsy';
import { Provider as PaperProvider } from 'react-native-paper';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Provider as AtomsProvider } from 'jotai';
import Navigator from './navigation';
import { dripsyTheme, paperTheme } from './theme';
import { LanguageProvider } from './context/LanguageContext';

const AppContainer: React.FC = () => {
  const { width, height } = useWindowDimensions();

  return (
    <DripsyProvider theme={dripsyTheme}>
      <PaperProvider theme={paperTheme}>
        <GestureHandlerRootView style={{ width, height }}>
          <AtomsProvider>
            <LanguageProvider>
              <Navigator />
            </LanguageProvider>
          </AtomsProvider>
        </GestureHandlerRootView>
      </PaperProvider>
    </DripsyProvider>
  );
};

export default AppContainer;
