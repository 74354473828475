import { getApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import { child, get, getDatabase, ref, set, onValue } from 'firebase/database';

export class Auth {
  static SAVE_USER_DATA_KEY = 'SAVE_USER_DATA_KEY';

  static onAuthChange(setAuth: (user: User | null) => void, setUnsubs: (unsub: any) => void) {
    const auth = getAuth(getApp());
    const unsub = onAuthStateChanged(auth, usr => setAuth(usr));
    setUnsubs(unsub);
  }

  static isSignedin() {
    const auth = getAuth(getApp());
    return Boolean(auth.currentUser);
  }

  static async createWithEmail(email: string, password: string) {
    const auth = getAuth(getApp());

    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      return user.uid;
    } catch (err) {
      __DEV__ && console.log('err', err);
    }
  }

  static async signinWithEmail(name: string, email: string, inscrever: boolean) {
    email = email.trim().toLowerCase();
    const password = email;

    const auth = getAuth(getApp());
    const database = getDatabase(getApp());

    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);

      await set(ref(database, `/Users/${user.uid}`), {
        email,
        name,
        receivePromotion: inscrever,
        createdAt: Date.now()
      });
    } catch (err: any) {
      if (err.code === 'auth/email-already-in-use') {
        try {
          const usr = await signInWithEmailAndPassword(auth, email, password);
          console.log('usr', usr);
        } catch (error) {
          throw error;
        }
      } else {
        //Crashlytics.record(err);
        throw err;
      }
    }
  }

  static async signinWithGoogle(inscrever: boolean) {
    try {
      const auth = getAuth(getApp());
      const database = getDatabase(getApp());

      const result = await signInWithPopup(auth, new GoogleAuthProvider());

      const user = result.user;

      await set(ref(database, `/Users/${user.uid}`), {
        email: user.email,
        name: user.displayName,
        receivePromotion: inscrever,
        createdAt: Date.now()
      });

      __DEV__ && console.log('user', user);
    } catch (err) {
      __DEV__ && console.log('err', err);
      throw err;
    }
  }

  static async signinWithFacebook(inscrever: boolean) {
    try {
      const auth = getAuth(getApp());
      const database = getDatabase(getApp());

      const result = await signInWithPopup(auth, new FacebookAuthProvider());

      const user = result.user;

      await set(ref(database, `/Users/${user.uid}`), {
        email: user.email,
        name: user.displayName,
        receivePromotion: inscrever,
        createdAt: Date.now()
      });

      __DEV__ && console.log('user', user);
    } catch (err) {
      __DEV__ && console.log('err', err);
      throw err;
    }
  }

  static async signOut() {
    try {
      const auth = getAuth(getApp());
      await signOut(auth);
      //await AsyncStorage.removeItem(this.SAVE_USER_DATA_KEY);
    } catch (err) {
      //Crashlytics.record(err);
    }
  }
}

export class RTDatabase {
  static async set(path: string, value: any) {
    try {
      const database = getDatabase(getApp());
      await set(ref(database, path), value);
    } catch (err) {
      __DEV__ && console.log('[ERROR:RTDatabase.set]', err);
      throw err;
    }
  }

  static async get(path: string) {
    try {
      const database = getDatabase(getApp());

      const databaseTimeout = (promise: Promise<any>): Promise<any> => {
        return new Promise((resolve, reject) => {
          const tmo = setTimeout(() => {
            reject(new Error('Timeout'));
          }, 1000 * 60);
          promise.then(data => {
            clearTimeout(tmo);
            resolve(data);
          }, reject);
        });
      };

      const snapshot = await databaseTimeout(get(child(ref(database), path)));
      return snapshot.val() || null;
    } catch (err) {
      __DEV__ && console.log('err', err);
      throw err;
    }
  }

  static onValue(path: string, callback: (value: any) => void) {
    const database = getDatabase(getApp());

    const unsubscribe = onValue(ref(database, path), snapshot => {
      if (snapshot?.val()) {
        callback(snapshot?.val());
      }
    });

    return unsubscribe;
  }
}
