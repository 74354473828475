import React, { useEffect, useContext } from 'react';
import { Platform, TouchableOpacity } from 'react-native';
import { useSx, View, Text } from 'dripsy';
import { useAtom } from 'jotai';
import Icon from 'react-native-vector-icons/Feather';
import ServiceList from '../../components/ServiceList';
import { EstacionamentoProps } from '../../navigation/types';
import { LanguageContext } from '../../context/LanguageContext';
import { localAtom } from '../../atoms';
import { CIDADE } from '../../values/constants';
import { Colors } from '../../theme';

const Estacionamento: React.FC<EstacionamentoProps> = ({ navigation }) => {
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: `${CIDADE[local]} / ${str.estacionamento}`
    });
  }, [navigation, local, str]);

  return (
    <View sx={{ flex: 1 }}>
      {local === 'ilha-grande' ? (
        <>
          <Text
            sx={{
              color: '$text',
              textAlign: 'center',
              marginTop: 20,
              marginRight: 15,
              marginLeft: 15,
              fontSize: [16],
              fontFamily: Platform.select({ android: 'Poppins-Regular' })
            }}>
            {str.infoestacionamento}
          </Text>

          <View
            sx={{
              justifyContent: 'center',
              margin: 15
            }}>
            <LinkToEstacionamento
              onPress={() => navigation.navigate('MangaratibaHome', { screen: 'Estacionamento' })}
              cidade={CIDADE.mangaratiba}
            />

            <LinkToEstacionamento
              onPress={() => navigation.navigate('ConceicaoHome', { screen: 'Estacionamento' })}
              cidade={CIDADE['conceicao-de-jacarei']}
            />

            <LinkToEstacionamento
              onPress={() => navigation.navigate('AngraHome', { screen: 'Estacionamento' })}
              cidade={CIDADE['angra-dos-reis']}
            />
          </View>
        </>
      ) : (
        <ServiceList repo='Estacionamento' />
      )}
    </View>
  );
};

export default Estacionamento;

const LinkToEstacionamento: React.FC<{ cidade: string; onPress: () => void }> = ({
  cidade,
  onPress
}) => {
  const sx = useSx();
  const [{ strings: str }] = useContext(LanguageContext);

  return (
    <TouchableOpacity
      onPress={onPress}
      style={sx({
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      })}>
      <Text
        sx={{
          color: '$primary1',
          textAlign: 'center',
          fontSize: 16,
          fontFamily: Platform.select({ android: 'Poppins-Regular' })
        }}>
        {str.estacionamento} {str.em} {cidade}
      </Text>
      <Icon name='arrow-up-right' color={Colors.primary1} size={20} />
    </TouchableOpacity>
  );
};
