import React, { useState, useContext, useCallback } from 'react';
import { Platform } from 'react-native';
import { View, Text, TextInput, Image, ScrollView, useSx } from 'dripsy';
import { Checkbox, Button, IconButton, Snackbar } from 'react-native-paper';
import { useAtom } from 'jotai';
import { useLinkTo } from '@react-navigation/native';
// @ts-ignore
import LinearGradient from '../../shims/gradient';
import { LanguageContext } from '../../context/LanguageContext';
import { Colors } from '../../theme';
import Logo from '../../assets/images/img-logo.png';
import GooglePng from '../../assets/images/google.png';
import FacebookPng from '../../assets/images/facebook.png';
import { CadastroLoginProps } from '../../navigation/types';
import { isLoadingAtom } from '../../atoms';
// @ts-ignore
import { Auth } from '../../shims/firebase';

const CadastroLogin: React.FC<CadastroLoginProps> = ({ navigation }) => {
  const sx = useSx();
  const linkTo = useLinkTo();
  const [{ strings: str }] = useContext(LanguageContext);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [promoChecked, setPromoChecked] = useState(false);
  const [error, setError] = useState('');
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const onSubmit = useCallback(() => {
    if (nome && email) {
      setIsLoading(true);
      Auth.signinWithEmail(nome, email, promoChecked)
        .then(() => {
          __DEV__ && console.log('[CadastroUsuario.onSubmit]', 'OK');
          setIsLoading(false);
          navigation.goBack();
        })
        .catch((err: any) => {
          __DEV__ && console.log('[ERROR:CadastroUsuario.onSubmit]', err);
          setIsLoading(false);
          setError(str.errocadastro);
        });
      return;
    }

    setError(str.nomeemailvazio);
  }, [str, nome, email, promoChecked, setIsLoading, navigation]);

  const onGoogleSignin = useCallback(() => {
    setIsLoading(true);
    Auth.signinWithGoogle(promoChecked)
      .then(() => {
        __DEV__ && console.log('[CadastroUsuario.onGoogleSignin]', 'OK');
        setIsLoading(false);
        navigation.goBack();
      })
      .catch((err: any) => {
        __DEV__ && console.log('[ERROR:CadastroUsuario.onGoogleSignin]', err);
        setIsLoading(false);
        setError(str.errocadastro);
      });
  }, [promoChecked, setIsLoading, str.errocadastro, navigation]);

  const onFacebookSignin = useCallback(() => {
    setIsLoading(true);
    Auth.signinWithFacebook(promoChecked)
      .then(() => {
        __DEV__ && console.log('[CadastroUsuario.onFacebookSignin]', 'OK');
        setIsLoading(false);
        navigation.goBack();
      })
      .catch((err: any) => {
        __DEV__ && console.log('[ERROR:CadastroUsuario.onFacebookSignin]', err);
        setIsLoading(false);
        setError(str.errocadastro);
      });
  }, [promoChecked, setIsLoading, str.errocadastro, navigation]);

  return (
    <>
      <View sx={{ flex: 1 }}>
        <LinearGradient
          colors={[Colors.primary1, Colors.primary2, Colors.primary2]}
          style={sx({ flex: 1 })}>
          <ScrollView>
            <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
              <View sx={{ margin: [15], alignSelf: ['center'] }}>
                <Image
                  source={Logo as any}
                  sx={{
                    height: [60, 60, 80],
                    width: [60, 60, 80],
                    alignSelf: ['center'],
                    marginTop: [15]
                  }}
                />
                <Text
                  sx={{
                    color: '$white',
                    alignSelf: 'center',
                    marginTop: 10,
                    marginBottom: 10,
                    fontSize: 20,
                    fontFamily: Platform.select({ android: 'Poppins-Medium' })
                  }}>
                  Opção Costa Verde
                </Text>
              </View>

              <Text
                sx={{
                  color: '$primary3',
                  marginLeft: 30,
                  fontSize: '$1',
                  fontWeight: Platform.select({ web: ['bold'] }),
                  fontFamily: Platform.select({ android: 'Poppins-Bold' })
                }}>
                {str.nome}
              </Text>
              <TextInput
                value={nome}
                placeholderTextColor={Colors.primary3}
                onChangeText={setNome}
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: '$primary3',
                  marginRight: 25,
                  marginLeft: 25,
                  marginBottom: 10,
                  color: '$primary3',
                  height: Platform.select({ web: [30], android: [45] }),
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}
              />

              <Text
                sx={{
                  color: '$primary3',
                  marginLeft: 30,
                  fontSize: '$1',
                  fontWeight: Platform.select({ web: ['bold'] }),
                  fontFamily: Platform.select({ android: 'Poppins-Bold' })
                }}>
                {str.email}
              </Text>
              <TextInput
                value={email}
                placeholderTextColor={Colors.primary3}
                onChangeText={setEmail}
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: '$primary3',
                  marginRight: 25,
                  marginLeft: 25,
                  marginBottom: 10,
                  color: '$primary3',
                  height: Platform.select({ web: [30], android: [45] }),
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}
                keyboardType='email-address'
                autoCapitalize='none'
              />

              <View
                sx={{
                  flexDirection: ['row'],
                  alignItems: ['center'],
                  alignSelf: ['center'],
                  marginBottom: [10]
                }}>
                <Checkbox
                  color={Colors.primary3}
                  status={promoChecked ? 'checked' : 'unchecked'}
                  onPress={() => setPromoChecked(!promoChecked)}
                />
                <Text
                  sx={{
                    color: '$text',
                    fontFamily: Platform.select({ android: 'Poppins-Regular' })
                  }}>
                  {str.inscricaopromocoes}
                </Text>
              </View>

              <Button
                mode='contained'
                color={Colors.primary5}
                onPress={onSubmit}
                labelStyle={sx({
                  color: '$white',
                  fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
                })}
                style={sx({ marginBottom: 10, marginTop: 5, marginLeft: 15, marginRight: 15 })}>
                {str.entrar}
              </Button>

              <Text
                sx={{
                  alignSelf: ['center'],
                  color: '$primary3',
                  fontSize: ['$2'],
                  marginBottom: [5],
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}>
                OU
              </Text>

              <View sx={{ flexDirection: ['column', 'row', 'row'] }}>
                <Button
                  mode='contained'
                  color={Colors.primary5}
                  icon={() => (
                    <Image
                      source={GooglePng as any}
                      sx={{ height: 20, width: 20 }}
                      resizeMode='contain'
                    />
                  )}
                  onPress={onGoogleSignin}
                  labelStyle={sx({
                    color: '$primary3',
                    textTransform: ['none'],
                    fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
                  })}
                  style={sx({
                    flex: 1,
                    marginBottom: 10,
                    marginTop: 5,
                    marginLeft: 15,
                    marginRight: 15,
                    backgroundColor: ['$white']
                  })}>
                  {str.entrarcom} Google
                </Button>

                <Button
                  mode='contained'
                  color={Colors.primary5}
                  icon={() => (
                    <Image
                      source={FacebookPng as any}
                      sx={{ height: 20, width: 20 }}
                      resizeMode='contain'
                    />
                  )}
                  onPress={onFacebookSignin}
                  labelStyle={sx({
                    color: '$primary3',
                    textTransform: ['none'],
                    fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
                  })}
                  style={sx({
                    flex: 1,
                    marginBottom: 10,
                    marginTop: 5,
                    marginLeft: 15,
                    marginRight: 15,
                    backgroundColor: ['$white']
                  })}>
                  {str.entrarcom} Facebook
                </Button>
              </View>

              <Text
                sx={{
                  color: '$primary3',
                  marginLeft: 30,
                  marginRight: 30,
                  textAlign: 'center',
                  fontSize: 14,
                  marginBottom: 15,
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}>
                {str.aocriar}
                {'\n'}
                <Text
                  sx={{ textDecorationLine: 'underline', fontWeight: ['bold'] }}
                  onPress={() => linkTo('/termos-de-uso')}>
                  {str.termosuso}
                </Text>{' '}
                e{' '}
                <Text
                  sx={{ textDecorationLine: 'underline', fontWeight: ['bold'] }}
                  onPress={() => linkTo('/politica-de-privacidade')}>
                  {str.politicaprivacidade}
                </Text>
              </Text>
            </View>
          </ScrollView>

          <IconButton
            icon='arrow-left'
            color={Colors.white}
            onPress={() => navigation.goBack()}
            style={sx({ position: ['absolute'], left: [15], top: [15] })}
          />
        </LinearGradient>
      </View>

      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ top: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

export default CadastroLogin;
