import React from 'react';
import { Platform } from 'react-native';
import { useSx } from 'dripsy';
import { Button } from 'react-native-paper';

const TextButton: React.FC<{
  text: string;
  onPress: () => void;
  icon?: string;
  color: string;
}> = ({ text, onPress, icon, color }) => {
  const sx = useSx();

  return (
    <Button
      mode='text'
      icon={icon}
      onPress={onPress}
      labelStyle={sx({
        color: color || '$white',
        fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
      })}
      style={sx({ marginBottom: 10, marginTop: 5, marginLeft: 15, marginRight: 15 })}>
      {text}
    </Button>
  );
};

export default TextButton;
