import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import {
  useWindowDimensions,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform,
  Linking
} from 'react-native';
import { View, Text, useSx } from 'dripsy';
import { useLinkTo } from '@react-navigation/native';
import { Portal, Modal, Button } from 'react-native-paper';
import { useAtom } from 'jotai';
// @ts-ignore
import LinearGradient from '../../shims/gradient';
import { Colors } from '../../theme';
import ImgAngra from '../../assets/images/img-angra.jpg';
import ImgExperiencias from '../../assets/images/img-experiencia.jpg';
import ImgIlhaGrande from '../../assets/images/img-ilha-grande.jpg';
import ImgLanchas from '../../assets/images/img-lancha.jpg';
import ImgMangaratiba from '../../assets/images/img-mangaratiba.jpg';
import ImgParaty from '../../assets/images/img-paraty.jpg';
import ImgPasseio from '../../assets/images/img-passeio-barco.jpg';
import ImgTransfer from '../../assets/images/img-transfer.jpg';
import ImgTop from '../../assets/images/img-top.png';
import ImgMergulho from '../../assets/images/img-mergulho.jpg';
import { SafeAreaView } from 'react-native-safe-area-context';
import { HomeProps, Navigation } from '../../navigation/types';
import { LanguageContext } from '../../context/LanguageContext';
import BannerNoticias from '../../components/BannerNoticias';
import imgFlagEn from '../../assets/images/flag-en.png';
import imgFlagEs from '../../assets/images/flag-es.png';
import imgFlagPt from '../../assets/images/flag-pt.png';
import ImgHeader from '../../assets/images/img-header.png';
import Logo from '../../assets/images/img-logo.png';
import { isLoadingAtom, showModalAtom, userAtom } from '../../atoms';
import { SITE_LINK } from '../../values/constants';
//import ServiceList from '../../components/ServiceList';

const Home: React.FC<HomeProps> = ({ navigation }) => {
  const sx = useSx();
  const { width } = useWindowDimensions();
  const linkTo = useLinkTo<Navigation>();
  const [{ strings: str }] = useContext(LanguageContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading] = useAtom(isLoadingAtom);
  const [user] = useAtom(userAtom);
  const [showModal, setShowModal] = useAtom(showModalAtom);

  useEffect(() => {
    if (!isLoading && !user && showModal) {
      setTimeout(() => setModalVisible(true), 2000);
    }
    return () => setShowModal(false);
  }, [isLoading, user, showModal, setShowModal]);

  return (
    <SafeAreaView style={sx({ width, height: '100%' })}>
      <View sx={{ width, height: '100%' }}>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          colors={[Colors.primary2, Colors.primary1]}
          style={sx({ width, height: '100%' })}>
          <HomeHeader />

          <BannerNoticias />

          <ScrollView>
            {/* <ServiceList
              repo='Hospedagem'
              withLocal={false}
              withBanner={false}
              withSearch={false}
              withFeedback={false}
              sections={[
                { title: 'Destaques ILHA GRANDE', key: 'Local', value: 'ilha-grande' },
                { title: 'Destaques PARATY', key: 'Local', value: 'paraty' },
                { title: 'Destaques ANGRA DOS REIS', key: 'Local', value: 'angra-dos-reis' },
                { title: 'Destaques MANGARATIBA', key: 'Local', value: 'mangaratiba' }
              ]}
              filters={[{ key: 'Parceiro', value: true }]}
              maxSectionItems={6}
              sectionsHeaderStyle={{ backgroundColor: 'transparent' }}
            /> */}

            <View
              sx={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                paddingTop: [15, 15, 15]
              }}>
              <MenuButton
                title='Ilha Grande'
                img={ImgIlhaGrande}
                onPress={() => linkTo('/ilha-grande/hospedagem')}
              />
              <MenuButton
                title='Paraty'
                img={ImgParaty}
                onPress={() => linkTo('/paraty/hospedagem')}
              />
              <MenuButton
                title='Angra dos Reis'
                img={ImgAngra}
                onPress={() => linkTo('/angra-dos-reis/hospedagem')}
              />
              <MenuButton
                title='Mangaratiba'
                img={ImgMangaratiba}
                onPress={() => linkTo('/mangaratiba/hospedagem')}
              />
              <MenuButton
                title={str.experiencias}
                img={ImgExperiencias}
                onPress={() => linkTo('/experiencia/ilha-grande')}
              />
              <MenuButton
                title={str.mergulho}
                img={ImgMergulho}
                onPress={() => linkTo('/mergulho/ilha-grande')}
              />
              <MenuButton
                title={str.passeiosdebarco}
                img={ImgPasseio}
                onPress={() => linkTo('/passeio/ilha-grande')}
              />
              <MenuButton title='Top' img={ImgTop} onPress={() => linkTo('/top/ilha-grande')} />
              <MenuButton
                title={str.transfer}
                img={ImgTransfer}
                onPress={() => linkTo('/transfer/ilha-grande')}
              />
              <MenuButton
                title={str.lanchas}
                img={ImgLanchas}
                onPress={() => linkTo('/lancha/aluguel')}
              />
            </View>
          </ScrollView>
        </LinearGradient>
      </View>

      <Portal>
        <Modal
          visible={modalVisible}
          onDismiss={() => {
            setShowModal(false);
            setModalVisible(false);
          }}
          contentContainerStyle={sx({ width: ['100%', '100%', '50%'], alignSelf: ['center'] })}>
          <View>
            <View
              sx={{
                backgroundColor: ['$white'],
                marginLeft: [15],
                marginRight: [15],
                borderRadius: [10],
                padding: [15]
              }}>
              <Image
                source={Logo as any}
                style={sx({
                  height: [60, 60, 80],
                  width: [60, 60, 80],
                  alignSelf: ['center'],
                  marginTop: [15]
                })}
              />
              <Text
                sx={{
                  color: '$primary5',
                  alignSelf: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                  fontSize: 20,
                  fontFamily: Platform.select({ android: 'Poppins-Medium' })
                }}>
                Opção Costa Verde
              </Text>

              <Text
                sx={{
                  color: Colors.text,
                  alignSelf: ['center'],
                  textAlign: ['center'],
                  fontSize: ['$2'],
                  fontFamily: Platform.select({ android: 'Poppins-Regular' })
                }}>
                {str.registreseParaNovidades}
              </Text>

              <TouchableOpacity
                onPress={() => {
                  setShowModal(false);
                  setModalVisible(false);
                  setTimeout(() => navigation.navigate('CadastroLogin'), 200);
                }}
                style={sx({ marginTop: [10], marginBottom: [10], alignSelf: ['center'] })}>
                <Text
                  sx={{
                    fontSize: ['$3'],
                    fontWeight: Platform.select({ web: ['bold'] }),
                    color: ['$primary1'],
                    textDecorationLine: ['underline'],
                    fontFamily: Platform.select({ android: 'Poppins-Bold' })
                  }}>
                  {str.queroMeCadastrar}
                </Text>
              </TouchableOpacity>

              <Text
                onPress={() => {
                  setShowModal(false);
                  setModalVisible(false);
                }}
                sx={{
                  padding: [10],
                  textAlign: ['center'],
                  fontSize: ['$1'],
                  color: ['$red'],
                  fontFamily: Platform.select({ android: 'Poppins-Medium' })
                }}>
                {str.agoraNao}
              </Text>
            </View>
          </View>
        </Modal>
      </Portal>
    </SafeAreaView>
  );
};

export default Home;

const HomeHeader: React.FC = () => {
  const sx = useSx();
  const linkTo = useLinkTo();
  const [{ lang }, dispatch] = useContext(LanguageContext);

  const onChangeLang = useCallback(
    (language: any) => {
      dispatch({ type: 'SET_LANG', payload: { lang: language } });
    },
    [dispatch]
  );

  return (
    <View
      sx={{
        flexDirection: ['row'],
        alignItems: ['center'],
        justifyContent: ['space-between'],
        paddingTop: [10],
        paddingBottom: [10]
      }}>
      <View sx={{ alignItems: ['center'] }}>
        <View
          sx={{
            flexDirection: ['row'],
            alignItems: ['center'],
            justifyContent: ['space-between']
          }}>
          <Image
            source={ImgHeader as any}
            style={sx({ width: 45, height: 45, marginLeft: 10 })}
            resizeMode='contain'
          />
          <Text
            sx={{
              fontFamily: Platform.select({ android: 'Poppins-Bold' }),
              color: '$primary3',
              marginLeft: [5, 10, 15],
              fontSize: [16, 16, 18],
              fontWeight: Platform.select({ web: ['bold'] })
            }}>
            Opção Costa Verde
          </Text>
        </View>
        <Button
          mode='text'
          color={Colors.primary3}
          onPress={async () =>
            Platform.OS === 'android'
              ? await Linking.openURL(`${SITE_LINK}/area-do-comerciante`)
              : linkTo('/area-do-comerciante')
          }
          labelStyle={sx({
            textTransform: ['none'],
            textDecorationLine: ['underline'],
            //textDecorationColor: ['$primary3'],
            fontFamily: Platform.select({ android: 'Poppins-Regular' })
          })}
          style={sx({ marginRight: [10, 10, 15] })}>
          Cadastrar minha Empresa
        </Button>
      </View>

      <View sx={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
        <TouchableOpacity
          onPress={() => onChangeLang('pt')}
          style={
            lang === 'pt'
              ? sx({
                  marginLeft: 2,
                  marginRight: 2,
                  borderRadius: [15, 15, 13],
                  borderWidth: 1,
                  borderColor: 'white'
                })
              : sx({ marginLeft: 2, marginRight: 2 })
          }>
          <Image
            resizeMode='contain'
            source={imgFlagPt as any}
            style={sx({ width: [30, 30, 26], height: [30, 30, 26] })}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onChangeLang('en')}
          style={
            lang === 'en'
              ? sx({
                  marginLeft: 2,
                  marginRight: 2,
                  borderRadius: [15, 15, 13],
                  borderWidth: 1,
                  borderColor: 'white'
                })
              : sx({ marginLeft: 2, marginRight: 2 })
          }>
          <Image
            resizeMode='cover'
            source={imgFlagEn as any}
            style={sx({ width: [30, 30, 26], height: [30, 30, 26] })}
          />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => onChangeLang('es')}
          style={
            lang === 'es'
              ? sx({
                  marginLeft: 2,
                  marginRight: 2,
                  borderRadius: [15, 15, 13],
                  borderWidth: 1,
                  borderColor: 'white'
                })
              : sx({ marginLeft: 2, marginRight: 2 })
          }>
          <Image
            resizeMode='cover'
            source={imgFlagEs as any}
            style={sx({ width: [30, 30, 26], height: [30, 30, 26] })}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

type MenuButtonProps = {
  img: any;
  title: string;
  onPress: () => void;
};

const MenuButton: React.FC<MenuButtonProps> = ({ img, title, onPress }) => {
  const sx = useSx();
  const { height, width } = useWindowDimensions();

  const isVertical = useMemo(() => height > width, [height, width]);

  return (
    <View
      sx={{
        height: isVertical
          ? [((width * 0.45) / 2) * 3, ((width * 0.3) / 2) * 3, ((width * 0.3) / 2) * 3]
          : [((height * 0.45) / 2) * 3, ((height * 0.3) / 2) * 3, ((height * 0.23) / 2) * 3],
        width: isVertical
          ? [width * 0.45, width * 0.3, width * 0.3]
          : [width * 0.45, width * 0.3, width * 0.23]
      }}>
      <TouchableOpacity
        onPress={onPress}
        style={sx({ marginBottom: 20, flex: 1, height: '100%', width: '100%' })}>
        <Image
          resizeMode='cover'
          source={img}
          style={sx({ width: '100%', height: '100%', borderRadius: [5, 5, 10] })}
        />
        <LinearGradient
          colors={['transparent', Colors.primary3]}
          style={sx({
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            paddingLeft: [10, 10, 15],
            paddingRight: [10, 10, 15],
            paddingTop: [10, 10, 15],
            paddingBottom: [5, 10, 10],
            borderRadius: [5, 5, 10]
          })}>
          <Text
            sx={{
              fontSize: [16, 18, 22],
              color: ['white'],
              fontFamily: Platform.select({ android: 'Poppins-Medium' })
            }}>
            {title}
          </Text>
        </LinearGradient>
      </TouchableOpacity>
    </View>
  );
};
