import React, { useContext, useEffect } from 'react';
import { TouchableOpacity, ScrollView, Platform } from 'react-native';
import { View, Text } from 'dripsy';
import { useAtom } from 'jotai';
import { maskPhone } from 'js-util/functions/mask-text';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { localAtom } from '../../atoms';
import { LanguageContext } from '../../context/LanguageContext';
import { TelefonesUteisProps } from '../../navigation/types';
import { CIDADE } from '../../values/constants';
import ServiceList from '../../components/ServiceList';
import { Colors } from '../../theme';
import { openEmail, openSite, openTelefone, openZap } from '../../functions';

const TelefonesUteis: React.FC<TelefonesUteisProps> = ({ navigation }) => {
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: `${CIDADE[local]} / ${str.telefonesuteis}`
    });
  }, [navigation, local, str]);

  const [textStyle, linkStyle] = [
    {
      fontSize: 16,
      marginLeft: 15,
      marginRight: 15,
      color: Colors.primary3,
      marginTop: 5,
      fontFamily: Platform.select({ android: 'Poppins-Regular' })
    },
    {
      /*  textDecorationStyle: 'dotted',
      textDecorationLine: 'underline',
      textDecorationColor: Colors.primary3 */
    }
  ];

  return (
    <View sx={{ flex: 1 }}>
      <ScrollView>
        <ServiceList
          repo='TelefoneUtil'
          // eslint-disable-next-line react/no-unstable-nested-components
          customRender={items => (
            <View
              sx={{
                width: ['100%', '100%', '75%'],
                marginBottom: [15],
                marginTop: [15],
                alignSelf: ['center'],
                flexWrap: ['wrap'],
                flexDirection: ['column', 'column', 'row']
              }}>
              {items.flat().map((item: any, index: number) => (
                <View
                  key={`tel-util-${item.Local}-${index}`}
                  sx={{ width: ['100%', '100%', '50%'], padding: [15, 15, 10] }}>
                  <Text
                    sx={{
                      fontSize: 16,
                      color: Colors.primary3,
                      fontWeight: Platform.select({ web: ['bold'] }),
                      fontFamily: Platform.select({ android: 'Poppins-Bold' })
                    }}>
                    {item.Nome}
                  </Text>

                  {
                    // @ts-ignore
                    item.Telefones?.map((tel, idx) => (
                      <TouchableOpacity
                        key={`tel-${idx}`}
                        onPress={() =>
                          tel.WhatsApp ? openZap(tel.Numero) : openTelefone(tel.Numero)
                        }>
                        <Text style={[textStyle, linkStyle] as any}>
                          {!tel.WhatsApp && <Icon name='phone' size={18} color={Colors.primary1} />}
                          {tel.WhatsApp && (
                            <Icon name='whatsapp' size={18} color={Colors.primary1} />
                          )}
                          {tel.Numero.length > 4 ? `+55 ${maskPhone(tel.Numero)}` : tel.Numero}{' '}
                        </Text>
                      </TouchableOpacity>
                    ))
                  }

                  {item.Email ? (
                    <TouchableOpacity onPress={() => openEmail(item.Email)}>
                      <Text style={[textStyle, linkStyle] as any}>
                        <Icon name='email' size={18} color={Colors.primary1} />
                        {item.Email}
                      </Text>
                    </TouchableOpacity>
                  ) : null}

                  {item.Site ? (
                    <TouchableOpacity onPress={() => openSite(item.Site)}>
                      <Text style={[textStyle, linkStyle] as any}>
                        <Icon name='web' size={18} color={Colors.primary1} />
                        {item.Site.replace('http://', '')
                          .replace('https://', '')
                          .replace('www.', '')}
                      </Text>
                    </TouchableOpacity>
                  ) : null}

                  {item.Endereco ? <Text style={textStyle as any}>{item.Endereco}</Text> : null}
                </View>
              ))}
            </View>
          )}
        />
      </ScrollView>
    </View>
  );
};

export default TelefonesUteis;
