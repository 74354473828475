import React, { useCallback, useContext } from 'react';
import {
  DrawerItem,
  DrawerItemList,
  DrawerContentComponentProps,
  DrawerContentScrollView
} from '@react-navigation/drawer';
import { useSx, View, Text, Image } from 'dripsy';
import { useLinkTo } from '@react-navigation/native';
import { useAtom } from 'jotai';
import Logo from '../../assets/images/img-logo.png';
import { LanguageContext } from '../../context/LanguageContext';
// @ts-ignore
import { Auth } from '../../shims/firebase';
import { userAtom } from '../../atoms';
import { Platform } from 'react-native';

type DrawerMenuProps = DrawerContentComponentProps;

type DrawerMenuItemProps = {
  label: string;
  onPress: () => void;
};

const DrawerMenu: React.FC<DrawerMenuProps> = props => {
  const sx = useSx();
  const linkTo = useLinkTo();
  const [{ strings: str }] = useContext(LanguageContext);
  const [user] = useAtom(userAtom);

  const signOut = useCallback(async () => {
    await Auth.signOut();
    linkTo('/Home');
  }, [linkTo]);

  return (
    <DrawerContentScrollView {...props} style={sx({ backgroundColor: ['$white'] })}>
      <View
        sx={{
          backgroundColor: '$primary3',
          flexDirection: ['row'],
          padding: [10],
          alignItems: ['center']
        }}>
        <Image
          source={Logo as any}
          resizeMode='contain'
          sx={{ width: [25, 25, 50], height: [25, 25, 50] }}
        />
        <Text
          sx={{
            color: '$white',
            marginLeft: [10],
            fontSize: [18],
            fontWeight: Platform.select({ web: ['bold'] }),
            fontFamily: Platform.select({ android: 'Poppins-Bold' })
          }}>
          Opção Costa Verde
        </Text>
      </View>

      <DrawerMenuItem label={str.inicio} onPress={() => linkTo('/Home')} />

      <DrawerItemList {...props} />

      {user && <DrawerMenuItem label={str.sair} onPress={() => signOut()} />}
    </DrawerContentScrollView>
  );
};

export default DrawerMenu;

const DrawerMenuItem: React.FC<DrawerMenuItemProps> = ({ label, onPress }) => {
  const sx = useSx();
  return (
    <DrawerItem
      label={label}
      onPress={onPress}
      labelStyle={sx({
        color: ['$primary3'],
        fontWeight: Platform.select({ web: ['600'] }),
        fontFamily: Platform.select({ android: 'Poppins-Bold', web: 'Poppins' })
      })}
    />
  );
};
