import { Platform } from 'react-native';

export const VERSION = Platform.select({ android: '4.3.17', ios: '', web: '3.0.0' });
export const CODEPUSH_VERSION = Platform.select({ android: '' });

export const CIDADE = {
  'ilha-grande': 'Ilha Grande',
  mangaratiba: 'Mangaratiba',
  'angra-dos-reis': 'Angra dos Reis',
  paraty: 'Paraty',
  'sao-paulo': 'São Paulo',
  'rio-de-janeiro': 'Rio de Janeiro',
  'conceicao-de-jacarei': 'Conceição de Jacareí'
};

export const STORAGEKEY_LANGUAGE = 'STORAGEKEY_LANGUAGE';
export const STORAGEKEY_LIKES = 'STORAGEKEY_LIKES';
export const STORAGEKEY_COMERCIANTE = 'STORAGEKEY_COMERCIANTE';

export const SITE_LINK = 'https://opcaocostaverde.com.br';
export const TERMOS_LINK = 'https://opcaocostaverde.com.br/termos-de-uso';
export const POLITICA_LINK = 'https://opcaocostaverde.com.br/politica-de-privacidade';

export const CLASSIFICACOES = ['$$', '$$$', '$$$$$'];

export const VALOR_PLANO = 'R$ 0,00';

export const BOOKING_LINKS = {
  'ilha-grande':
    'https://www.booking.com/searchresults.xb.html?region=3464&aid=8086593&no_rooms=1&group_adults=2',
  mangaratiba:
    'https://www.booking.com/searchresults.xb.html?city=-653332&aid=8086593&no_rooms=1&group_adults=2',
  'angra-dos-reis':
    'https://www.booking.com/searchresults.xb.html?city=-625033&aid=8086593&no_rooms=1&group_adults=2',
  paraty:
    'https://www.booking.com/searchresults.xb.html?city=-659504&aid=8086593&no_rooms=1&group_adults=2'
};
