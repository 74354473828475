import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Platform, ScrollView } from 'react-native';
import { View, Text, useSx, Image, TextInput } from 'dripsy';
import { Button, Snackbar } from 'react-native-paper';
import { useAtom } from 'jotai';
import { useLinkTo } from '@react-navigation/native';
import { comercianteAtom, isLoadingAtom } from '../../../atoms';
import { LanguageContext } from '../../../context/LanguageContext';
import Logo from '../../../assets/images/img-logo.png';
import { Colors } from '../../../theme';
import { ACSigninProps } from '../../../navigation/types';
import { ACRepository } from '../../../repositories/ACRepository';
import StorageService from '../../../services/StorageService';
import { STORAGEKEY_COMERCIANTE } from '../../../values/constants';

const ACSignin: React.FC<ACSigninProps> = ({ navigation }) => {
  const sx = useSx();
  const linkTo = useLinkTo();
  const [{ strings: str }] = useContext(LanguageContext);
  const [senha, setSenha] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [comerciante, setComerciante] = useAtom(comercianteAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const onSubmit = useCallback(async () => {
    try {
      if (!email || !senha) {
        setError('Preencha todos os campos para continuar!');
        return;
      }

      setIsLoading(true);

      const res = await ACRepository.signin(email.trim().toLowerCase(), senha);

      if (res.repo === 'Hospedagem') {
        window.location.replace('https://opcaocostaverde.com.br/central-reservas');
        return;
      }

      await StorageService.set(STORAGEKEY_COMERCIANTE, res);

      setIsLoading(false);
      setComerciante(res);
    } catch (err) {
      __DEV__ && console.log('[ERROR:ACSignin.onSubmit]', err);
      setIsLoading(false);
      // @ts-ignore
      setError(err?.message || str.errocadastro);
    }
  }, [str, senha, email, setIsLoading, setComerciante]);

  useEffect(() => {
    if (comerciante) {
      navigation.replace('ACHome');
    }
  }, [comerciante, navigation]);

  return (
    <>
      <View sx={{ flex: 1 }}>
        <ScrollView>
          <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
            <View sx={{ margin: [15], alignSelf: ['center'] }}>
              <Image
                source={Logo as any}
                sx={{
                  height: [60, 60, 80],
                  width: [60, 60, 80],
                  alignSelf: ['center'],
                  marginTop: [15]
                }}
              />
              <Text
                sx={{
                  color: '$primary5',
                  alignSelf: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                  fontSize: [16, 16, 20],
                  fontFamily: Platform.select({ android: 'Poppins-Medium' }),
                  textAlign: 'center'
                }}>
                Opção Costa Verde | Comerciante
              </Text>
            </View>

            <Text
              sx={{
                color: '$primary3',
                marginLeft: 30,
                fontSize: '$1',
                fontWeight: Platform.select({ web: ['bold'] }),
                fontFamily: Platform.select({ android: 'Poppins-Bold' })
              }}>
              {str.email}
            </Text>
            <TextInput
              value={email}
              placeholderTextColor={Colors.primary3}
              onChangeText={setEmail}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
              keyboardType='email-address'
              autoCapitalize='none'
            />

            <Text
              sx={{
                color: '$primary3',
                marginLeft: 30,
                fontSize: '$1',
                fontWeight: Platform.select({ web: ['bold'] }),
                fontFamily: Platform.select({ android: 'Poppins-Bold' })
              }}>
              {str.senha}
            </Text>
            <TextInput
              value={senha}
              placeholderTextColor={Colors.primary3}
              onChangeText={setSenha}
              secureTextEntry
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
            />

            <Button
              mode='contained'
              color={Colors.primary5}
              onPress={onSubmit}
              labelStyle={sx({
                color: '$white',
                fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
              })}
              style={sx({ marginBottom: 10, marginTop: 5, marginLeft: 15, marginRight: 15 })}>
              {str.entrar}
            </Button>

            <Text
              sx={{
                alignSelf: ['center'],
                color: '$primary3',
                fontSize: ['$2'],
                marginBottom: [5],
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}>
              OU
            </Text>

            <Button
              mode='text'
              color={Colors.primary1}
              onPress={() => linkTo('/area-do-comerciante/cadastro')}
              labelStyle={sx({
                fontWeight: Platform.select({ web: ['bold'] }),
                fontFamily: Platform.select({ android: 'Poppins-Bold', web: 'Poppins' })
              })}
              style={sx({ marginBottom: 10, marginTop: 5, marginLeft: 15, marginRight: 15 })}>
              Cadastre-se
            </Button>

            <Button
              mode='text'
              color={Colors.danger}
              onPress={() => linkTo('/area-do-comerciante/recuperar-senha')}
              labelStyle={sx({
                fontWeight: Platform.select({ web: ['bold'] }),
                fontFamily: Platform.select({ android: 'Poppins-Bold', web: 'Poppins' })
              })}
              style={sx({ marginBottom: 10, marginLeft: 15, marginRight: 15 })}>
              Recuperar Senha
            </Button>

            <Text
              sx={{
                color: '$primary3',
                marginLeft: 30,
                marginRight: 30,
                textAlign: 'center',
                fontSize: 14,
                marginBottom: 15,
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}>
              {str.aocriar}
              {'\n'}
              <Text
                sx={{ textDecorationLine: 'underline', fontWeight: ['bold'] }}
                onPress={() => linkTo('/termos-de-uso')}>
                {str.termosuso}
              </Text>{' '}
              e{' '}
              <Text
                sx={{ textDecorationLine: 'underline', fontWeight: ['bold'] }}
                onPress={() => linkTo('/politica-de-privacidade')}>
                {str.politicaprivacidade}
              </Text>
            </Text>
          </View>
        </ScrollView>
      </View>

      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ top: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

export default ACSignin;
