import { Linking, Share, Platform } from 'react-native';
import { sortByKey } from 'js-util/functions/array-util';
// @ts-ignore
import md5 from 'md5';
import { SITE_LINK } from '../values/constants';

/**
 * Monta uma lista dividindo a lista original em partes
 * limitando a quantidade de itens
 *
 * @export
 * @param {Array<any>} [list=[]] - lista original
 * @return {Array<Array<any>>|Array<any>} Matriz da lista original dividida ou lista original
 */
export function mountServiceList(list: Array<any> = []) {
  const listLength = list.length;

  if (listLength === 0) {
    return list;
  }

  const listLimit = [2].includes(listLength)
    ? 1
    : [3, 4].includes(listLength)
    ? 2
    : [5, 6].includes(listLength)
    ? 3
    : 4;

  const listRest = listLength % listLimit;
  const mountedListLength = Math.trunc(listLength / listLimit) + (listRest !== 0 ? 1 : 0);

  let mountedList = Array.from(Array(mountedListLength));

  for (let i in mountedList) {
    mountedList[i] = list.splice(0, listLimit);
  }

  return mountedList;
}

// @ts-ignore
export function keepDataIfNeeded(freshData, sourceData, keyData) {
  if (!freshData || freshData.length === 0) {
    return sourceData[keyData];
  }
  return freshData;
}

// @ts-ignore
export function getDistance(lat1, lon1, lat2, lon2, unit = 'K') {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }

  let radlat1 = (Math.PI * lat1) / 180;
  let radlat2 = (Math.PI * lat2) / 180;
  let theta = lon1 - lon2;
  let radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist = dist * 1.609344;
  }
  if (unit === 'N') {
    dist = dist * 0.8684;
  }
  return dist;
}

export function orderList(list: Array<any> = []) {
  if (list.length === 0) return list;
  return [
    // @ts-ignore
    ...list.filter(i => i.Parceiro).sort(sortByKey('Nome')),
    // @ts-ignore
    ...list.filter(i => !i.Parceiro).sort(sortByKey('Nome'))
  ];
}

export async function openSite(site: string) {
  if (!site) return;
  await Linking.openURL(site);
}

export async function openTelefone(phone: string) {
  if (!phone) return;
  await Linking.openURL(`tel:${phone.length > 3 ? '+55' : ''}${phone}`);
}

export async function openPreco(nome: string, endereco: string) {
  if (!nome || !endereco) return;
  nome = nome.replace(new RegExp(' ', 'g'), '%20');
  endereco = endereco.replace(new RegExp(' ', 'g'), '%20');
  const url = `https://google.com.br/search?tbm=lcl&q=${nome}-${endereco}&rflfq=1&num=20`;
  await Linking.openURL(url);
}

export async function openEmail(email: string) {
  if (!email) return;
  await Linking.openURL(`mailto:${email}`);
}

export async function openShare(item: any) {
  const link = `${SITE_LINK}/servico/${item.Local}/${item.repo}/${item.id}`;

  if (Platform.OS === 'web') {
    navigator.clipboard.writeText(link).then(
      // eslint-disable-next-line no-alert
      () => alert('Link copiado para a área de transferência!'),
      err => console.log(err)
    );

    return;
  }
  try {
    /* const result = */ await Share.share({
      message: `Conheça *${item.Nome}* na *Costa Verde*: ${link}`
    });
    /* if (result.action === Share.sharedAction) {
			if (result.activityType) {
			} else {
			}
		} else if (result.action === Share.dismissedAction) {
		} */
  } catch (error) {
    __DEV__ && console.log('error', error);
  }
}

export async function openZap(num: string) {
  if (!num) return;
  await Linking.openURL(`https://api.whatsapp.com/send?phone=55${num}`);
}

export function getMapUrl(endereco: string) {
  return (
    'https://www.google.com/maps/embed/v1/search?q=' +
    endereco +
    '&key=AIzaSyBrovRXAOJ0pYzcsRRzLqG973NeZg7fm2w'
  );
}

export function generateDeviceCode() {
  let n1 = Math.floor(Math.random() * 10);
  let n2 = Math.floor(Math.random() * 10);
  let n3 = Math.floor(Math.random() * 10);
  let n4 = Math.floor(Math.random() * 10);
  let n5 = Math.floor(Math.random() * 10);
  let n6 = Math.floor(Math.random() * 10);

  return '' + n1 + n2 + n3 + n4 + n5 + n6;
}

export function getValidator(deviceCode: string) {
  let key = 'HZxOC3BcsWvcr9o';
  return md5(key + deviceCode)
    .substring(0, 6)
    .toUpperCase();
}

export function formatDateDispon(date: string) {
  const [y, m, d] = date.split('-');
  return `${d}/${m}/${y}`;
}
