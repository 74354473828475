import React, { useContext } from 'react';
import { Linking, Platform, ScrollView, TouchableOpacity } from 'react-native';
import { View, Text } from 'dripsy';
import { useLinkTo } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { LanguageContext } from '../../context/LanguageContext';
import { PoliticaPrivacidadeProps, SaibaMaisProps, TermosUsoProps } from '../../navigation/types';
import ServiceAlone from '../../components/ServiceAlone';
import FormatedText from '../../components/FormatedText';
import { Colors } from '../../theme';
import LogoTitle from '../../components/LogoTitle';
import { CODEPUSH_VERSION, VERSION } from '../../values/constants';

const Informacao: React.FC<SaibaMaisProps> = ({}) => {
  const linkTo = useLinkTo();
  const [{ strings: str, lang }] = useContext(LanguageContext);

  return (
    <View sx={{ flex: 1 }}>
      <ScrollView>
        <View
          sx={{
            width: ['100%', '100%', '75%'],
            marginBottom: [15],
            marginTop: [15],
            alignSelf: ['center']
          }}>
          <LogoTitle />

          <ServiceAlone
            repo='Informacao'
            // eslint-disable-next-line react/no-unstable-nested-components
            customRender={data => {
              const item = data ? data.find((i: any) => i.id === '-LD50ddktMf55k9J430E') : null;

              if (!item) return null;

              return (
                <View>
                  <FormatedText text={item.Descricao[lang]} />
                </View>
              );
            }}
          />

          <View
            sx={{
              flexDirection: ['row'],
              alignSelf: ['center'],
              alignItems: ['center'],
              marginTop: [25],
              marginBottom: [25]
            }}>
            <TouchableOpacity
              onPress={async () =>
                await Linking.openURL(
                  'https://www.linkedin.com/in/ilha-grande-angra-dos-reis-costa-verde'
                )
              }>
              <Icon name='linkedin' size={24} color={Colors.primary3} />
            </TouchableOpacity>
            <View sx={{ width: [10, 15, 15] }} />

            <TouchableOpacity
              onPress={async () =>
                await Linking.openURL('https://www.youtube.com/channel/UC86Hvi1YLDKhTNflxxrMiVg')
              }>
              <Icon name='youtube' size={24} color={Colors.primary3} />
            </TouchableOpacity>
            <View sx={{ width: [10, 15, 15] }} />

            <TouchableOpacity
              onPress={async () => await Linking.openURL('https://twitter.com/IlhaApp')}>
              <Icon name='twitter' size={24} color={Colors.primary3} />
            </TouchableOpacity>
            <View sx={{ width: [10, 15, 15] }} />

            <TouchableOpacity
              onPress={async () =>
                await Linking.openURL('https://www.facebook.com/ilhagrandecostaverde')
              }>
              <Icon name='facebook' size={24} color={Colors.primary3} />
            </TouchableOpacity>
            <View sx={{ width: [10, 15, 15] }} />

            <TouchableOpacity
              onPress={async () =>
                await Linking.openURL('https://www.instagram.com/opcao_costa_verde/')
              }>
              <Icon name='instagram' size={24} color={Colors.primary3} />
            </TouchableOpacity>
          </View>

          <Text
            sx={{
              color: '$primary3',
              marginLeft: 30,
              marginRight: 30,
              textAlign: 'center',
              fontSize: 14,
              marginBottom: 15,
              fontFamily: Platform.select({ android: 'Poppins-Regular' })
            }}>
            <Text
              sx={{
                textDecorationLine: 'underline',
                fontWeight: ['bold']
              }}
              onPress={() => linkTo('/termos-de-uso')}>
              {str.termosuso}
            </Text>{' '}
            e{' '}
            <Text
              sx={{
                textDecorationLine: 'underline',
                fontWeight: ['bold']
              }}
              onPress={() => linkTo('/politica-de-privacidade')}>
              {str.politicaprivacidade}
            </Text>
          </Text>

          <AllRights />
        </View>
      </ScrollView>
    </View>
  );
};

const TermosUso: React.FC<TermosUsoProps> = ({}) => {
  const [{ lang }] = useContext(LanguageContext);

  return (
    <View sx={{ flex: 1 }}>
      <ScrollView>
        <View
          sx={{
            width: ['100%', '100%', '75%'],
            marginBottom: [15],
            marginTop: [15],
            alignSelf: ['center']
          }}>
          <LogoTitle />

          <ServiceAlone
            repo='Informacao'
            // eslint-disable-next-line react/no-unstable-nested-components
            customRender={data => {
              const item = data ? data.find((i: any) => i.id === '-LD50ddktMf55k9J430G') : null;

              if (!item) return null;

              return (
                <View>
                  <FormatedText text={item.Descricao[lang]} />
                </View>
              );
            }}
          />

          <AllRights />
        </View>
      </ScrollView>
    </View>
  );
};

const PoliticaPrivacidade: React.FC<PoliticaPrivacidadeProps> = ({}) => {
  const [{ lang }] = useContext(LanguageContext);

  return (
    <View sx={{ flex: 1 }}>
      <ScrollView>
        <View
          sx={{
            width: ['100%', '100%', '75%'],
            marginBottom: [15],
            marginTop: [15],
            alignSelf: ['center']
          }}>
          <LogoTitle />

          <ServiceAlone
            repo='Informacao'
            // eslint-disable-next-line react/no-unstable-nested-components
            customRender={data => {
              const item = data ? data.find((i: any) => i.id === '-LD50ddktMf55k9J430F') : null;

              if (!item) return null;

              return (
                <View>
                  <FormatedText text={item.Descricao[lang]} />
                </View>
              );
            }}
          />

          <AllRights />
        </View>
      </ScrollView>
    </View>
  );
};

const AllRights = () => {
  return (
    <Text
      sx={{
        marginTop: 15,
        alignSelf: ['center'],
        textAlign: ['center'],
        color: ['$primary3'],
        fontFamily: Platform.select({ android: 'Poppins-Regular' })
      }}>
      © 2017-{new Date().getFullYear()} Corporativa Comunicação. v{VERSION}
      {CODEPUSH_VERSION ? `-${CODEPUSH_VERSION}` : ''}
    </Text>
  );
};

export default {
  Informacao,
  TermosUso,
  PoliticaPrivacidade
};
