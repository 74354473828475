import React, { useEffect, useContext } from 'react';
import { View } from 'dripsy';
import { useAtom } from 'jotai';
import ServiceList from '../../components/ServiceList';
import { localAtom } from '../../atoms';
import { LanguageContext } from '../../context/LanguageContext';
import { CampingProps } from '../../navigation/types';
import { CIDADE } from '../../values/constants';

const Camping: React.FC<CampingProps> = ({ navigation }) => {
  const [local] = useAtom(localAtom);
  const [{ strings: str }] = useContext(LanguageContext);

  useEffect(() => {
    navigation.setOptions({ headerTitle: `${CIDADE[local]} / ${str.camping}` });
  }, [navigation, local, str]);

  return (
    <View sx={{ flex: 1 }}>
      <ServiceList repo='Camping' />
    </View>
  );
};

export default Camping;
