import React, { createContext, useReducer, useEffect } from 'react';
import * as RNLocalize from 'react-native-localize';
import en from '../assets/json/en.json';
import es from '../assets/json/es.json';
import pt from '../assets/json/pt.json';
import StorageService from '../services/StorageService';
import { STORAGEKEY_LANGUAGE } from '../values/constants';

export type Langs = 'en' | 'es' | 'pt';

type LanguageContextState = { lang: Langs; strings: typeof pt };

type LanguageContextActions = SetLanguage;

type SetLanguage = {
  type: 'SET_LANG';
  payload: { lang: Langs };
};

const languages = { en, es, pt };

const initialState: LanguageContextState = {
  lang: 'pt',
  strings: pt
};

export const LanguageContext = createContext<
  [LanguageContextState, React.Dispatch<LanguageContextActions>]
>([initialState, () => {}]);

const reducer = (
  state: LanguageContextState,
  action: LanguageContextActions
): LanguageContextState => {
  switch (action.type) {
    case 'SET_LANG':
      const { lang } = action.payload;
      return { lang, strings: languages[lang] };
    default:
      return state;
  }
};

export const LanguageProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const locales = RNLocalize.getLocales();

    StorageService.get(STORAGEKEY_LANGUAGE).then(
      value => {
        dispatch({
          type: 'SET_LANG',
          payload: { lang: (value || locales?.[0]?.languageCode || 'pt') as Langs }
        });
      },
      err => __DEV__ && console.log('[ERROR:StorageService.get]', err)
    );
  }, []);

  useEffect(() => {
    StorageService.set(STORAGEKEY_LANGUAGE, state.lang).then(
      () => {},
      err => __DEV__ && console.log('[ERROR:StorageService.set]', err)
    );
  }, [state]);

  // prettier-ignore
  return (
    <LanguageContext.Provider value={[state, dispatch]}>
    {children}
    </LanguageContext.Provider>
  );
};
