import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Platform, ScrollView } from 'react-native';
import { View, Text, useSx, Image, TextInput } from 'dripsy';
import { Button, Snackbar } from 'react-native-paper';
import { useAtom } from 'jotai';
import { useLinkTo } from '@react-navigation/native';
import { comercianteAtom, isLoadingAtom } from '../../../atoms';
import { LanguageContext } from '../../../context/LanguageContext';
import Logo from '../../../assets/images/img-logo.png';
import { Colors } from '../../../theme';
import { ACRecoveryProps } from '../../../navigation/types';
import { ACRepository } from '../../../repositories/ACRepository';

const ACRecovery: React.FC<ACRecoveryProps> = ({ navigation }) => {
  const sx = useSx();
  const linkTo = useLinkTo();
  const [{ strings: str }] = useContext(LanguageContext);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [comerciante] = useAtom(comercianteAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  const onSubmit = useCallback(async () => {
    try {
      if (!email) {
        setError('Preencha o email para continuar!');
        return;
      }

      setIsLoading(true);

      await ACRepository.recovery(email.trim());

      setIsLoading(false);
      setError(
        'Enviaremos informações de recuperação no email informado caso ele corresponda ao seu cadastro!'
      );
    } catch (err) {
      __DEV__ && console.log('[ERROR:ACSignin.onSubmit]', err);
      setIsLoading(false);
      // @ts-ignore
      setError(err?.message || str.errocadastro);
    }
  }, [str, email, setIsLoading]);

  useEffect(() => {
    if (comerciante) {
      navigation.replace('ACHome');
    }
  }, [comerciante, navigation]);

  return (
    <>
      <View sx={{ flex: 1 }}>
        <ScrollView>
          <View sx={{ width: ['100%', '100%', '45%'], alignSelf: ['center'], marginTop: [15] }}>
            <View sx={{ margin: [15], alignSelf: ['center'] }}>
              <Image
                source={Logo as any}
                sx={{
                  height: [60, 60, 80],
                  width: [60, 60, 80],
                  alignSelf: ['center'],
                  marginTop: [15]
                }}
              />
              <Text
                sx={{
                  color: '$primary5',
                  alignSelf: 'center',
                  marginTop: 10,
                  marginBottom: 10,
                  fontSize: [16, 16, 20],
                  fontFamily: Platform.select({ android: 'Poppins-Medium' }),
                  textAlign: 'center'
                }}>
                Opção Costa Verde | Comerciante
              </Text>
            </View>

            <Text
              sx={{
                color: '$primary3',
                marginLeft: 30,
                fontSize: '$1',
                fontWeight: Platform.select({ web: ['bold'] }),
                fontFamily: Platform.select({ android: 'Poppins-Bold' })
              }}>
              Informe o email para recuperar senha
            </Text>
            <TextInput
              value={email}
              placeholderTextColor={Colors.primary3}
              onChangeText={setEmail}
              sx={{
                borderBottomWidth: 1,
                borderBottomColor: '$primary3',
                marginRight: 25,
                marginLeft: 25,
                marginBottom: 10,
                color: '$primary3',
                height: Platform.select({ web: [30], android: [45] }),
                fontFamily: Platform.select({ android: 'Poppins-Regular' })
              }}
              keyboardType='email-address'
              autoCapitalize='none'
            />

            <Button
              mode='contained'
              color={Colors.primary5}
              onPress={onSubmit}
              labelStyle={sx({
                color: '$white',
                fontFamily: Platform.select({ android: 'Poppins-Regular', web: 'Poppins' })
              })}
              style={sx({ marginBottom: 10, marginTop: 5, marginLeft: 15, marginRight: 15 })}>
              Enviar
            </Button>

            <Button
              mode='text'
              color={Colors.primary1}
              onPress={() => linkTo('/area-do-comerciante/entrar')}
              labelStyle={sx({
                fontWeight: Platform.select({ web: ['bold'] }),
                fontFamily: Platform.select({ android: 'Poppins-Bold', web: 'Poppins' })
              })}
              style={sx({ marginBottom: 10, marginTop: 5, marginLeft: 15, marginRight: 15 })}>
              Voltar
            </Button>
          </View>
        </ScrollView>
      </View>

      <Snackbar
        visible={Boolean(error)}
        onDismiss={() => setError('')}
        wrapperStyle={sx({ top: 15 })}>
        {error}
      </Snackbar>
    </>
  );
};

export default ACRecovery;
