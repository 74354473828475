import React, { useEffect, useContext, useState, useRef } from 'react';
import {
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
  Platform,
  Image,
  FlatList
} from 'react-native';
import { maskPhone } from 'js-util/functions/mask-text';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { View, Text, useSx } from 'dripsy';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { useAtom } from 'jotai';
import { Portal, Modal, IconButton, Divider } from 'react-native-paper';
// @ts-ignore
import LinearGradient from '../../shims/gradient';
//import auth from '@react-native-firebase/auth';
//import { openTelefone, openZap } from '../../components/SocialBar/acoes';
import BannerNoticias from '../../components/BannerNoticias';
import { BOOKING_LINKS, CIDADE, STORAGEKEY_LIKES } from '../../values/constants';
import { DetalhesServicoProps, Navigation } from '../../navigation/types';
import { LanguageContext } from '../../context/LanguageContext';
import {
  formatDateDispon,
  generateDeviceCode,
  getMapUrl,
  getValidator,
  openEmail,
  openPreco,
  openShare,
  openSite,
  openTelefone,
  openZap
} from '../../functions';
import { Colors } from '../../theme';
import ContainedButton from '../../components/ContainedButton';
import TagTopLeft from '../../components/TagTopLeft';
import TagTopRight from '../../components/TagTopRight';
import TagBottomLeft from '../../components/TagBottomLeft';
import TagBottomRight from '../../components/TagBottomRight';
import { isLoadingAtom, likesAtom, localAtom, selectedItemAtom, userAtom } from '../../atoms';
import StorageService from '../../services/StorageService';
// @ts-ignore
import MapLocal from '../../components/MapLocal';
// @ts-ignore
import { RTDatabase } from '../../shims/firebase';
// @ts-ignore
import { Dialog } from '../../shims/dialog';
import FormatedText from '../../components/FormatedText';
// @ts-ignore
import { openMap } from '../../shims/openmap';
import { sortByKey } from 'js-util/functions/array-util';

const DetalhesServico: React.FC<DetalhesServicoProps> = ({ navigation, route }) => {
  const [{ strings: str }] = useContext(LanguageContext);
  const [item, setItem] = useAtom(selectedItemAtom);
  const [, setIsLoading] = useAtom(isLoadingAtom);

  useEffect(() => {
    navigation.setOptions({ title: item?.NomeExperiencia || item?.Nome || str.detalhesservico });
  }, [navigation, item, str]);

  useEffect(() => {
    return () => setItem(null);
  }, [setItem]);

  useEffect(() => {
    if (!item) {
      setIsLoading(true);
      const itemParam = route?.params;

      RTDatabase.get(`/${itemParam?.tipo}/${itemParam?.id}`)
        .then((value: any) => {
          if (value) {
            setItem({
              ...(value as any),
              id: itemParam?.id,
              repo: itemParam?.tipo
            });
          }
        })
        .catch((err: any) => console.log('err', err))
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params, setItem, setIsLoading]);

  return <DetalhesServicoContent item={item} />;
};

export default DetalhesServico;

export const DetalhesServicoContent: React.FC<{ item: any }> = ({ item }) => {
  const sx = useSx();
  const { width, height } = useWindowDimensions();
  const [{ lang, strings: str }] = useContext(LanguageContext);
  const navigation = useNavigation<Navigation>();
  const linkTo = useLinkTo();
  const [local] = useAtom(localAtom);
  const [user] = useAtom(userAtom);
  const [curtidas, setCurtidas] = useAtom(likesAtom);
  const [modalVisible, setModalVisible] = useState(false);
  const [deviceCode, setDeviceCode] = useState(generateDeviceCode());
  const [validator, setValidator] = useState('');

  const imagesRef = useRef<FlatList>(null);

  const imgUrl = item?.Images?.[0] || item?.UrlImagem || null;
  const imageList = item?.Images?.length ? [...item?.Images] : imgUrl ? [imgUrl] : [];
  const repo = item?.repo;
  const desconto = item?.Desconto ? `${str.apresente} App. ${str.Desconto} ${item?.Desconto}%` : '';
  const titleKey = item?.Titulo ? 'Titulo' : '';
  const descriptionKey = 'Descricao';
  const preco = [
    'Hospedagem',
    'Restaurante',
    'Passeio',
    'Transfer',
    'Mergulho',
    'Camping',
    'Estacionamento'
  ].includes(repo)
    ? item?.Preco || '$$'
    : '';
  const tipo = !['Lugares', 'Lancha'].includes(repo) ? item?.Tipo || item?.Especialidade || '' : '';
  const promocao = item?.Promocao ? str.conhecapromocao : '';

  const topLeft = item?.Parceiro ? str.destaque : '';
  const topRight = desconto || promocao;
  const bottomLeft = tipo;
  const bottomRight = preco;

  useEffect(() => {
    StorageService.get(STORAGEKEY_LIKES).then(value => {
      if (value) setCurtidas(JSON.parse(value));
    });
  }, [item, setCurtidas]);

  /* useEffect(() => {
    (function () {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      var BookingAffiliateWidget = new Booking.AffiliateWidget({
        iframeSettings: {
          selector: 'bookingAffiliateWidget_14a049f8-01df-42f8-8220-3c9b337c89a8',
          responsive: true
        },
        widgetSettings: {}
      });
    })();
  }, []); */

  return (
    <View sx={{ flex: 1 }}>
      <BannerNoticias />

      <View sx={{ flex: 1 }}>
        <ScrollView>
          {item ? (
            <>
              <View
                sx={{
                  height: [height / 3 + 30, height / 3 + 30, height * 0.45],
                  width: [width, width, width * 0.4],
                  alignSelf: [undefined, undefined, 'center']
                }}>
                <Image
                  source={{ uri: imgUrl }}
                  style={sx({ height: ['100%'], width: ['100%'] })}
                  resizeMode={'cover'}
                />

                <LinearGradient
                  colors={['transparent', Colors.primary3]}
                  style={sx({
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    paddingLeft: [10, 10, 15],
                    paddingRight: [10, 10, 15],
                    paddingTop: [10, 10, 15],
                    paddingBottom: [5, 10, 10]
                  })}>
                  <View sx={{ height: [10, 15, 20] }} />
                </LinearGradient>

                {topLeft ? <TagTopLeft label={str.destaque} /> : null}

                {topRight ? <TagTopRight label={topRight} /> : null}

                {bottomLeft ? <TagBottomLeft label={bottomLeft} /> : null}

                {bottomRight ? <TagBottomRight label={bottomRight} /> : null}
              </View>

              <View
                sx={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  backgroundColor: '$primary3',
                  justifyContent: ['space-around', 'space-around', 'center']
                }}>
                {item.Site ? (
                  <ActionButton onPress={() => openSite(item.Site)} icon='web' text={str.site} />
                ) : null}

                {
                  //@ts-ignore
                  item.Telefones?.some(t => !t.WhatsApp) ? (
                    <ActionButton
                      onPress={() =>
                        openTelefone(
                          // @ts-ignore
                          item.Telefones?.find(t => !t.WhatsApp)?.Numero
                        )
                      }
                      icon='phone'
                      text={str.ligar}
                    />
                  ) : null
                }

                {!item.Parceiro && !['Lugares'].includes(repo) && item.Endereco ? (
                  <ActionButton
                    onPress={() => openPreco(item.Nome, item.Endereco)}
                    icon='cash-marker'
                    text={str.valor}
                  />
                ) : null}

                {item.Email ? (
                  <ActionButton
                    onPress={() => openEmail(item.Email)}
                    icon='email-outline'
                    text={str.email}
                  />
                ) : null}

                <ActionButton
                  onPress={() => openShare(item)}
                  icon='share'
                  text={str.compartilhar}
                />

                <ActionButton
                  onPress={() => {
                    if (!curtidas.includes(`${item.repo}/${item.id}`)) {
                      if (user) {
                        RTDatabase.set(`${item.repo}/${item.id}/Curtidas/${user.uid}`, Date.now())
                          .then(() => {
                            Dialog.alert(str.obrigadocurtida);
                            const likes = [...curtidas, `${item.repo}/${item.id}`];
                            setCurtidas(likes);
                            StorageService.set(STORAGEKEY_LIKES, likes).then(
                              () => {},
                              err => console.log('err', err)
                            );
                          })
                          .catch((err: any) => console.log('err', err));
                      } else {
                        Dialog.confirm(str.cadastrarparacurtir, (value: boolean) => {
                          if (value) linkTo('/cadastro');
                        });
                      }
                    }
                  }}
                  icon={curtidas.includes(`${item.repo}/${item.id}`) ? 'heart' : 'heart-outline'}
                  text={str.curtir}
                />

                {item.Instagram ? (
                  <ActionButton
                    onPress={() => openSite(item.Instagram)}
                    icon='instagram'
                    text='Instagram'
                  />
                ) : null}

                {item.Video ? (
                  <ActionButton
                    onPress={() => openSite(item.Video)}
                    icon='play-circle-outline'
                    text='Video'
                  />
                ) : null}

                {
                  //@ts-ignore
                  item.Telefones?.some(t => t.WhatsApp) ? (
                    <ActionButton
                      onPress={() =>
                        openZap(
                          //@ts-ignore
                          item.Telefones?.find(t => t.WhatsApp)?.Numero
                        )
                      }
                      icon='whatsapp'
                      text={str.whatsapp}
                    />
                  ) : null
                }
              </View>

              {imgUrl ? (
                <TouchableOpacity
                  style={sx({
                    backgroundColor: '$primary3',
                    width: 35,
                    height: 35,
                    position: 'absolute',
                    top: 15,
                    right: 15,
                    borderRadius: 5,
                    justifyContent: 'center',
                    alignItems: 'center'
                  })}
                  onPress={() =>
                    Platform.OS === 'android'
                      ? navigation.navigate('ImageZoom', { images: imageList })
                      : setModalVisible(true)
                  }>
                  <Icon name='arrow-expand' color='white' size={24} />
                </TouchableOpacity>
              ) : null}

              <View
                sx={{ width: ['100%', '100%', '75%'], marginBottom: [15], alignSelf: ['center'] }}>
                {/* Platform.OS === 'web' ? (
                  <>
                    <div id='bookingAffiliateWidget_14a049f8-01df-42f8-8220-3c9b337c89a8'>
                      &nbsp;
                    </div>
                  </>
                ) : null */}

                <Text
                  sx={{
                    fontSize: 18,
                    marginTop: 15,
                    marginBottom: 10,
                    marginRight: 15,
                    marginLeft: 15,
                    color: '$primary3',
                    fontFamily: Platform.select({ android: 'Poppins-Regular' })
                  }}>
                  {repo === 'Acesso'
                    ? `${item.Origem} ↔ ${
                        // @ts-ignore
                        CIDADE[item.Local]
                      }`
                    : repo === 'Experiencia'
                    ? `${item.NomeExperiencia}\npor ${item.Nome}`
                    : titleKey
                    ? item[titleKey][lang]
                    : // @ts-ignore
                      item.Nome || CIDADE[item.Local]}
                </Text>

                {desconto ? (
                  <TouchableOpacity
                    onPress={() => {
                      if (!deviceCode) setDeviceCode(generateDeviceCode());
                      setValidator(getValidator(deviceCode));
                    }}
                    style={sx({
                      marginRight: 15,
                      marginLeft: 15,
                      backgroundColor: 'red',
                      marginBottom: 10,
                      padding: 10,
                      borderWidth: 2,
                      borderStyle: 'dashed',
                      borderColor: 'white',
                      alignItems: 'center',
                      justifyContent: 'center'
                    })}>
                    <Text
                      sx={{
                        color: '$white',
                        textAlign: 'center',
                        fontFamily: Platform.select({ android: 'Poppins-Regular' })
                      }}>
                      {desconto}

                      <Icon
                        name='chevron-down'
                        color={Colors.white}
                        size={20}
                        style={sx({ marginLeft: [10] })}
                      />
                    </Text>

                    {deviceCode && validator ? (
                      <>
                        <View
                          sx={{
                            marginTop: [15],
                            flexDirection: ['row'],
                            alignSelf: ['center'],
                            borderWidth: 1,
                            borderColor: '$white'
                          }}>
                          <View sx={{ flex: 1 }}>
                            <Text
                              sx={{
                                backgroundColor: '$white',
                                color: '$primary3',
                                fontWeight: ['bold'],
                                padding: [10],
                                textAlign: ['center']
                              }}>
                              Código do dispositivo
                            </Text>
                            <Text
                              sx={{
                                alignSelf: ['center'],
                                fontSize: 20,
                                fontWeight: ['bold'],
                                color: ['$white'],
                                marginTop: [5],
                                marginBottom: [5],
                                flex: 1,
                                textAlign: ['center']
                              }}>
                              {deviceCode}
                            </Text>
                          </View>
                          <View sx={{ flex: 1 }}>
                            <Text
                              sx={{
                                backgroundColor: '$white',
                                color: '$primary3',
                                fontWeight: ['bold'],
                                padding: 10,
                                flex: 1,
                                textAlign: ['center']
                              }}>
                              Soma de verificação
                            </Text>
                            <Text
                              sx={{
                                alignSelf: ['center'],
                                fontSize: 20,
                                fontWeight: ['bold'],
                                color: ['$white'],
                                marginTop: [5],
                                marginBottom: [5]
                              }}>
                              {validator}
                            </Text>
                          </View>
                        </View>

                        {item?.Condicao ? (
                          <View
                            sx={{
                              alignSelf: ['center'],
                              backgroundColor: ['$white'],
                              marginTop: [15],
                              padding: [15],
                              borderRadius: [10]
                            }}>
                            <Text sx={{ color: '$text' }}>
                              <Text sx={{ fontWeight: ['bold'] }}>Termos e Condições:</Text>
                              {'\n'}
                              {item?.Condicao}
                            </Text>
                          </View>
                        ) : null}
                      </>
                    ) : null}
                  </TouchableOpacity>
                ) : null}

                {item.Endereco ? (
                  <Text
                    sx={{
                      fontSize: 16,
                      marginLeft: 15,
                      marginRight: 15,
                      color: '$primary3',
                      fontFamily: Platform.select({ android: 'Poppins-Regular' })
                    }}>
                    {item.Endereco}
                  </Text>
                ) : null}

                {item.Cadastur ? (
                  <Text
                    sx={{
                      fontSize: 16,
                      marginRight: 15,
                      marginLeft: 15,
                      color: '$primary3',
                      fontFamily: Platform.select({ android: 'Poppins-Regular' })
                    }}>
                    Cadastur: {item.Cadastur}
                  </Text>
                ) : null}

                {item.Curtida ? (
                  <Text
                    sx={{
                      fontSize: 18,
                      marginTop: 15,
                      marginBottom: 10,
                      marginRight: 15,
                      marginLeft: 15,
                      color: '$primary3',
                      fontFamily: Platform.select({ android: 'Poppins-Regular' })
                    }}>
                    {str.curtidas}: {item.Curtida}
                  </Text>
                ) : null}

                {item.Telefones ? (
                  <>
                    <Text
                      sx={{
                        fontSize: 16,
                        marginRight: 15,
                        marginLeft: 15,
                        color: '$primary3',
                        fontFamily: Platform.select({ android: 'Poppins-Regular' })
                      }}>
                      {str.telefones}:
                    </Text>
                    {
                      // @ts-ignore
                      item.Telefones.filter(t => !t.WhatsApp).map((tel, index) => (
                        <TouchableOpacity
                          key={`tel-${index}`}
                          onPress={() => openTelefone(tel.Numero)}>
                          <Text
                            sx={{
                              fontSize: 16,
                              marginLeft: 15,
                              marginRight: 15,
                              color: '$primary3',
                              textDecorationStyle: 'dotted',
                              textDecorationLine: 'underline',
                              //textDecorationColor: '$primary3',
                              fontFamily: Platform.select({ android: 'Poppins-Regular' })
                            }}>
                            +55 {maskPhone(tel.Numero)}
                          </Text>
                        </TouchableOpacity>
                      ))
                    }
                    <View sx={{ height: 10 }} />
                  </>
                ) : null}

                {
                  // @ts-ignore
                  item.Telefones && item.Telefones.some(t => t.WhatsApp) ? (
                    <>
                      <Text
                        sx={{
                          fontSize: 16,
                          marginLeft: 15,
                          marginRight: 15,
                          color: '$primary3',
                          fontFamily: Platform.select({ android: 'Poppins-Regular' })
                        }}>
                        {str.whatsapp}:
                      </Text>
                      {
                        // @ts-ignore
                        item.Telefones.filter(t => t.WhatsApp).map((tel, index) => (
                          <TouchableOpacity
                            key={`whats-${index}`}
                            onPress={() => openZap(tel.Numero)}>
                            <Text
                              sx={{
                                fontSize: 16,
                                marginLeft: 15,
                                marginRight: 15,
                                color: '$primary3',
                                textDecorationStyle: 'dotted',
                                textDecorationLine: 'underline',
                                //textDecorationColor: '$primary3',
                                fontFamily: Platform.select({ android: 'Poppins-Regular' })
                              }}>
                              +55 {maskPhone(tel.Numero)}
                            </Text>
                          </TouchableOpacity>
                        ))
                      }
                      <View sx={{ height: 10 }} />
                    </>
                  ) : null
                }

                <View sx={{ width: ['100%', '100%', '50%'], alignSelf: ['center'] }}>
                  {['Hospedagem'].includes(item.repo) && (
                    <TouchableOpacity
                      // @ts-ignore
                      onPress={() => openSite(BOOKING_LINKS[local])}
                      style={sx({
                        marginBottom: 10,
                        marginTop: 5,
                        marginLeft: 15,
                        marginRight: 15,
                        justifyContent: 'center',
                        alignItems: 'center',
                        elevation: 2,
                        backgroundColor: Colors.white,
                        height: 40,
                        borderRadius: 4
                      })}>
                      <Image
                        source={require('../../assets/images/booking.png')}
                        resizeMode='contain'
                        style={sx({ width: 150, height: 30 })}
                      />
                    </TouchableOpacity>
                  )}

                  {['Hospedagem'].includes(item.repo) && (
                    <ContainedButton
                      icon='hand-pointing-up'
                      text={str.reserveja}
                      onPress={() => {
                        // @ts-ignore
                        const tel = item.Telefones.find(t => !t.WhatsApp)?.Numero;
                        // @ts-ignore
                        const zap = item.Telefones.find(t => t.WhatsApp)?.Numero;
                        if (zap) openZap(zap);
                        else if (tel) openTelefone(tel);
                      }}
                      color={Colors.red}
                    />
                  )}

                  {['Hospedagem'].includes(item.repo) && (
                    <ContainedButton
                      icon='hand-pointing-up'
                      text={str.escolhaotransfer}
                      onPress={() => {
                        const localTransfer = {
                          'ilha-grande': 'IlhaGrande',
                          paraty: 'Paraty',
                          'angra-dos-reis': 'AngraDosReis',
                          mangaratiba: 'Mangaratiba'
                        };
                        // @ts-ignore
                        navigation.navigate('TransferHome', { screen: localTransfer[local] });
                      }}
                      color={Colors.red}
                    />
                  )}

                  {[
                    'Hospedagem',
                    'Restaurante',
                    'Transfer',
                    'Mergulho',
                    'Passeio',
                    'Experiencia',
                    'Lancha',
                    'Marina'
                  ].includes(item.repo) &&
                    item.Telefones &&
                    // @ts-ignore
                    item.Telefones.some(t => t.WhatsApp) && (
                      <ContainedButton
                        icon='whatsapp'
                        text={str.chamarwhatsapp}
                        onPress={() =>
                          // @ts-ignore
                          openZap(item.Telefones.find(t => t.WhatsApp)?.Numero)
                        }
                        color={Colors.whatsapp}
                      />
                    )}

                  {['Transfer', 'Mergulho', 'Passeio', 'Experiencia', 'Lancha', 'Marina'].includes(
                    item.repo
                  ) &&
                    item.Telefones &&
                    item.Telefones[0] && (
                      <ContainedButton
                        icon='phone'
                        text={str.ligueja}
                        onPress={() => openTelefone(item.Telefones[0]?.Numero)}
                        color={Colors.red}
                      />
                    )}

                  {item.Endereco && Platform.OS !== 'web' ? (
                    <ContainedButton
                      icon='map-marker-outline'
                      text={str.vernomapa}
                      onPress={() => {
                        /* if (Platform.OS === 'web') openPreco(item.Nome, item.Endereco);
                        else */ openMap(item.Endereco);
                      }}
                      color={Colors.primary5}
                    />
                  ) : null}
                </View>

                {item[descriptionKey] ? (
                  <FormatedText text={item[descriptionKey][lang] || item[descriptionKey].pt} />
                ) : null}

                {item?.Disponibilidade?.length > 0 && (
                  <View>
                    <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />
                    <Text
                      sx={{
                        fontSize: 16,
                        marginRight: 15,
                        marginLeft: 15,
                        color: '$primary3',
                        fontFamily: Platform.select({ android: 'Poppins-Regular' })
                      }}>
                      Disponibilidade:
                    </Text>

                    <View
                      sx={{
                        flexDirection: ['row'],
                        flexWrap: ['wrap'],
                        marginRight: [15],
                        marginLeft: [15]
                      }}>
                      {item?.Disponibilidade.map((d: any, i: number) => ({ ...d, index: i }))
                        .sort(
                          // @ts-ignore
                          sortByKey('Data')
                        )
                        .map((dispon: any, disponIndex: number) => (
                          <View
                            key={`dispon-${disponIndex}`}
                            sx={{
                              borderRadius: 5,
                              backgroundColor: Colors.placeholderLight,
                              marginRight: [5],
                              marginBottom: 10,
                              padding: 5
                            }}>
                            <Text sx={{ color: ['$primary3'], fontWeight: ['bold'], fontSize: 14 }}>
                              {formatDateDispon(dispon.Data)}
                            </Text>
                            <View sx={{ flexDirection: ['row'] }}>
                              <Text
                                sx={{
                                  fontSize: 10,
                                  fontWeight: ['bold'],
                                  color: dispon.Status === 'lotado' ? '$danger' : '$success'
                                }}>
                                {dispon.Status === 'lotado' ? 'Lotado' : 'Disponível'}
                              </Text>
                              {dispon.Status === 'disponivel' && dispon.NumVagas ? (
                                <Text
                                  sx={{
                                    fontSize: 10,
                                    fontWeight: ['bold'],
                                    color: dispon.Status === 'lotado' ? '$danger' : '$success'
                                  }}>
                                  {' '}
                                  ({dispon.NumVagas} vagas/mesas)
                                </Text>
                              ) : null}
                            </View>
                          </View>
                        ))}
                    </View>
                  </View>
                )}

                {item?.Produtos?.length > 0 && (
                  <View>
                    <Divider style={sx({ marginTop: 15, marginBottom: 15 })} />
                    <Text
                      sx={{
                        fontSize: 16,
                        marginRight: 15,
                        marginLeft: 15,
                        color: '$primary3',
                        fontFamily: Platform.select({ android: 'Poppins-Regular' })
                      }}>
                      Cardápio:
                    </Text>

                    {item?.Produtos.map((prod: any, prodIndex: number) => (
                      <View
                        key={`prod-${prodIndex}`}
                        sx={{
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: '$primary1',
                          marginRight: [15],
                          marginLeft: [15],
                          marginBottom: 10,
                          flexDirection: ['row'],
                          padding: 5
                        }}>
                        <Image
                          source={{ uri: prod.Image }}
                          resizeMode='cover'
                          style={sx({ width: 80, height: 80, borderRadius: 10 })}
                        />
                        <View sx={{ flex: 1, marginRight: 4, marginLeft: 4 }}>
                          <Text sx={{ fontSize: 16, color: '$text', fontWeight: ['bold'] }}>
                            {prod.Nome}
                          </Text>
                          <Text sx={{ fontSize: 14, color: '$text' }}>{prod.Descricao}</Text>
                          <Text sx={{ fontSize: 16, color: '$primary3' }}>{prod.Preco}</Text>
                          {item.Telefones &&
                            // @ts-ignore
                            item.Telefones.some(t => t.WhatsApp) && (
                              <IconButton
                                icon='whatsapp'
                                color={Colors.white}
                                size={20}
                                onPress={() =>
                                  // @ts-ignore
                                  openZap(item.Telefones.find(t => t.WhatsApp)?.Numero)
                                }
                                style={sx({
                                  position: ['absolute'],
                                  top: [0],
                                  right: [0],
                                  backgroundColor: Colors.whatsapp
                                })}
                              />
                            )}
                        </View>
                      </View>
                    ))}
                  </View>
                )}

                {item.Endereco ? <MapLocal url={getMapUrl(item.Endereco)} /> : null}
              </View>
            </>
          ) : null}
        </ScrollView>
      </View>

      <Portal>
        <Modal
          visible={modalVisible}
          onDismiss={() => setModalVisible(false)}
          contentContainerStyle={sx({ width: ['100%'], height: ['100%'] })}>
          <View
            sx={{
              width: ['100%'],
              height: ['100%'],
              justifyContent: ['center'],
              backgroundColor: 'rgba(0,0,0,0.5)'
            }}>
            <FlatList
              ref={imagesRef}
              horizontal
              data={imageList}
              scrollEnabled={false}
              showsHorizontalScrollIndicator={false}
              renderItem={({ item: img, index }) => (
                <View
                  sx={{
                    width,
                    height,
                    alignItems: ['center'],
                    justifyContent: ['center'],
                    flexDirection: 'row'
                  }}>
                  <Image
                    source={{ uri: img }}
                    style={sx({
                      height: ['90%'],
                      width: ['90%'],
                      alignSelf: ['center']
                    })}
                    resizeMode='contain'
                  />
                  {imageList.length > 1 && (
                    <>
                      <IconButton
                        icon='arrow-left'
                        disabled={index === 0}
                        onPress={() =>
                          imagesRef.current?.scrollToIndex?.({ animated: false, index: index - 1 })
                        }
                        color={Colors.white}
                        size={25}
                        style={sx({
                          position: ['absolute'],
                          //top: [15],
                          left: [15],
                          marginTop: [10],
                          marginBottom: [10],
                          alignSelf: ['center'],
                          backgroundColor: 'rgba(0,0,0,0.3)'
                        })}
                      />
                      <IconButton
                        icon='arrow-right'
                        disabled={index === imageList.length - 1}
                        onPress={() =>
                          imagesRef.current?.scrollToIndex?.({ animated: false, index: index + 1 })
                        }
                        color={Colors.white}
                        size={25}
                        style={sx({
                          position: ['absolute'],
                          //top: [15],
                          right: [15],
                          marginTop: [10],
                          marginBottom: [10],
                          alignSelf: ['center'],
                          backgroundColor: 'rgba(0,0,0,0.3)'
                        })}
                      />
                    </>
                  )}
                </View>
              )}
              style={sx({ width: ['100%'], height: ['100%'] })}
            />

            <IconButton
              icon='close'
              onPress={() => setModalVisible(false)}
              color={Colors.white}
              size={25}
              style={sx({
                position: ['absolute'],
                top: [15],
                right: [15],
                marginTop: [10],
                marginBottom: [10],
                alignSelf: ['center'],
                backgroundColor: 'rgba(0,0,0,0.3)'
              })}
            />
          </View>
        </Modal>
      </Portal>
    </View>
  );
};

const ActionButton: React.FC<{ onPress: () => void; text: string; icon: string }> = ({
  onPress,
  text,
  icon
}) => {
  const sx = useSx();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={sx({
        flexGrow: [1, 1, 0],
        marginTop: 10,
        marginBottom: 10,
        marginLeft: [0, 0, 15],
        marginRight: [0, 0, 15],
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      })}>
      <Icon name={icon} size={20} color='white' />
      <Text
        numberOfLines={1}
        sx={{
          color: '$white',
          fontSize: [8, 10, 12],
          flex: 1,
          fontFamily: Platform.select({ android: 'Poppins-Regular' })
        }}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};
